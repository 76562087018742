// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wide-banner {
  padding-left: 15px;
  padding-right: 15px;
}

.narrow-banner {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".wide-banner {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n\n.narrow-banner {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
