import { Component, Input } from '@angular/core';

export interface ProgressBarItem {
  label: string;
  complete: boolean;
}

@Component({
  selector: 'progress-bar',
  templateUrl: 'progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

  @Input() steps: ProgressBarItem[];

}
