import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ITab } from '../../models/tab';

@Component({
  selector: "app-tab-container",
  templateUrl: "./tab-container.component.html",
  styleUrls: ["./tab-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContainerComponent {
  @Input() tabs: ITab[] = [];
  @Output() tabChanged: EventEmitter<ITab[]> = new EventEmitter();

  onTabClicked(selected:ITab)
  {
    var findCurrentSelected = this.tabs?.find(x => x.isSelected === true);
    if(findCurrentSelected)
    {
      findCurrentSelected.isSelected = false;
      selected.isSelected = true;
      this.tabChanged.emit(this.tabs)
    }
  }
}
