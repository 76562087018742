import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as dayjs from "dayjs";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { dateFormat } from "../record-editor/record-editor.model";
import { ProviderApiService } from "../services/api/provider-api.service";

export enum DateRangeEnum {
  FromYear = 1800,
  ToYear = 2050
}

@Injectable()
export class ValidatorService {

  constructor(private service: ProviderApiService) { }

  validateDuplicateUKPRN(control: AbstractControl): Observable<ValidationErrors> | null {

    return this.service.checkDuplicateUkprns([control.value])
      .pipe(
        map(data => data[0].isDuplicated === true ? {
          ukprnExists: 'UKPRN is already in use, enter a unique UKPRN'
        } : null)
      );
  }

  validateDuplicateURN(control: AbstractControl): Observable<boolean> | null {
    return this.service.checkDuplicateUrn(Number(control.value.toString()))
  }

  validateFormat(date, format: string): boolean {
    return dayjs(date, format).format(format) === date;
  }

  dateValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const dayNumber = Number(fg.get(dateFormat.day).value);
    const monthNumber = Number(fg.get(dateFormat.month).value);
    const yearNumber = Number(fg.get(dateFormat.year).value);

    // Allow null date and comment, if all fields are empty
    if (!dayNumber && !monthNumber && !yearNumber) {
      return null;
    }

    if (!dayNumber || !monthNumber || !yearNumber) {
      return { date: true }
    }

    const dayString = dayNumber < 10 ? '0' + dayNumber.toString() : dayNumber.toString();
    const monthString = monthNumber < 10 ? '0' + monthNumber.toString() : monthNumber.toString();
    const yearString = yearNumber.toString();
    const dateString = yearString + '/' + monthString + '/' + dayString;

    return this.validateFormat(dateString, 'YYYY/MM/DD') ? null : { date: true };
  }

  dateRangeValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const dayNumber = Number(fg.get(dateFormat.day).value);
    const monthNumber = Number(fg.get(dateFormat.month).value);
    const yearNumber = Number(fg.get(dateFormat.year).value);

    // Allow null date and comment, if all fields are empty
    if (!dayNumber && !monthNumber && !yearNumber) {
      return null;
    }

    if (!dayNumber || !monthNumber || !yearNumber) {
      return { date: true }
    }

    const dayString = dayNumber < 10 ? '0' + dayNumber.toString() : dayNumber.toString();
    const monthString = monthNumber < 10 ? '0' + monthNumber.toString() : monthNumber.toString();
    const yearString = yearNumber.toString();
    const dateString = yearString + '/' + monthString + '/' + dayString;

    if (this.validateFormat(dateString, 'YYYY/MM/DD')) {
      if (yearNumber < DateRangeEnum.FromYear || yearNumber > DateRangeEnum.ToYear) {
        return { date: true, errorType: 'RangeValidation' };
      }
    }
    else {
      return { date: true, errorType: 'InputValidation' };
    }
  }

}