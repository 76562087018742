import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'record-paging',
  templateUrl: './record-paging.component.html',
  styleUrls: ['./record-paging.component.scss']
})
export class RecordPagingComponent implements OnInit, OnDestroy {

  @Input() recordCount$: Observable<number>;
  @Input() pageLength$: Observable<number>;
  @Input() currentPage$: Observable<number>;
  @Output() pageSelected = new EventEmitter<number>();

  pagesDisplayed: number = 5;
  recordCount: number;
  pageLength: number;
  numberOfPages: number = 1;
  currentPage: number = 1;
  displayedPages: number[];
  showNext: boolean = true;

  notifier = new Subject<void>()

  ngOnInit(): void {
    this.pageLength$.pipe(
      takeUntil(this.notifier)
    ).subscribe(pageLength => this.pageLength = pageLength);

    this.recordCount$.pipe(
      takeUntil(this.notifier)
    ).subscribe(recordCount => this.updateRecordCount(recordCount));

    this.currentPage$.pipe(
      takeUntil(this.notifier)
    ).subscribe(currentPage => {
      this.currentPage = currentPage;
      this.updateDisplayedPages(this.currentPage);
    });
  }

  updateRecordCount(recordCount: number): void {
    this.recordCount = recordCount;
    this.numberOfPages = Math.ceil(recordCount / this.pageLength);
    this.updateDisplayedPages(this.currentPage);
  }

  updateDisplayedPages(currentPage: number): void {
    const startPage = Math.floor((currentPage - 1) / this.pagesDisplayed) * this.pagesDisplayed;
    const endPage = Math.min(startPage + this.pagesDisplayed, this.numberOfPages);

    let displayedPages = this.recordCount > this.pageLength
      ? Array.from({ length: this.numberOfPages }, (_, i) => i + 1).slice(startPage, endPage)
      : [1];

    if (this.displayedPages && this.displayedPages.length > displayedPages.length) {
      this.currentPage = 1;
    }

    this.displayedPages = displayedPages;
  }


  previousClicked(): void {
    this.currentPage !== 1 && this.currentPage--;
    this.pageSelected.emit(this.currentPage);
    this.updateDisplayedPages(this.currentPage);
  }

  nextClicked(): void {
    this.currentPage !== this.numberOfPages && this.currentPage++;
    this.pageSelected.emit(this.currentPage);
    this.updateDisplayedPages(this.currentPage);
  }

  pageClicked(page: number): void {
    this.currentPage = page;
    this.pageSelected.emit(this.currentPage);
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  getRecordNumberDisplay(currentPage: number): string {
    const beginning = (currentPage - 1) * this.pageLength + 1;
    const end = Math.min(...[currentPage * this.pageLength, this.recordCount]);
    return `${beginning} - ${end} of ${this.recordCount} records`;
  }
}
