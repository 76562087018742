import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { commonErrorMessageTemplate, dateFormat, IPropertyEditCancelled, IPropertyEdited } from '../record-editor.model';
import { BannerStateCommand } from '../../layout/components/banner/state/banner-state.command';
import { VISIBILITY_BANNER } from '../../layout/components/banner/state/banner-state.model';
import { Helpers } from 'src/core/helpers';
import { ValidatorService, DateRangeEnum } from 'src/app/validators/validator-service';

@Component({
  selector: 'app-date-editor',
  templateUrl: './date-editor.component.html',
  styleUrls: ['./date-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class DateEditorComponent implements OnInit {
  @Input() showCommentTextBox: boolean;
  @Input() propertyValue: string;
  @Input() propertyName: string;
  @Input() propertyDisplayName: string;
  @Input() id: any;
  @Input() isValidUser: boolean;
  @Input() isFundingAgreementDataModule: boolean;
  @Input() isFundingDataModule: boolean;
  @Output() propertyValueChanged = new EventEmitter<IPropertyEdited>();
  @Output() commentAdded = new EventEmitter<IPropertyEdited>();
  @Output() cancelled = new EventEmitter<IPropertyEditCancelled>();

  dateForm: UntypedFormGroup;
  commentHasError: boolean;
  dateHasError: boolean;
  commentErrorMessage: string;
  dateErrorMessage: string;
  constructor(
    private fb: UntypedFormBuilder,
    private bannerStateCommand: BannerStateCommand,
    private element: ElementRef,
    private validatorService: ValidatorService
  ) {
  }

  setConditionalRequiredValidator(dayValue: string, monthValue: string, yearValue: string) {
    if (this.showCommentTextBox) {
      this.dateForm = this.fb.group({
        day: dayValue,
        month: monthValue,
        year: yearValue,
        comments: ['', [Validators.required, Validators.maxLength(200)]],
      }, { validator: this.validatorService.dateRangeValidator });
    }
    else {
      this.dateForm = this.fb.group({
        day: dayValue,
        month: monthValue,
        year: yearValue,
        comments: ['', []]
      }, { validator: this.validatorService.dateRangeValidator });
    }
  }

  ngOnInit(): void {
    if (this.isFundingDataModule) {
      const date = this.propertyValue ? this.parseDate(this.propertyValue) : null;
      this.propertyValue = date !== null ? date.toString() : null;
    }

    this.resetError();
    if (this.propertyName) {
      const dayValue = this.propertyValue ? this.getDate(this.propertyValue, dateFormat.day) : null;
      const monthValue = this.propertyValue ? this.getDate(this.propertyValue, dateFormat.month) : null;
      const yearValue = this.propertyValue ? this.getDate(this.propertyValue, dateFormat.year) : null;
      this.setConditionalRequiredValidator(dayValue, monthValue, yearValue);
    }
  }

  private parseDate(dateString: string): Date | null {
    const dateParts: string[] = dateString.split("/");

    if (dateParts.length !== 3) {
      console.error("Invalid date string format");
      return null;
    }

    const day: number = parseInt(dateParts[0], 10);
    const month: number = parseInt(dateParts[1], 10) - 1; // Months are 0-based in JavaScript Date
    const year: number = parseInt(dateParts[2], 10);

    const parsedDate: Date = new Date(year, month, day);

    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid date");
      return null;
    }

    return parsedDate;
  }

  onKeyPressDayMonthYear(event: any, charLength: number) {
    if (this.numberOnly(event)) {
      return (event.target.value.length >= charLength) ? false : true;
    }
    return false;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onDayMonthYearChange(event, length: number, nextFocusCtrlName: string) {

    if (event.target.value && event.target.value.length == length) {
      const foundElement = this.element.nativeElement.querySelector('[formcontrolname="' + nextFocusCtrlName + '"]');
      foundElement.focus();
    }
  }

  onSubmit() {
    this.resetError();

    if (this.dateForm.invalid) {
      this.bannerStateCommand.updateBanner({
        visibility: VISIBILITY_BANNER.SHOW_ERROR,
        notificationItem: {
          messages: this.buildNotificationErrors(this.dateForm),
        },
      });
      return;
    }

    const dayNumber: number = this.dateForm.get(dateFormat.day).value;
    const monthNumber: number = this.dateForm.get(dateFormat.month).value;
    const yearNumber: number = this.dateForm.get(dateFormat.year).value;
    let newDate: string;

    if (!this.isFundingAgreementDataModule) {
      newDate = (dayNumber && monthNumber && yearNumber)
        ? new Date(yearNumber, monthNumber - 1, dayNumber, 2, 0, 0).toISOString()
        : null;
    }
    else {
      newDate = (dayNumber && monthNumber && yearNumber) ? new Date(yearNumber + '-' + monthNumber + '-' + dayNumber).toString() : null;
    }

    const property = {
      comment: this.dateForm.get('comments').value,
      propertyName: Helpers.capitalizeFirstChar(this.propertyName),
      propertyValue: newDate,
      id: this.id,
    } as IPropertyEdited;

    this.propertyValue
      ? this.isSameDate(new Date(property.propertyValue), new Date(this.propertyValue))
        ? this.commentAdded.emit(property)
        : this.propertyValueChanged.emit(property)
      : this.propertyValueChanged.emit(property);
  }

  isSameDate(date1: Date, date2: Date): boolean {
    return date1 && date2 ? date1?.toISOString() === date2?.toISOString() : false;
  }

  onCancelled() {
    this.cancelled.emit({
      propertyName: this.propertyName,
      cancelled: true,
      id: this.id
    } as IPropertyEditCancelled);
  }

  getDate(date: string, dateElement: dateFormat): string {
    const dateTime = new Date(date);
    switch (dateElement) {
      case dateFormat.day:
        return dateTime ? new Date(date).getDate().toString() : '';
      case dateFormat.month:
        return dateTime ? (new Date(date).getMonth() + 1).toString() : '';
      case dateFormat.year:
        return dateTime ? new Date(date).getFullYear().toString() : '';
      default:
        return dateTime.toISOString();
    }
  }

  buildNotificationErrors(form: UntypedFormGroup): string[] {
    const commentControl = form.get('comments');
    let message = [];

    if (form.invalid) {
      if (commentControl.invalid) {
        this.commentHasError = true;
        if (commentControl.errors?.maxlength) {
          this.commentErrorMessage = this.maxLengthError('comment', 200);
          message.push(this.commentErrorMessage);
        }
        if (commentControl.errors?.required) {
          this.commentErrorMessage = commonErrorMessageTemplate.commentErrorMessage1 + this.propertyDisplayName + '. ';
          this.commentErrorMessage = this.commentErrorMessage + commonErrorMessageTemplate.commentErrorMessage2;
          message.push(this.commentErrorMessage);
        }
      }
      if (form.errors?.date) {
        this.dateHasError = true;
        let colName: string;
        if (!this.isFundingAgreementDataModule) {
          colName = Helpers.getTransformedColumnHeader(this.propertyName).toLowerCase();
          colName = colName.charAt(0).toUpperCase() + colName.slice(1).toLowerCase();
        }
        else {
          colName = this.propertyName;
        }

        if (form.errors?.errorType == 'RangeValidation') {
          this.dateErrorMessage = colName + " : Enter a date between " + DateRangeEnum.FromYear + " and " + DateRangeEnum.ToYear;
        }
        else {
          this.dateErrorMessage = colName + " : Enter a valid date.";
        }
        message.push(this.dateErrorMessage);
      }
    }

    return message;
  }

  errorGroupCss(hasError: boolean) {
    return !hasError
      ? 'record-editor-left-padding-1'
      : 'govuk-form-group--error';
  }

  resetError() {
    this.commentHasError = false;
    this.dateHasError = false;
    this.commentErrorMessage = '';
    this.dateErrorMessage = '';
  }

  maxLengthError(propertyName: string, maxLength: number) {
    return `Enter a ${Helpers.getTransformedColumnHeader(propertyName).toLowerCase()} of ${maxLength} characters or less`;
  }
}
