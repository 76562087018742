import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IMultiSelectOption } from '../multi-select-field/multi-select.model';
import { Observable } from 'rxjs';
import { Filters, IFieldSearchTerms } from '../../../maintain-data/models/search-filter.model';

@Component({
  selector: 'multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectDropdownComponent implements OnInit {

  @Input() title: string;
  @Input() compactView = false;
  @Input() fieldName: string;
  @Input() clearFilter$: Observable<Filters | null>;
  @Input() options$: Observable<IMultiSelectOption[]>;
  @Output() selectionChange = new EventEmitter<string[] | IFieldSearchTerms>();

  checkboxOptions: IMultiSelectOption[];
  selections: string[] = [];
  checkboxFormGroup: UntypedFormGroup;
  isOpen = false;

  constructor(private fb: UntypedFormBuilder, private cd: ChangeDetectorRef) {
    this.checkboxFormGroup = this.fb.group({
      all: this.fb.control(false)
    });
  }

  ngOnInit(): void {
    this.clearFilter$?.subscribe(filter => {
      filter
        ? filter === this.fieldName && this.unselectAll()
        : this.unselectAll();
    });

    this.options$.subscribe((options) => {
      this.setupCheckboxOptions(options);
    });
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  private setupCheckboxOptions(options: IMultiSelectOption[]): void {
    this.checkboxOptions = options;
    this.checkboxFormGroup = this.fb.group({
      ...this.checkboxFormGroup.controls,
      checkArray: this.fb.array(options.map(option => {
        // Maintain selections after options reset
        return this.fb.control(this.selections?.includes(option.value));
      }))
    });

    this.updateSelections();
  }

  updateSelections(): void {
    this.selections = [];
    if (this.allOptionsSelected()) {
      this.selections = ['All'];
      this.allControl.setValue(true);
    } else {
      this.checkArray.controls.forEach((control, i) => {
        if (control.value) {
          this.selections.push(this.checkboxOptions[i].value);
        }
      });
      this.allControl.setValue(false);
    }
    this.fieldName
      ? this.selectionChange.emit({ fieldName: this.fieldName, searchTerms: this.selections } as IFieldSearchTerms)
      : this.selectionChange.emit(this.selections);
    this.cd.markForCheck();
  }

  get checkArray(): UntypedFormArray {
    return <UntypedFormArray>this.checkboxFormGroup.get('checkArray');
  }

  get allControl(): UntypedFormControl {
    return <UntypedFormControl>this.checkboxFormGroup.get('all');
  }

  allOptionsSelected(): boolean {
    return this.checkArray?.value.every(val => val);
  }

  allIndeterminate(): boolean {
    return !this.allOptionsSelected() && this.checkArray?.value.some(val => val);
  }

  selectAll(): void {
    this.checkArray.controls.forEach(control => {
      control.setValue(true);
    });
    this.updateSelections();
  }

  unselectAll(): void {
    this.checkArray.controls.forEach(control => {
      control.setValue(false);
    });
    this.updateSelections();
  }

  onAllClicked(e: Event): void {
    // @ts-ignore
    if (e.target.checked) {
      this.selectAll();
    } else {
      this.unselectAll();
    }
  }

  onClickOutside(): void {
    this.isOpen = false;
  }
}
