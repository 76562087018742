import { Component, OnInit } from '@angular/core';
import { FundingStreamType } from 'src/app/models/funding-stream-type';
import { FundingStreamTypeService } from 'src/app/services/funding-stream-type.service';
import { SessionsStoreUserInformationService } from 'src/app/services/sessions-store-user-information.service';

@Component({
  templateUrl: './manage-data.page.html',
  styleUrls: ['./manage-data.page.scss']
})
export class ManageDataPage implements OnInit {
  reconciliation: boolean = false;

  constructor(private userInformationService: SessionsStoreUserInformationService,
    private fundingStreamTypeService: FundingStreamTypeService) { }

  ngOnInit(): void {
    this.reconciliation = this.userInformationService.hasReconciliationAccess();
    this.fundingStreamTypeService.setFundingStreamType(FundingStreamType.Pre16);
  }

}
