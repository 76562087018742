import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiSelectionText'
})
export class MultiSelectionTextPipe implements PipeTransform {

  transform(selections: string[]): string {
    return (selections.length === 0 || selections[0] === 'All')
      ? ''
      : selections.length.toString() + ' selected';
  }

}
