import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITreeSelectOptions, TreeNode } from './tree-select.model';
import { Observable } from 'rxjs';
import { Filters, IFieldSearchTerms } from '../../../maintain-data/models/search-filter.model';

@Component({
  selector: 'tree-select-dropdown',
  templateUrl: './tree-select-dropdown.component.html',
  styleUrls: ['./tree-select-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSelectDropdownComponent implements OnInit {

  @Input() title: string;
  @Input() compactView = false;
  @Input() fieldName: string;
  @Input() clearFilter$: Observable<Filters | null>;
  @Input() options$: Observable<ITreeSelectOptions[]>;

  /*** if true, root level selection returns all children of selected node */
  @Input() returnAllChildren = false;
  @Output() selectionChange = new EventEmitter<ITreeSelectOptions[] | IFieldSearchTerms>();

  isOpen = false;
  allSelected = true;
  allIndeterminate = false;
  treeState: TreeNode[];
  selectedOptions: ITreeSelectOptions[] = [];

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.clearFilter$?.subscribe(filter => {
      filter
        ? filter === this.fieldName && this.unselectAll(this.treeState)
        : this.unselectAll(this.treeState);
    });

    this.options$.subscribe(options => this.setupCheckboxOptions(options) );
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  private setupCheckboxOptions(options: ITreeSelectOptions[]): void {
    // Apply existing selections, if any
    if (this.selectedOptions.length > 0) {
      options.forEach(option => {
        const selectedOption = this.selectedOptions.find((selectedOption) => selectedOption.value === option.value);
        if (selectedOption) {
          option.children?.forEach((childOption) => {
            childOption.checked = selectedOption.children?.some(selectedChildOption => selectedChildOption.value === childOption.value);
          });

          option.checked = selectedOption.children?.length > 0
            ? selectedOption.children.length === option.children?.length
            : true;
        }
      });

      this.treeState = options.map(option => {
        return new TreeNode(option);
      });
      this.treeState.forEach((treeNode) => {
        treeNode.indeterminate = this.checkIndeterminate(treeNode);
      });
    } else {
      this.treeState = options.map(option => {
        return new TreeNode(option);
      });
    }

    this.updateSelection();
  }

  onAllClicked(): void {
    this.allSelected ? this.selectAll(this.treeState) : this.unselectAll(this.treeState);
  }

  nodeClicked(node: TreeNode, child?: TreeNode): void {
    if (child) {
      node.checked = this.allOptionsSelected(node.children);
      this.updateSelection();
    } else if (node) {
      node.checked ? this.selectAll(node.children) : this.unselectAll(node.children);
    }
    node.indeterminate = this.checkIndeterminate(node);
    this.allIndeterminate = !this.allSelected && this.treeState.some(node => node.checked || node.indeterminate);
  }

  toggleNodeExpand(node: TreeNode): void {
    node.expanded = !node.expanded;
  }

  allOptionsSelected(nodes: TreeNode[]): boolean {
    return nodes.every(val => val.checked);
  }

  selectAll(nodes: TreeNode[]): void {
    nodes?.forEach(node => {
      node.checked = true;
      node.indeterminate = false;
      if (node.children.length > 0) {
        this.selectAll(node.children);
      }
    });
    this.allIndeterminate = false;
    this.updateSelection();
  }

  unselectAll(nodes: TreeNode[]): void {
    nodes.forEach(node => {
      node.checked = false;
      node.indeterminate = false;
      if (node.children.length > 0) {
        this.unselectAll(node.children);
      }
    });
    this.allIndeterminate = false;
    this.updateSelection();
  }

  checkIndeterminate(node: TreeNode): boolean {
    return node.children?.some(child => child.checked) && !node.children?.every(child => child.checked);
  }

  private updateSelection(): void {
    this.allSelected = this.allOptionsSelected(this.treeState);
    this.selectedOptions = [];
    if (this.allSelected) {
      this.selectedOptions = [{ name: 'All', value: 'All' }];
    } else {
      this.selectedOptions = this.treeState.map(treeNode => {
        const selectedChildren = treeNode.children.filter(child => child.checked).map(child => {
          return {name: child.name, value: child.value}
        });
        return treeNode.checked
          ? this.returnAllChildren
            ? { name: treeNode.name, value: treeNode.value, children: selectedChildren }
            : { name: treeNode.name, value: treeNode.value }
          : selectedChildren.length > 0
            ? { name: treeNode.name, value: treeNode.value, children: selectedChildren }
            : null;
      }).filter(node => node !== null);
    }
    this.fieldName
      ? this.selectionChange.emit({ fieldName: this.fieldName, searchTerms: this.selectedOptions } as IFieldSearchTerms)
      : this.selectionChange.emit(this.selectedOptions);
    this.cd.markForCheck();
  }

  onClickOutside(): void {
    this.isOpen = false;
  }
}
