import { MsalGuard } from '@azure/msal-angular';
import { ProviderColumnName } from 'src/app/maintain-data/components/providers/provider-tab/provider-tab.model';
import { environment } from 'src/environments/environment';

export class Helpers {
  public static excludeProperties<T>(data: any, exclude: string[]) {
    let deepCopy = JSON.parse(JSON.stringify(data));
    for (let item in exclude) {
      delete deepCopy[item];
    }
    return deepCopy;
  }

  public static getTransformedColumnHeader(colHeader: string): string {
    const regEx = /\W+|(?=[A-Z])|_/g;
    return colHeader.split(regEx).join(' ');
  }

  public static capitalizeFirstChar(txt: string) {
    return txt.charAt(0).toUpperCase() + txt.slice(1); //or if you want lowercase the rest txt.slice(1).toLowerCase();
  }

  public static stripWhiteSpace(input: string): string {
    return input.replace(/\s+/g, '');
  }

  public static lowerCaseFirstChar(txt: string) {
    return txt?.charAt(0).toLowerCase() + txt?.slice(1); //or if you want lowercase the rest txt.slice(1).toLowerCase();
  }

  public static isAuthority(authorityFDSUID: string): boolean {
    return authorityFDSUID?.toLowerCase().includes(ProviderColumnName.authorityFDSUID.toLowerCase());
  }

  public static getFlattenedObject = (obj, roots = [], sep = '.') =>
    Object.keys(obj).reduce(
      (memo, prop) =>
        Object.assign(
          {},
          memo,
          Object.prototype.toString.call(obj[prop]) === '[object Object]'
            ? Helpers.getFlattenedObject(obj[prop], roots.concat([prop]))
            : { [roots.concat([prop]).join(sep)]: obj[prop] }
        ),
      {}
    );

  public static isNumeric(str: any): boolean {
    return !isNaN(Number(str)) && !isNaN(parseFloat(str));
  }

  public static getGuards = (guards: any[] = []) => {
    if (!environment?.useLocalHttpInterceptor) {
      guards = [...guards, MsalGuard];
    }
    return guards;
  };
}
