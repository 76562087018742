import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRadioFieldOption } from './radio-field-option.model';
import { Observable } from 'rxjs';
import { Filters, IFieldSearchTerms } from '../../../maintain-data/models/search-filter.model';

@Component({
  selector: 'radio-dropdown',
  templateUrl: './radio-dropdown.component.html',
  styleUrl: './radio-dropdown.component.scss'
})
export class RadioDropdownComponent implements OnInit {

  @Input() title: string;
  @Input() compactView = false;
  @Input() fieldName: string;
  @Input() clearFilter$: Observable<Filters | null>;
  @Input() options$: Observable<IRadioFieldOption[]>;
  @Input() defaultOption$: Observable<string>;
  @Output() selectionChange = new EventEmitter<string[] | IFieldSearchTerms>();

  fieldOptions: IRadioFieldOption[];
  defaultOption: string;
  selection: string;
  isOpen = false;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.clearFilter$?.subscribe(filter => {
      filter
        ? filter === this.fieldName && this.unselectAll()
        : this.unselectAll();
    });

    this.options$.subscribe((options) => {
      this.fieldOptions = options;
      this.defaultOption$.subscribe(option => {
        this.defaultOption = option;
        this.selection = option ?? this.fieldOptions[0].value;
        this.updateSelection();
      });
    });
  }

  updateSelection(): void {
    this.selectionChange.emit({ fieldName: this.fieldName, searchTerms: this.selection } as IFieldSearchTerms)
    this.cd.markForCheck();
  }

  unselectAll(): void {
    this.selection = this.defaultOption;
    this.updateSelection();
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  onClickOutside(): void {
    this.isOpen = false;
  }
}