import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export interface IUnsavedChangesComponent {
  hasUnsavedChanges: boolean;
  confirmDeactivate: () => Promise<boolean>;
}

@Injectable()
export class UnsavedChangesGuard  {
  canDeactivate(component: IUnsavedChangesComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.confirmDeactivate();
  }
}
