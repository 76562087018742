// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  `, "",{"version":3,"sources":["webpack://./src/app/layout/components/phase-banner/phase-banner.component.ts"],"names":[],"mappings":";IACI;MACE,aAAa;MACb,mBAAmB;MACnB,8BAA8B;MAC9B,mBAAmB;IACrB","sourcesContent":["\n    .flex {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      flex-direction: row;\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
