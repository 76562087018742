import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomePage } from './navigation-pages/home/home.page';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { ManageDataPage } from './navigation-pages/manage-data/manage-data.page';
import { FileImportComponent } from './components/file-import/file-import.component';
import { ConfigApiService } from './services/api/config-api.service';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { API_BASE_URL } from './services/api/custom-api.service';
import { AccessibilityStatementComponent } from './navigation-pages/accessibility-statement/accessibility-statement.page';
import { CookiesComponent } from './navigation-pages/cookies/cookies-statement';
import { LocalHttpInterceptorServiceService } from './services/interceptors/local-http-interceptor-service.service';
import { ViewMyPermissionsPageComponent } from './components/view-my-permissions-page/view-my-permissions-page.component';
import { PermissionErrorPageComponent } from './components/permission-error-page/permission-error-page.component';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { ConfirmationModalDialogComponent } from './components/confirmation-modal-dialog/confirmation-modal-dialog.component';
import { ManageProviderComponent } from './navigation-pages/manage-provider/manage-provider.component';
import { ManageFundingDataComponent } from './navigation-pages/manage-funding-data/manage-funding-data.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: environment.redirectUri,
      authority: environment.authority,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(`${environment.apiBaseUrl}/api/*`, environment.scope);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    ManageDataPage,
    FileImportComponent,
    AccessibilityStatementComponent,
    CookiesComponent,
    ViewMyPermissionsPageComponent,
    PermissionErrorPageComponent,
    UnsavedChangesDialogComponent,
    ConfirmationModalDialogComponent,
    ManageProviderComponent,
    ManageFundingDataComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    LayoutModule,
    MsalModule,
    MatDialogModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: environment.useLocalHttpInterceptor ? LocalHttpInterceptorServiceService : MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    ConfigApiService,
    {
      provide: API_BASE_URL,
      useValue: environment.apiBaseUrl,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    UnsavedChangesGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
