import { Component, Input, OnInit } from '@angular/core';
import { SessionsStoreUserInformationService } from '../../../services/sessions-store-user-information.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  menuOpen: boolean;
  @Input() wideView = false;
  showAdminLink: boolean = false;
  env: string = "";

  constructor(private sessionsStoreUserInformationService: SessionsStoreUserInformationService) { }

  ngOnInit(): void {
    this.setEnvironmentName();
    this.showAdminLink = this.sessionsStoreUserInformationService.isSysAdmin();
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  setEnvironmentName(): void {
    switch (environment.environment) {
      case "dev":
        this.env = "(Dev environment)";
        break;
      case "sit":
        this.env = "(SIT environment)";
        break;
      case "uat":
        this.env = "(UAT environment)";
        break;
      case "preprod":
        this.env = "(Pre-production environment)";
        break;
    }
  }
}
