import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BannerStateCommand } from '../../layout/components/banner/state/banner-state.command';
import { VISIBILITY_BANNER } from '../../layout/components/banner/state/banner-state.model';
import { Observable, of } from 'rxjs';
import { Helpers } from 'src/core/helpers';
import {
  commonErrorMessageTemplate,
  EditComponentType,
  IDropdownItem,
  IPropertyEditCancelled,
  IPropertyEdited,
  IPropertyLink,
  Property,
} from '../record-editor.model';
import { FDSReferenceData } from '../../models/fds-reference-data';
import { PaymentOrganisation } from 'src/app/models/payment-organisation';
import { ProviderColumnName } from 'src/app/maintain-data/components/providers/provider-tab/provider-tab.model';

@Component({
  selector: 'app-drop-editor',
  templateUrl: './drop-down-editor.component.html',
  styleUrls: ['./drop-down-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropEditorComponent implements OnInit {
  @Input() showCommentTextBox: boolean;
  @Input() propertyValue: any;
  @Input() propertyName: string;
  @Input() propertyDisplayName: string;
  @Input() dropdownItems: IDropdownItem[];
  @Input() childPropertyValue?: string = null;
  @Input() childPropertyName?: string;
  @Input() childDropdownItems?: IDropdownItem[];
  @Input() childPropertyLinks?: IPropertyLink[];
  @Input() providerReferenceData: FDSReferenceData[];
  @Input() displayType?: string;
  @Input() id: any;
  @Input() childProperties: Property[];
  @Input() searchData: any[];
  @Input() nullable: boolean = false;
  @Input() hintMessage: string;
  @Input() isProviderPaymentOrganisation: boolean = false;
  @Input() isOrganisationTypeLA: boolean;
  @Input() isValidUser: boolean;

  @Output() propertyValueChanged = new EventEmitter<IPropertyEdited>();
  @Output() commentAdded = new EventEmitter<IPropertyEdited>();
  @Output() cancelled = new EventEmitter<IPropertyEditCancelled>();

  dropdownForm: UntypedFormGroup;
  commentHasError: boolean;
  commentErrorMessage: string;

  dropdownItems$: Observable<IDropdownItem[]>
  childDropdownItems$: Observable<IDropdownItem[]>

  eligibleChildDropdownItems: IDropdownItem[];
  updatedChildlist: Property[] = [];

  disableCheckbox: boolean;
  isProviderPaymentOrgTemp: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private bannerStateCommand: BannerStateCommand
  ) { }

  setConditionalRequiredValidator() {
    if (this.showCommentTextBox) {
      this.dropdownForm = this.fb.group({
        assignedValue: [this.propertyValue],
        childAssignedValue: [this.childPropertyValue || null],
        comments: ['', [Validators.required, Validators.maxLength(200)]],
      });
    }
    else {
      this.dropdownForm = this.fb.group({
        assignedValue: [this.propertyValue],
        childAssignedValue: [this.childPropertyValue || null],
        comments: [''],
      });
    }
  }
  ngOnInit(): void {
    this.isProviderPaymentOrgTemp = this.isProviderPaymentOrganisation;
    this.resetError();

    if (this.propertyName && this.dropdownItems) {
      this.setConditionalRequiredValidator();
    }
    this.nullable && this.dropdownItems.unshift({ display: '-', value: null });

    if (this.childPropertyLinks) {
      this.eligibleChildDropdownItems = this.getEligibleDropdownItems();
    }
    this.updatedChildlist = this.childProperties;

    this.dropdownItems$ = of(this.dropdownItems);
    this.childDropdownItems$ = of(this.eligibleChildDropdownItems);
  }

  onSubmit() {
    this.resetError();

    if (this.dropdownForm.invalid) {
      this.bannerStateCommand.updateBanner({
        visibility: VISIBILITY_BANNER.SHOW_ERROR,
        notificationItem: {
          messages: this.buildNotificationErrors(this.dropdownForm),
        },
      });
      return;
    }

    const selectedItem = this.dropdownForm.get('assignedValue').value === 'null'
      ? null
      : this.dropdownForm.get('assignedValue').value;
    const selectedChild = this.dropdownForm.get('childAssignedValue').value === 'null'
      ? null
      : this.dropdownForm.get('childAssignedValue').value;

    let property = {
      comment: this.dropdownForm.get('comments').value,
      id: this.id,
    } as IPropertyEdited;

    property.propertyName = Helpers.capitalizeFirstChar(this.propertyName);
    property.propertyValue = selectedItem;
    property.childPropertyName = this.childPropertyName === undefined ? undefined : Helpers.capitalizeFirstChar(this.childPropertyName);
    property.childPropertyValue = selectedChild;
    property.id = this.id;
    property.isProviderPaymentOrganisation = this.isProviderPaymentOrganisation;
    (property.propertyValue === this.propertyValue && property.childPropertyValue === this.childPropertyValue &&
      this.isProviderPaymentOrgTemp === this.isProviderPaymentOrganisation) ?
      this.commentAdded.emit(property) : this.propertyValueChanged.emit(property);
  }

  onCancelled() {
    this.cancelled.emit({
      propertyName: this.propertyName === ProviderColumnName.paymentOrganisationId ? ProviderColumnName.paymentOrganisation : this.propertyName,
      cancelled: true,
      id: this.id
    } as IPropertyEditCancelled);
  }

  buildNotificationErrors(form: UntypedFormGroup): string[] {
    const commentControl = form.get('comments');
    let message = [];
    if (commentControl.invalid) {
      this.commentHasError = true;
      if (commentControl.errors?.maxlength) {
        this.commentErrorMessage = this.maxLengthError('comment', 200);
        message.push(this.commentErrorMessage);
      }

      if (commentControl.errors?.required) {
        this.commentErrorMessage = commonErrorMessageTemplate.commentErrorMessage1 + this.propertyDisplayName + '. ';
        this.commentErrorMessage = this.commentErrorMessage + commonErrorMessageTemplate.commentErrorMessage2;
        message.push(this.commentErrorMessage);
      }
    }

    return message;
  }

  resetError() {
    this.commentHasError = false;
    this.commentErrorMessage = '';
  }

  maxLengthError(propertyName: string, maxLength: number) {
    return `Enter a ${Helpers.getTransformedColumnHeader(
      propertyName
    ).toLowerCase()} of ${maxLength} characters or less`;
  }

  onMainSelectionChanged(event: any) {
    if (this.childDropdownItems) {
      this.childPropertyValue = null;
      if (this.childPropertyName) {
        this.eligibleChildDropdownItems = this.getEligibleDropdownItems();
      }
      this.childDropdownItems$ = of(this.eligibleChildDropdownItems);

      // Select first available child when parent control is changed
      this.dropdownForm?.get('childAssignedValue').setValue(this.eligibleChildDropdownItems[0]?.value || '');
    } else if (this.displayType === EditComponentType.referenceDropdown) {
      const val = this.dropdownForm?.get('assignedValue').value;
      const refData = this.providerReferenceData.find(c => c.fdsuid === val);
      if (this.updatedChildlist.length > 0) {
        this.updatedChildlist.map(x => x.displayName = refData[x.name]);
      }
    }
  }

  onUpdate(event: PaymentOrganisation) {
    this.dropdownForm?.get('assignedValue').setValue(event || '');
    this.updatedChildlist = [...this.childProperties];
    this.disableCheckbox = !!event.paymentOrganisationId;

    this.childProperties.map(x => {
      if (x) {
        if (x.type !== EditComponentType.checkbox) {
          let name = x.name.slice(x.name.lastIndexOf('.') + 1);
          x.displayName = event[name] || '-';
        }
      }
    });

    //Trigger change detection.
    this.updatedChildlist.splice(0);
    this.childProperties.forEach((c, index) => {
      this.updatedChildlist.push(this.childProperties[index]);
    });
  }

  private getEligibleDropdownItems(): IDropdownItem[] {
    const val = this.dropdownForm?.get('assignedValue').value;
    const validLinks = this.childPropertyLinks
      .filter(link => link.parentFDSUID === val)
      .map(link => link.childFDSUID);

    const eligibleItems = this.childDropdownItems?.filter(item => validLinks.includes(item.value));
    this.nullable && eligibleItems.unshift({ display: '-', value: null });

    return eligibleItems;
  }

  private onCheckboxChange(event: any): void {
    this.isProviderPaymentOrganisation = event?.target?.checked;
  }
}