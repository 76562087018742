import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, delay, map, mapTo, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FundingStreamTypeService {
  _funingStreamType$ = new BehaviorSubject<string>("");
  constructor() { }

  setFundingStreamType(value: string) {
    this._funingStreamType$.next(value);
  }

  getFundingStreamType(): Observable<string> {
    return this._funingStreamType$.asObservable();
  }

  getFundingStreamTypeValue(): string {
    return this._funingStreamType$.value;
  }

  clear() {
    this._funingStreamType$.next("");
  }


}
