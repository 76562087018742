import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { SessionsStoreUserInformationService } from '../services/sessions-store-user-information.service';

/**
 * This guard ensures that the route cannot be used without the user being a sys admin role.
 */

export const AdminGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(SessionsStoreUserInformationService).isSysAdmin();
};

