import { EntityState } from "@datorama/akita";
import { IBanner, IBannerUIState } from "../banner.model";

/**
 * BannerState state
 *
 * @export
 * @interface BannerStateState
 */
export interface BannerStateState extends EntityState<IBanner> {
  ui: IBannerUIState;
}

export enum VISIBILITY_BANNER {
  SHOW_SUCCESS = 'SHOW_SUCCESS',
  SHOW_ERROR = 'SHOW_ERROR',
  SHOW_NONE = 'SHOW_NONE',
  SHOW_ERROR_SchemaDef = 'SHOW_ERROR_SchemaDef',
  SHOW_ERROR_Schema = 'SHOW_ERROR_Schema',
  SHOW_IMPORTANT_INFO = 'SHOW_IMPORTANT_INFO'
}

