import { Inject, Injectable, Optional } from '@angular/core';
import { API_BASE_URL, CustomApiService } from './custom-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FundingFlag } from '../../models/funding-flag';
import { ICopyFundingFlags, IFundingStreamScope } from '../../models/funding-flag-scope';
import { IUnscopedSuccessor } from '../../share-data/models/unscopedSuccessor';
import { BannerStateCommand } from '../../layout/components/banner/state/banner-state.command';
import { SnapshotTransactionScope } from 'src/app/maintain-provider-attributes/models/provider-transaction-scope-data.model';

@Injectable({
  providedIn: 'root'
})
export class FundingFlagApiService extends CustomApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(BannerStateCommand) bannerStateCommand: BannerStateCommand, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, bannerStateCommand, baseUrl);
  }

  saveFundingFlags(fundingFlags: FundingFlag[]): Observable<boolean> {
    return this.post<boolean>('/api/FundingFlag/bulk/save', fundingFlags);
  }

  saveFundingFlagsTransaction(transation: SnapshotTransactionScope): Observable<boolean> {
    return this.post<boolean>('/api/ProviderTransaction/bulk/save', transation);
  }

  descopeProviderTransaction(transation: SnapshotTransactionScope): Observable<boolean> {
    return this.post<boolean>('/api/ProviderTransaction/descope', transation);
  }

  copyFundingFlags(fundingFlagsCopy: ICopyFundingFlags[]) {
    return this.post<ICopyFundingFlags[]>('/api/FundingFlag/bulk/copy', fundingFlagsCopy, null, null, false);
  }

  getInScopeFundingFlags(type: string): Observable<IFundingStreamScope[]> {
    return this.get<IFundingStreamScope[]>('/api/FundingFlag/fundingStream/inscope', null, { fundingStreamType: type });
  }

  getMissingSuccessors(fundingPeriodId: number): Observable<IUnscopedSuccessor[]> {
    return this.get<IUnscopedSuccessor[]>(`/api/FundingFlag/fundingPeriod/${fundingPeriodId}/successors-to-be-scoped`);
  }

  getDatasetTracking(): Observable<IFundingStreamScope[]> {
    return this.get<IFundingStreamScope[]>('/api/FundingFlag/fundingStream/inscope-with-tracking-details');
  }
}
