import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { VISIBILITY_TABS } from 'src/app/data-tab/models/tab';
import { MaintainDataState } from './maintain-data-state.model';
import { LEFT_PANEL_STATE } from '../maintain-data.model';

const initialState = {
  ui: {
    rightPanelTabs: VISIBILITY_TABS.SHOW_NONE,
    leftPanel: LEFT_PANEL_STATE.HIDE
  },
};

/**
 * MaintainData store
 *
 * @export
 * @class MaintainDataStateStore
 * @extends {Store<MaintainDataStateStore>}
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'maintainData' , resettable: true})
export class MaintainDataStateStore extends EntityStore<MaintainDataState> {
  constructor() {
    super(initialState);
  }

  updateRightPanelTabs(rightPanelTabs: VISIBILITY_TABS): void {
    this.update(state => {
      return { ui: { ...state.ui, rightPanelTabs: rightPanelTabs } }
    });
  }

  updateLeftPanel(leftPanel: LEFT_PANEL_STATE): void {
    this.update(state => {
      return { ui: { ...state.ui, leftPanel: leftPanel } }
    });
  }
}
