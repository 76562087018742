// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-wide {
  padding-left: 15px;
  padding-right: 15px;
}

.header-logo {
  width: auto;
}

.header-content {
  width: auto;
}

.header-nav {
  float: right;
}

.hideMenu {
  display: none !important;
}

.csMarginTop {
  margin-top: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/navbar/navbar.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,0BAAA;AACF","sourcesContent":[".header-wide {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n\n.header-logo {\n  width: auto;\n}\n\n.header-content {\n  width: auto;\n}\n\n.header-nav {\n  float: right;\n}\n\n.hideMenu {\n  display: none !important;\n}\n\n.csMarginTop{\n  margin-top: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
