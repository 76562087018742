// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-style-inactive {
  border: none;
  background-color: Transparent;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.button-style-active {
  border: none;
  background-color: #cacfd2;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.button-style-inactive:hover {
  cursor: pointer;
}

.button-style-active:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/sortable-list/sortable-list.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,6BAAA;EACA,oBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,YAAA;EACA,yBAAA;EACA,oBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".button-style-inactive {\n    border: none;\n    background-color: Transparent;\n    padding-left: 0.2rem;\n    padding-right: 0.2rem;\n}\n\n.button-style-active {\n    border: none;\n    background-color: #cacfd2;\n    padding-left: 0.2rem;\n    padding-right: 0.2rem;\n}\n\n.button-style-inactive:hover {\n    cursor: pointer;\n}\n\n.button-style-active:hover {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
