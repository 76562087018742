// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paging {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.buttons-row {
  display: flex;
  justify-content: flex-end;
}

.records-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.25rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.selected-page {
  text-decoration: none !important;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/record-paging/record-paging.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,sBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,oBAAA;EACA,qBAAA;AACF;;AAEA;EACE,gCAAA;EACA,YAAA;AACF","sourcesContent":[".paging {\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.buttons-row {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.records-row {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 0.25rem;\n}\n\n.page-link {\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n}\n\n.selected-page {\n  text-decoration: none !important;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
