// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: #f3f2f1;
  position: relative;
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  margin: 0;
  width: 16rem;
}

.subtext {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
}

.toggle-button {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  width: 2rem;
  height: 2rem;
  border: none;
  background: none;
  cursor: pointer;
}

.body {
  background-color: white;
  border: 2px solid #f3f2f1;
}

.multi-select {
  display: block;
  padding-left: 0.5rem;
  padding-bottom: 10px;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.govuk-checkboxes__label {
  padding-bottom: 0.2rem;
  font-size: 1.1rem !important;
}

.govuk-checkboxes__input:indeterminate + .govuk-checkboxes__label::after {
  transform: rotate(0);
  border: none;
  top: 18px;
  left: 4px;
  height: 4px;
  width: 16px;
  background: currentColor;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/multi-select-field-schema/multi-select-field-schema.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;AACJ;;AAEE;EACE,SAAA;EACA,YAAA;AACJ;;AAEE;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AACJ;;AAEE;EACE,uBAAA;EACA,yBAAA;AACJ;;AAEE;EACE,cAAA;EACA,oBAAA;EACA,oBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;AACJ;;AAKE;EACE,sBAAA;EACA,4BAAA;AAFJ;;AAIE;EACE,oBAAA;EACA,YAAA;EACA,SAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,wBAAA;EACA,UAAA;AADJ","sourcesContent":[".header {\n    background-color: #f3f2f1;\n    position: relative;\n    padding: 0.5rem;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n  \n  .title {\n    margin: 0;\n    width: 16rem;\n  }\n  \n  .subtext {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    margin-right: 1rem;\n  }\n  \n  .toggle-button {\n    position: absolute;\n    top: 0.4rem;\n    right: 0.4rem;\n    width: 2rem;\n    height: 2rem;\n    border: none;\n    background: none;\n    cursor: pointer;\n  }\n  \n  .body {\n    background-color: white;\n    border: 2px solid #f3f2f1;\n  }\n  \n  .multi-select {\n    display: block;\n    padding-left: 0.5rem;\n    padding-bottom: 10px;\n    max-height: 20rem;\n    overflow-y: auto;\n    overflow-x: hidden;\n    text-overflow: ellipsis;\n  }\n  \n  // .govuk-checkboxes__item input {\n  //   width: 100%;\n  // }\n  .govuk-checkboxes__label {\n    padding-bottom: 0.2rem;\n    font-size: 1.1rem !important;\n  }\n  .govuk-checkboxes__input:indeterminate + .govuk-checkboxes__label::after {\n    transform: rotate(0);\n    border: none;\n    top: 18px;\n    left: 4px;\n    height: 4px;\n    width: 16px;\n    background: currentColor;\n    opacity: 1;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
