import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagingComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() length: number;
  @Input() showFirstLast = true;
  @Output() pageSelected = new EventEmitter<any>();

  currentPage: any;
  pageSize = 50;
  pageIndex = 0;
  pageSizeOptions: number[] = [];

  ngOnInit(): void {
    this.pageSizeOptions = this.getPageSizeOptions();
  }

  getPageSizeOptions(): Array<number> {
    const response = new Array<number>();
    let i = 1;
    while (i <= Math.ceil(this.length / this.pageSize) && (i * this.pageSize) <= 500) {
      response.push(i * this.pageSize);
      i++;
    }
    return response;
  }

  emitPaginationEvent($event: any) {
    this.pageSizeOptions = this.getPageSizeOptions();
    this.pageSelected.emit($event)
    this.resetFirstPage($event);
    this.currentPage = $event;
  }

  resetFirstPage($event: any) {
    if (this.currentPage?.pageSize && this.currentPage.pageSize !== $event.pageSize) {
      this.paginator?.firstPage();
    }
  }

  ngOnChanges() {
    this.paginator?.firstPage();
    this.pageSizeOptions = this.getPageSizeOptions();
  }
}
