import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  @Output() clickOutside = new EventEmitter<void>();
  clickedInside = false;

  @HostListener('click')
  inside() {
    this.clickedInside = true;
  }

  @HostListener('document:click')
  outside() {
    if (!this.clickedInside) {
      this.clickOutside.emit();
    }
    this.clickedInside = false;
  }
}
