// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: white;
  position: relative;
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.compact-title {
  font-size: 1rem;
  font-weight: 400;
}

.body {
  padding: 0 5px 0 5px;
  background-color: white;
}

.select {
  position: relative;
  width: 100%;
  background: white;
}

.select-title {
  width: 100%;
  background-color: white;
  background-image: url("/assets/images/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 6px top 6px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-title div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 1.5rem);
}

.select-dropdown {
  width: calc(100% - 4px);
  position: absolute;
  top: 40px;
  left: 0;
  height: 20rem;
  z-index: 1000;
  background: white;
  border: 2px solid black;
  overflow-y: scroll;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.govuk-radios__item {
  margin-left: 0.3rem;
  width: calc(100% - 1.5rem);
}

.govuk-radios__label {
  padding-bottom: 0.2rem;
  font-size: 1.1rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/radio-dropdown/radio-dropdown.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,oBAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,uBAAA;EACA,sDAAA;EACA,4BAAA;EACA,sCAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,0BAAA;AACF;;AAEA;EACE,uBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,aAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,uBAAA;AACF;;AAEA;EACE,mBAAA;EACA,0BAAA;AACF;;AAEA;EACE,sBAAA;EACA,4BAAA;AACF","sourcesContent":[".header {\n  background-color: white;\n  position: relative;\n  padding: 0.5rem;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.compact-title {\n  font-size: 1rem;\n  font-weight: 400;\n}\n\n.body {\n  padding: 0 5px 0 5px;\n  background-color: white;\n}\n\n.select {\n  position: relative;\n  width: 100%;\n  background: white;\n}\n\n.select-title {\n  width: 100%;\n  background-color: white;\n  background-image: url('/assets/images/arrow-down.svg');\n  background-repeat: no-repeat;\n  background-position: right 6px top 6px;\n  text-align: left;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.select-title div {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: calc(100% - 1.5rem);\n}\n\n.select-dropdown {\n  width: calc(100% - 4px);\n  position: absolute;\n  top: 40px;\n  left: 0;\n  height: 20rem;\n  z-index: 1000;\n  background: white;\n  border: 2px solid black;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  text-overflow: ellipsis;\n}\n\n.govuk-radios__item {\n  margin-left: 0.3rem;\n  width: calc(100% - 1.5rem);\n}\n\n.govuk-radios__label {\n  padding-bottom: 0.2rem;\n  font-size: 1.1rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
