import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Filters, IFieldSearchTerms } from '../../../maintain-data/models/search-filter.model';

export interface IDateRange {
  fromDate: string;
  endDate: string;
}

@Component({
  selector: 'date-range-field',
  templateUrl: './date-range-field.component.html',
  styleUrls: ['./date-range-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeFieldComponent implements OnInit {

  fromDate: string;
  endDate: string;
  invalidRange = false;

  @Input() title: string;
  @Input() compactView = false;
  @Input() fieldName?: Filters;
  @Input() clearFilter$: Observable<Filters | null>;
  @Output() datesChanged = new EventEmitter<IFieldSearchTerms>();

  ngOnInit(): void {
    this.clearFilter$?.subscribe(filter => {
      filter
        ? filter === this.fieldName && this.clearFilters()
        : this.clearFilters();
    });
  }

  clearFilters(): void {
    this.fromDate = null;
    this.endDate = null;
    this.datesChanged.emit({
      fieldName: this.fieldName,
      searchTerms: {fromDate: this.fromDate, endDate: this.endDate} as IDateRange
    });
  }

  onNgModelChange(): void {
    this.invalidRange = this.fromDate && this.endDate
      ? new Date(this.formattedDate(this.endDate)) < new Date(this.formattedDate(this.fromDate))
      : false;

    this.datesChanged.emit({
      fieldName: this.fieldName,
      searchTerms: this.invalidRange
        ? {fromDate: null, endDate: null} as IDateRange
        : {fromDate: this.fromDate, endDate: this.endDate} as IDateRange
    });
  }

  formattedDate(input: string): string {
    return input.split("/").reverse().join("-");
  }
}
