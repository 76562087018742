import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BannerStateStore } from './banner-state.store';
import { BannerStateState } from './banner-state.model';

/**
 * BannerState query
 *
 * @export
 * @class BannerStateQuery
 * @extends {Query<BannerStateState>}
 */
@Injectable({ providedIn: 'root' })
export class BannerStateQuery extends Query<BannerStateState> {

  banner$  = this.select(x => x.ui)

  constructor(protected store: BannerStateStore) {
    super(store);
  }
}
