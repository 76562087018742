import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, forkJoin, NEVER, Observable, Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { ControlType, IProviderattribute, Providerattribute } from '../../../models/provider-attribute';
import { UserApiService } from 'src/app/services/api/user-api.service';
import { FDSReferenceData } from '../../../models/fds-reference-data';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { dateFormat } from 'src/app/record-editor/record-editor.model';
import { PaymentOrganisation, PaymentOrganisationPagingResponse } from 'src/app/models/payment-organisation';
import { PaymentOrganisationApiService } from 'src/app/services/api/payment-organisation-api.service';
import { QueryModel } from 'src/app/models/query-model';
import { ProviderScopeUpdatesService } from 'src/app/bulk-update-provider-data/services/provider-scope-updates.service';
import { DisplayText, ProviderattributeValue } from 'src/app/models/provider-attribute-value';
import { ProviderColumnName, ReferenceColumnName } from 'src/app/maintain-data/components/providers/provider-tab/provider-tab.model';
import { ALLOWED_NUMERIC_VALUES } from 'src/app/validators/allowedCharacters';
import { Helpers } from 'src/core/helpers';

@Component({
  selector: 'provider-attribute-select',
  templateUrl: './provider-attribute-select.component.html',
  styleUrls: ['./provider-attribute-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderAttributeSelectComponent implements OnInit {

  @Input() selections: FDSReferenceData[] = [];
  @Input() multiSelect: boolean = false;
  @Input() addText = 'Add provider attribute';
  @Input() checkErrors$: Observable<void>;
  @Input() storeSelections: boolean = true;
  /*** If required, the selectors will show if no option is selected, and the state will be incomplete ***/
  @Input() required: boolean = false;
  @Output() incompleteSelection = new EventEmitter<boolean>();

  // local copy of input options.
  _options: IProviderattribute[];
  _providerAttributeOptions$: IProviderattribute[];
  _updatedproviderAttributeOptions$: IProviderattribute[];
  _selectedProviderAttribute: IProviderattribute = null;
  _providerReferenceDataOptions$: FDSReferenceData[];
  _selectedProviderAttributeValue: FDSReferenceData = null;
  _selectedProviderAttributeValues: FDSReferenceData[];
  _paymentOrgeDataOption$: PaymentOrganisation[];
  _selectedPaymentOrgeDataValue: PaymentOrganisation[] = null;
  _selectedPaymentOrgeData: PaymentOrganisation = null;
  selectedAttributes$: ProviderattributeValue[];

  _showAddButton: boolean;
  _invalidDate: boolean = false;
  _providerAttributeName: string = null;
  _isEmpty: boolean = false;
  saving = false;
  loadingResults = new BehaviorSubject(false);
  _showSelector: boolean;
  _showSelectorError: boolean = false;
  _isPaymentOrg: boolean = false;
  _isAuthority: boolean = false;
  _isAuthorityDisplayMessage: boolean = false;
  _notifier$ = new Subject<void>();
  user$ = null
  hasFsAccess: boolean = true;
  // Data Source - Providers attribute data
  providerReferenceData: FDSReferenceData[];
  paymentOrganisationPagingResponse: PaymentOrganisationPagingResponse;
  dateForm: UntypedFormGroup;
  inputForm: UntypedFormGroup;
  isDropdown: boolean = true;
  customAttributesSelectedValues: Providerattribute[];
  authorities: FDSReferenceData[];
  freeTextHasError: boolean;
  freeTextErrorMessage: string;
  dateInputHasError: boolean;
  dateInputErrorMessage: string;

  constructor(private cd: ChangeDetectorRef, private userApiService: UserApiService,
    private referenceDataService: ReferenceDataService, private fb: UntypedFormBuilder,
    private paymentOrganisationApi: PaymentOrganisationApiService,
    private providerScopeUpdatesService: ProviderScopeUpdatesService) {
    this.userApiService.getUserProfile().subscribe((user) => {
      this.user$ = user;
    });
  }

  ngOnInit(): void {

    const dayValue = null;
    const monthValue = null;
    const yearValue = null;
    this.dateForm = this.fb.group({
      day: [dayValue, [Validators.required, Validators.maxLength(2), Validators.pattern(ALLOWED_NUMERIC_VALUES)]],
      month: [monthValue, [Validators.required, Validators.maxLength(2), Validators.pattern(ALLOWED_NUMERIC_VALUES)]],
      year: [yearValue, [Validators.required, Validators.maxLength(4), Validators.pattern(ALLOWED_NUMERIC_VALUES)]]
    });

    this.providerScopeUpdatesService.getProviderAttributeSelectedValue().subscribe(res => {
      this.selectedAttributes$ = res;
    });
    this._selectedProviderAttributeValues = this.providerScopeUpdatesService.getSelectedProviderAttributeValue();
    this.providerScopeUpdatesService.getProviderAttributeDateValue().subscribe(res => {
      this.customAttributesSelectedValues = res;
    });
    this._selectedPaymentOrgeDataValue = this.providerScopeUpdatesService.getProviderPaymentAttributeValue();

    this._showAddButton = this.selectedAttributes$?.length === 0 || this.multiSelect;
    this._showSelector = this.required;
    this.incompleteSelection.emit(this.required && this.selectedAttributes$?.length === 0);
    this._options = this.getAttributes();
    this._providerAttributeOptions$ = this._options;
    this.checkErrors$?.subscribe(() => {
      this._showSelectorError = this._showSelector;
      this.cd.markForCheck();
    });

    const selectedAttributeValues = this._selectedProviderAttributeValues.map((selection) => selection.lookupName.toLocaleLowerCase());
    this._providerAttributeOptions$ = this._providerAttributeOptions$
      .filter((item: Providerattribute) => !selectedAttributeValues.includes(item.providerAttributeCode.toLocaleLowerCase()));

    const selectedAttributes = this.customAttributesSelectedValues.map((selection) => selection.providerAttributeCode.toLocaleLowerCase());
    this._providerAttributeOptions$ = this._providerAttributeOptions$
      .filter((item: Providerattribute) => !selectedAttributes.includes(item.providerAttributeCode.toLocaleLowerCase()));

    const selectePaymentdAttributesLength = this._selectedPaymentOrgeDataValue.length;
    const selectePaymentdAttributes = "paymentOrganisation";
    if (selectePaymentdAttributesLength !== 0) {
      this._providerAttributeOptions$ = this._providerAttributeOptions$
        .filter((item: Providerattribute) => !selectePaymentdAttributes.includes(item.providerAttributeCode));
    }
    this.getUpdatedMasterAttributes();
    this.referenceDataService
      .getReferenceDataLoaded()
      .pipe(
        mergeMap((referenceDataLoaded) => {
          return referenceDataLoaded
            ? forkJoin([
              this.referenceDataService.getProviderReferenceData()
            ])
            : NEVER;
        })
      )
      .pipe(takeUntil(this._notifier$))
      .subscribe(([providerRefData]) => {
        this.providerReferenceData = providerRefData;
      });
  }

  setConditionalRequiredValidator(requiredValidation: any[]) {
    this.inputForm = this.fb.group({
      inputData: ['', requiredValidation]
    });
  }

  getUpdatedMasterAttributes() {
    this._updatedproviderAttributeOptions$ = this._providerAttributeOptions$.filter(x => x.isVisible === true);
  }

  updateMasterAttributeStatus(code: string, status: boolean) {
    this._providerAttributeOptions$.find(x => x.providerAttributeCode === code).isVisible = status;
    this.getUpdatedMasterAttributes();
  }

  buildNotificationErrors(form: UntypedFormGroup): string[] {
    const inputControl = form.get('inputData');
    let message = [];

    if (inputControl.invalid) {
      this.freeTextHasError = true;

      if (inputControl.errors?.required) {
        this.freeTextErrorMessage = 'Enter a provider attribute value';
        message.push(this.freeTextErrorMessage);
      }

      if (inputControl.errors?.pattern) {
        this.freeTextErrorMessage = this.numberOnlyError(
          this._selectedProviderAttribute.providerAttributeName
        );
        message.push(this.freeTextErrorMessage);
      }

      if (inputControl.errors?.maxlength) {
        this.freeTextErrorMessage = this.maxLengthError(
          this._selectedProviderAttribute.providerAttributeName,
          this._selectedProviderAttribute.validationRules?.maxLength
        );
        message.push(this.freeTextErrorMessage);
      }

      if (inputControl.errors?.max) {
        this.freeTextErrorMessage = this.maxError(this._selectedProviderAttribute.providerAttributeName, this._selectedProviderAttribute.validationRules?.max);
        message.push(this.freeTextErrorMessage);
      }
    }
    return message;
  }

  resetError() {
    this.freeTextHasError = false;
    this.freeTextErrorMessage = '';
  }

  dateResetError() {
    this._invalidDate = false;
    this.dateForm.controls.day.setValue('');
    this.dateForm.controls.month.setValue('');
    this.dateForm.controls.year.setValue('');
  }

  numberOnlyError(propertyName: string) {
    return `${Helpers.getTransformedColumnHeader(
      propertyName
    ).toUpperCase()} should contain numeric characters, with no leading zeros`;
  }

  maxLengthError(propertyName: string, maxLength: number) {
    return `Enter a ${Helpers.getTransformedColumnHeader(
      propertyName
    ).toLowerCase()} of ${maxLength} characters or less`;
  }

  maxError(propertyName: string, max: number) {
    return `${Helpers.getTransformedColumnHeader(
      propertyName
    ).toUpperCase()} must be ${max} or lower.`;
  }

  onProviderAttributeSelected(selected: IProviderattribute): void {
    this.resetError();
    this._showSelectorError = false;
    this._selectedProviderAttribute = selected;
    let requiredValidation = [];
    if (this._selectedProviderAttribute.control === 'Textbox') {
      if (selected.validationRules?.isRequired) {
        requiredValidation.push(Validators.required);
      }
      if (selected.validationRules?.pattern) {
        requiredValidation.push(Validators.pattern(ALLOWED_NUMERIC_VALUES));
      }
      if (selected.validationRules?.maxLength) {
        requiredValidation.push(Validators.maxLength(selected.validationRules?.maxLength));
      }
      if (selected.validationRules?.max) {
        requiredValidation.push(Validators.max(selected.validationRules?.max));
      }
    }
    if (this._selectedProviderAttribute.providerAttributeCode == "paymentOrganisation") {
      this.getAllPaymentOrganisations();
      this._isPaymentOrg = true;
      this._isAuthority = false;
    } else if (selected.providerAttributeCode === "previousLaName") {
      this._isPaymentOrg = false;
      this._isAuthority = false;
      this._providerReferenceDataOptions$ = this.providerReferenceData
        .filter((item: FDSReferenceData) => item.lookupName === "Authority")
        .sort((a, b) => a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1);
    }
    else if (selected.providerAttributeCode === "Authority") {
      this._isAuthorityDisplayMessage = true;
      this._isAuthority = true;
      this.authorities = this.providerReferenceData.filter(x => x.lookupName === ReferenceColumnName.authority)
        .sort((a, b) => a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1);
    }
    else {
      this._isPaymentOrg = false;
      this._isAuthority = false;
      this._providerReferenceDataOptions$ = this.providerReferenceData
        .filter((item: FDSReferenceData) => item.lookupName.toLocaleLowerCase() === selected.providerAttributeCode.toLocaleLowerCase())
        .sort((a, b) => a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1);
    }
    if (selected.control === 'Date') {
      this.dateResetError();
    }
    this.setConditionalRequiredValidator(requiredValidation);
  }

  onProviderAttributeValueSelected(selectedProviderAttributeValue: FDSReferenceData): void {
    this._showSelectorError = false;
    this._selectedProviderAttributeValue = selectedProviderAttributeValue;
  }

  onProviderPaymentOrgAttributeValueSelected(selectedProviderAttributeValue: PaymentOrganisation): void {
    this._showSelectorError = false;
    this._selectedPaymentOrgeData = selectedProviderAttributeValue;
  }

  onPaymentSelectClicked(): void {
    let newSelectionValue = new FDSReferenceData(); // Store the value for data update
    let tempSelectedValue = new ProviderattributeValue(); // Display in the screen 
    this._selectedPaymentOrgeDataValue.push(this._selectedPaymentOrgeData);

    tempSelectedValue.providerAttributeValue = this._selectedPaymentOrgeDataValue[0].name + " / UKPRN: " + this._selectedPaymentOrgeDataValue[0].ukprn + " / Type: " + this._selectedPaymentOrgeDataValue[0].paymentOrganisationType;
    tempSelectedValue.providerAttributeCode = this._selectedProviderAttribute.providerAttributeCode;
    tempSelectedValue.displayText = DisplayText.paymentOrganisation;

    newSelectionValue.name = this._selectedPaymentOrgeDataValue[0].name + " / UKPRN: " + this._selectedPaymentOrgeDataValue[0].ukprn + " / Type: " + this._selectedPaymentOrgeDataValue[0].paymentOrganisationType;
    newSelectionValue.lookupName = this._selectedProviderAttribute.providerAttributeCode;

    if (this.storeSelections) {
      this.selections.push(newSelectionValue);
      this.selectedAttributes$.push(tempSelectedValue);
      this.providerScopeUpdatesService.updateProviderPaymentAttributeValue(this._selectedPaymentOrgeDataValue);
      this.providerScopeUpdatesService.updateProviderAttributeSelectedValue([tempSelectedValue]);
      this._showAddButton = false;

      //Filter out Provider atribute whose value have all been selected
      this.updateMasterAttributeStatus(this._selectedProviderAttribute.providerAttributeCode, false);
    } else {
      this.providerScopeUpdatesService.updateProviderPaymentAttributeValue(this._selectedPaymentOrgeDataValue);
      this.providerScopeUpdatesService.updateProviderAttributeSelectedValue([tempSelectedValue]);
      this._showAddButton = true;
    }

    this.incompleteSelection.emit(false);
    this._selectedProviderAttribute = null;
    this._selectedProviderAttributeValue = null;
    this._showSelectorError = false;
    this._showSelector = false;
  }

  onSelectClicked(): void {
    this.resetError();
    let newSelectionValue = new FDSReferenceData();
    let selectedValue = new Providerattribute();
    let tempSelectedValue = new ProviderattributeValue(); // Display in the screen 

    if (this._selectedProviderAttribute.control == ControlType.Dropdown) {
      newSelectionValue = this._selectedProviderAttributeValue;
      if (this._selectedProviderAttribute.providerAttributeCode === "previousLaName") {
        newSelectionValue.lookupName = "previousLaName";
      }
      tempSelectedValue.providerAttributeValue = this._selectedProviderAttributeValue.name;
      tempSelectedValue.providerAttributeCode = this._selectedProviderAttributeValue.lookupName;
      tempSelectedValue.displayText = DisplayText[this._selectedProviderAttributeValue.lookupName];
      this.isDropdown = true;

      this.updateMasterAttributeStatus(tempSelectedValue.providerAttributeCode, false);
    } else if (this._selectedProviderAttribute.control == ControlType.Textbox) {
      if (this.inputForm.invalid) {
        this.buildNotificationErrors(this.inputForm);
        return;
      }
      this.isDropdown = false;
      selectedValue.providerAttributeName = this.inputForm.value.inputData;
      selectedValue.providerAttributeCode = this._selectedProviderAttribute.providerAttributeCode;
      selectedValue.control = this._selectedProviderAttribute.control;
      tempSelectedValue.providerAttributeValue = this.inputForm.value.inputData;
      tempSelectedValue.providerAttributeCode = this._selectedProviderAttribute.providerAttributeCode;
      tempSelectedValue.displayText = DisplayText[this._selectedProviderAttribute.providerAttributeCode];
      this.updateMasterAttributeStatus(tempSelectedValue.providerAttributeCode, false);

    } else if (this._selectedProviderAttribute.control == ControlType.Date) {
      this._invalidDate = false;
      this._providerAttributeName = this._selectedProviderAttribute.providerAttributeName;
      if (!this.isValidDate()) {
        return;
      }
      let isLeapYear = false;
      this.isDropdown = false;
      const dayNumber = this.dateForm.get(dateFormat.day).value;
      const monthNumber = this.dateForm.get(dateFormat.month).value;
      const yearNumber = this.dateForm.get(dateFormat.year).value;
      if ((dayNumber > 28 && monthNumber == 2 && yearNumber.length == 4)) {
        if ((dayNumber == 29) && ((0 == yearNumber % 4) && (0 != yearNumber % 100) || (0 == yearNumber % 400))) {
          isLeapYear = true;
          this._invalidDate = false;
        }
        if (!isLeapYear)
          this._invalidDate = true;
      }
      else if (dayNumber > 31 || monthNumber > 12 || yearNumber.length < 4) {
        this._invalidDate = true;
      }
      else if (dayNumber == 0 || monthNumber == 0 || yearNumber == 0) {
        this._invalidDate = true;
      }
      if (!this._invalidDate) {
        const newDate = (dayNumber && monthNumber && yearNumber)
        ? dayjs(new Date())
          .set('date', dayNumber)
          .set('month', monthNumber - 1)
          .set('year', yearNumber)
          .set('hour', 2).set('minute', 0).set('second', 0)
          .toString()
        : null;
        if (newDate !== null) {
          selectedValue.providerAttributeName = newDate.toString();
          selectedValue.providerAttributeCode = this._selectedProviderAttribute.providerAttributeCode;
          selectedValue.control = this._selectedProviderAttribute.control;
          tempSelectedValue.providerAttributeValue = newDate.toString();
          tempSelectedValue.providerAttributeCode = this._selectedProviderAttribute.providerAttributeCode;
          tempSelectedValue.displayText = DisplayText[this._selectedProviderAttribute.providerAttributeCode];
          this.updateMasterAttributeStatus(tempSelectedValue.providerAttributeCode, false);
          this._invalidDate = false;
        } else {
          this._invalidDate = true;
        }
      }
      if (this._invalidDate) {
        this.dateInputErrorMessage = this._providerAttributeName + ' date must be a real date';
      }
    }
    if (this.storeSelections) {

      if (newSelectionValue?.code !== undefined) {
        this.selections.push(newSelectionValue);
        this.selectedAttributes$.push(tempSelectedValue);
        this._selectedProviderAttributeValues.push(newSelectionValue);

        this.providerScopeUpdatesService.updateProviderAttributeValue(this._selectedProviderAttributeValues);
        this.providerScopeUpdatesService.updateProviderAttributeSelectedValue(this.selectedAttributes$);
        this._showAddButton = false;
      } else if (selectedValue?.control !== undefined) {
        let tempSelectionValue = new FDSReferenceData();
        tempSelectionValue.name = selectedValue.providerAttributeName;
        tempSelectionValue.lookupName = selectedValue.providerAttributeCode;
        this.selections.push(tempSelectionValue);
        this.selectedAttributes$.push(tempSelectedValue);
        this.customAttributesSelectedValues.push(selectedValue);

        this.providerScopeUpdatesService.updateProviderAttributeDateValue(this.customAttributesSelectedValues);
        this.providerScopeUpdatesService.updateProviderAttributeSelectedValue(this.selectedAttributes$);
        this._showAddButton = false;
      }

    } else {
      this.providerScopeUpdatesService.updateProviderAttributeValue([newSelectionValue]);
      this.providerScopeUpdatesService.updateProviderAttributeDateValue([selectedValue]);
      this.providerScopeUpdatesService.updateProviderAttributeSelectedValue([tempSelectedValue]);
      this._showAddButton = true;
    }

    if (!this._invalidDate && !this._isEmpty) {
      this.incompleteSelection.emit(false);
      this._selectedProviderAttribute = null;
      this._selectedProviderAttributeValue = null;
      this._showSelectorError = false;
      this._showSelector = false;
    }

  }

  isValidDate(): boolean {
    if (this.dateForm.invalid) {
      this._invalidDate = true;
      if (this.dateForm.controls.day.errors?.required || this.dateForm.controls.month.errors?.required
        || this.dateForm.controls.year.errors?.required) {
        this.dateInputErrorMessage = 'Enter a provider attribute value';
      }
      if (this.dateForm.controls.day.errors?.pattern || this.dateForm.controls.month.errors?.pattern
        || this.dateForm.controls.year.errors?.pattern) {
        this.dateInputErrorMessage = this._providerAttributeName + ' date must be a real date';
      }
      if (this.dateForm.controls.day.errors?.maxlength || this.dateForm.controls.month.errors?.maxlength
        || this.dateForm.controls.year.errors?.maxlength) {
        this.dateInputErrorMessage = this._providerAttributeName + ' date must be a real date';
      }
      return false;
    }
    else {
      return true;
    }
  }

  onAddClicked(): void {
    this._showSelector = true;
    this._showAddButton = true;
    this._isAuthorityDisplayMessage = false;
    this.incompleteSelection.emit(true);
  }

  onRemoveClicked(entry: FDSReferenceData): void {
    const index = this._selectedProviderAttributeValues.findIndex((s) => s.fdsuid === entry.fdsuid)
    this._selectedProviderAttributeValues.splice(index, 1);

    if (this._selectedProviderAttributeValues.length == 0)
      this._showAddButton = true;
    //Filter out Provider atribute whose value have all been selected
    this.updateMasterAttributeStatus(entry.lookupName, true);

    this._showSelector = this.required && this._selectedProviderAttributeValues.length === 0;
    this.providerScopeUpdatesService.updateProviderAttributeValue(this._selectedProviderAttributeValues);
    this._invalidDate = false; this._isEmpty = false;
    this._isAuthorityDisplayMessage = false;
  }

  onAttributeRemoveClicked(entry: Providerattribute): void {
    const index = this.customAttributesSelectedValues.findIndex((s) => s.providerAttributeCode === entry.providerAttributeCode)
    this.customAttributesSelectedValues.splice(index, 1);
    if (this.customAttributesSelectedValues.length == 0)
      this._showAddButton = true;
    //Filter out Provider atribute whose value have all been selected
    this.updateMasterAttributeStatus(entry.providerAttributeCode, true);

    this._showSelector = this.required && this.customAttributesSelectedValues.length === 0;
    this.providerScopeUpdatesService.updateProviderAttributeDateValue(this.customAttributesSelectedValues);
    this._invalidDate = false; this._isEmpty = false;
    this._isAuthorityDisplayMessage = false;
  }

  onPaymentRemoveClicked(entry: PaymentOrganisation): void {
    const index = this._selectedPaymentOrgeDataValue.findIndex((s) => s.paymentOrganisationId === entry.paymentOrganisationId)
    this._selectedPaymentOrgeDataValue.splice(index, 1);
    if (this._selectedPaymentOrgeDataValue.length == 0)
      this._showAddButton = true;
    //Filter out Provider atribute whose value have all been selected
    const selectedAttributes = DisplayText.paymentOrganisation;
    this.updateMasterAttributeStatus(ProviderColumnName.paymentOrganisation, true);

    this._showSelector = this.required && this._selectedPaymentOrgeDataValue.length === 0;
    this.providerScopeUpdatesService.updateProviderPaymentAttributeValue(this._selectedPaymentOrgeDataValue);
    this._invalidDate = false; this._isEmpty = false;
    this._isAuthorityDisplayMessage = false;
  }

  onCancelClicked(): void {
    this._selectedProviderAttribute = null;
    this._selectedProviderAttributeValue = null;
    this._showSelector = this.required && this._selectedProviderAttributeValues.length === 0;
    this._showSelectorError = false;
    this._showAddButton = false;
    this.incompleteSelection.emit(this._showSelector);
    this._invalidDate = false; this._isEmpty = false;
    this._isAuthorityDisplayMessage = false;
  }

  getAllPaymentOrganisations() {
    this.loadingResults.next(true);
    const queryModel = new QueryModel(0, 50, 'name', false, [], true);
    this.paymentOrganisationApi.paymentOrganisationQuery(queryModel).subscribe((response) => {
      this.paymentOrganisationPagingResponse = response;
      this.getPaymentOrganisationDropdownValues();
    });
  }

  getPaymentOrganisationDropdownValues() {
    this.paymentOrganisationPagingResponse?.data.unshift({} as PaymentOrganisation);
    this._paymentOrgeDataOption$ = this.paymentOrganisationPagingResponse?.data;
    this.loadingResults.next(false);
  }

  getDisplayText(lookup: string): string {
    let displayText = DisplayText[lookup]
    return displayText;
  }

  getAttributes(): Providerattribute[] {
    return [
      { providerAttributeName: 'Authority', providerAttributeCode: ReferenceColumnName.authority, control: ControlType.Dropdown, isVisible: true },
      { providerAttributeName: 'Date Closed', providerAttributeCode: ProviderColumnName.dateClosed, control: ControlType.Date, isVisible: true },
      { providerAttributeName: 'Date Opened', providerAttributeCode: ProviderColumnName.dateOpened, control: ControlType.Date, isVisible: true },
      {
        providerAttributeName: 'Dfe Establishment Number',
        providerAttributeCode: ProviderColumnName.dfeEstablishmentNumber,
        control: ControlType.Textbox,
        isVisible: true,
        validationRules: { maxLength: 16, pattern: ALLOWED_NUMERIC_VALUES, isRequired: true }
      },
      {
        providerAttributeName: 'District Code',
        providerAttributeCode: 'districtCode',
        control: ControlType.Textbox,
        isVisible: true,
        validationRules: { maxLength: 16, isRequired: true }
      },
      {
        providerAttributeName: 'Establishment Number',
        providerAttributeCode: ProviderColumnName.establishmentNumber,
        control: ControlType.Textbox,
        isVisible: true,
        validationRules: { maxLength: 16, pattern: ALLOWED_NUMERIC_VALUES, isRequired: true }
      },
      { providerAttributeName: 'Further Education Type', providerAttributeCode: ReferenceColumnName.furtherEducationType, control: ControlType.Dropdown, isVisible: true },
      {
        providerAttributeName: 'Lower Super Output Area Code',
        providerAttributeCode: 'lowerSuper',
        control: ControlType.Textbox,
        isVisible: true,
        validationRules: { maxLength: 16, isRequired: true }
      },
      {
        providerAttributeName: 'Middle Super Output Area Code',
        providerAttributeCode: 'middleSuper',
        control: ControlType.Textbox,
        isVisible: true,
        validationRules: { maxLength: 16, isRequired: true }
      },
      { providerAttributeName: 'Payment Organisation Name', providerAttributeCode: ProviderColumnName.paymentOrganisation, control: ControlType.Dropdown, isVisible: true },
      { providerAttributeName: 'Previous LA Name', providerAttributeCode: ProviderColumnName.previousLaName, control: ControlType.Dropdown, isVisible: true }, // Not editable
      { providerAttributeName: 'Provider Status', providerAttributeCode: ReferenceColumnName.providerStatus, control: ControlType.Dropdown, isVisible: true },
      { providerAttributeName: 'Provider Sub type', providerAttributeCode: ReferenceColumnName.providerSubType, control: ControlType.Dropdown, isVisible: true },
      { providerAttributeName: 'Provider Type', providerAttributeCode: ReferenceColumnName.providerType, control: ControlType.Dropdown, isVisible: true },
      { providerAttributeName: 'Reason Establishment Closed', providerAttributeCode: ReferenceColumnName.reasonEstablishmentClosed, control: ControlType.Dropdown, isVisible: true },
      { providerAttributeName: 'Reason Establishment Opened', providerAttributeCode: ReferenceColumnName.reasonEstablishmentOpened, control: ControlType.Dropdown, isVisible: true },
      {
        providerAttributeName: 'Statutory High Age',
        providerAttributeCode: ProviderColumnName.statutoryHighAge,
        control: ControlType.Textbox,
        isVisible: true,
        validationRules: { maxLength: 5, max: 99999, pattern: ALLOWED_NUMERIC_VALUES, isRequired: true }
      },
      {
        providerAttributeName: 'Statutory Low Age',
        providerAttributeCode: ProviderColumnName.statutoryLowAge,
        control: ControlType.Textbox,
        isVisible: true,
        validationRules: { maxLength: 5, max: 99999, pattern: ALLOWED_NUMERIC_VALUES, isRequired: true }
      },
      { providerAttributeName: 'Trust Status', providerAttributeCode: ReferenceColumnName.trustStatus, control: ControlType.Dropdown, isVisible: true }
    ] as Providerattribute[];
  }

  errorGroupCss(hasError: boolean) {
    return !hasError
      ? 'record-editor-left-padding-1'
      : 'govuk-form-group--error';
  }

  errorElementCss(hasError: boolean) {
    return !hasError ? '' : 'govuk-input--error';
  }
}
