import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal-dialog',
  templateUrl: './confirmation-modal-dialog.component.html',
  styleUrls: ['./confirmation-modal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalDialogComponent {
  content = '';
  constructor(private dialogRef: MatDialogRef<ConfirmationModalDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: { message: string }) {
    dialogRef.disableClose = true;
    this.content = data ? data.message : '';
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  discard(): void {
    this.dialogRef.close(false);
  }

}
