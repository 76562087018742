import { Component } from '@angular/core';
import { ProviderApiService } from '../../services/api/provider-api.service';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: 'app-file-import',
  templateUrl: './file-import.component.html',
  styleUrls: ['./file-import.component.scss']
})
export class FileImportComponent {
  uploadFinished: boolean;
  errorMessage: string;
  uploadSuccess: boolean;
  uploading: boolean;

  constructor(private providerApi: ProviderApiService) {
  }

  onFileUploadChange(event: Event) {
    const e = event as HTMLInputEvent;
    const files: FileList = e.target.files;
    this.uploadFinished = false;
    this.uploading = true;
    const file = files.item(0);
    if (file) {
      const fileParameter = { data: file, fileName: file.name }
      this.providerApi.uploadCsv(fileParameter).subscribe(
        () => {
          this.uploadFinished = true;
          this.uploadSuccess = true;
          this.uploading = false;
        }, (error) => {
          this.uploadFinished = true;
          this.uploadSuccess = false;
          this.uploading = false;
          this.errorMessage = error.title;
        });
    }
  }
}
