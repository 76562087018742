import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabContainerComponent } from './components/tab-container/tab-container.component';
import { TabBodyComponent } from './components/tab-body/tab-body.component';
import { ProviderSearchFilterService } from '../maintain-data/services/provider-search-filter.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TabContainerComponent,
    TabBodyComponent
  ],
  exports: [
    TabContainerComponent,
    TabBodyComponent
  ],
  providers: [
    ProviderSearchFilterService
  ]
})
export class DataTabModule {}
