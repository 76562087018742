// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csMarginBottom {
  margin-bottom: 0px !important;
}

.flex-row {
  display: flex;
  align-content: flex-end;
}

.cancel-link {
  margin-top: 0.6rem;
  padding-left: 1rem;
}

.record-editor-element {
  padding-bottom: 1rem;
}

.margin-left-0 {
  margin-left: 0;
}

.record-editor-left-padding-1 {
  padding-left: 1.16rem;
}

.w-100 {
  width: 100%;
}

.p-0 {
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/record-editor/free-text/free-text.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AAGA;EACI,aAAA;EACA,uBAAA;AAAJ;;AAGE;EACE,kBAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,oBAAA;AAAJ;;AAGE;EACE,cAAA;AAAJ;;AAGE;EACE,qBAAA;AAAJ;;AAGE;EACE,WAAA;AAAJ;;AAGE;EACE,qBAAA;AAAJ","sourcesContent":[".csMarginBottom{\n    margin-bottom: 0px !important;\n}\n\n//module.scss\n.flex-row {\n    display: flex;\n    align-content: flex-end;\n  }\n  \n  .cancel-link {\n    margin-top: .6rem;\n    padding-left: 1rem;\n  }\n  \n  .record-editor-element {\n    padding-bottom: 1rem;\n  }\n  \n  .margin-left-0 {\n    margin-left: 0;\n  }\n  \n  .record-editor-left-padding-1 {\n    padding-left: 1.16rem;\n  }\n  \n  .w-100 {\n    width: 100%;\n  }\n  \n  .p-0 {\n    padding: 0 !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
