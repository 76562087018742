export interface IFDSReferenceData {
  id: number;
  fdsuid: string;
  // Display name of reference property.
  name: string;
  // Name of reference type e.g. 'Authority' or 'ProviderType'.
  lookupName: string;
  source: string;
  code: number
}

export class FDSReferenceData implements IFDSReferenceData {
  id: number;
  fdsuid: string;
  name: string;
  lookupName: string;
  source: string;
  code: number;
  laOrgUKPRN? : string;

  constructor(data?: IFDSReferenceData) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}
