import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IBannerUIState } from './banner.model';
import { BannerStateCommand } from './state/banner-state.command';
import { VISIBILITY_BANNER } from './state/banner-state.model';
import { BannerStateQuery } from './state/banner-state.query';
import { NavigationEnd, Router } from '@angular/router';
import { FundingDataApiService } from 'src/app/funding-data/services/api/funding-data-api.service';
import { IFundingDataSchema } from 'src/app/funding-data/models/funding-data-schema.model';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, OnDestroy {
  bannerState: IBannerUIState;
  schema: IFundingDataSchema;
  notifier = new Subject<void>();
  visibility = VISIBILITY_BANNER;
  fundingDataSchema: IFundingDataSchema = null;
  FUNDING_DATA_STORAGE_KEY = 'adminFundingDataSchema';

  VISIBILITY_BANNER = VISIBILITY_BANNER;

  constructor(public fundingDataService: FundingDataApiService, private router: Router, private bannerStateQuery: BannerStateQuery,
    private bannerStateCommand: BannerStateCommand) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.notifier),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.bannerStateCommand.resetState();
      });

    this.bannerStateQuery.banner$
      .pipe(takeUntil(this.notifier))
      .subscribe((x) => {
        this.bannerState = x
      });
  }

  showBannerCss(visibility: VISIBILITY_BANNER) {
    switch (visibility) {
      case VISIBILITY_BANNER.SHOW_NONE:
        return 'banner-none';
      case VISIBILITY_BANNER.SHOW_ERROR:
      case VISIBILITY_BANNER.SHOW_SUCCESS:
        return 'banner-show';
    }
  }

  ngOnDestroy(): void {
    this.bannerStateCommand.resetState()
    this.notifier.next();
    this.notifier.complete();
  }

  onSelectClicked(subtext: string) {
    this.fundingDataService.getFundingDataSchemaById(subtext).subscribe(response => {
      this.schema = response;
      sessionStorage.setItem(this.FUNDING_DATA_STORAGE_KEY, JSON.stringify(this.schema));
      this.router.navigate(['/admin/manage-schema/view-definition']);
    });
  }
}
