import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IMultiSelectOption } from '../../shared/components/multi-select-field/multi-select.model';
import { ITreeSelectOptions } from '../../shared/components/tree-select-dropdown/tree-select.model';
import { ConfigApiService, IConfigurationResponse, IFundingStream, IProviderType } from '../../services/api/config-api.service';
import { SearchCriterionModel } from '../../models/search-criterion-model';
import { debounceTime, skip, take, takeUntil } from 'rxjs/operators';
import { Filters, IFieldSearchTerms, IFilterService, IFilterTag, ProviderSearchTerms } from '../models/search-filter.model';
import { SearchOperator } from '../../models/search-operator';
import { IDateRange } from '../../shared/components/date-range-field/date-range-field.component';
import { MaintainDataStateCommand } from '../components/maintain-data/state/maintain-data-state-command';
import { BreadcrumbsService } from '../../layout/services/breadcrumbs.service';
import { ReferenceDataService } from '../../services/reference-data.service';
import { FundingStreamType } from 'src/app/models/funding-stream-type';

interface IProviderSearchState {
  selectedAuthorities?: string[];
  selectedStatuses?: string[];
  selectedProviderTypes?: ITreeSelectOptions[];
  selectedFundingStreams?: ITreeSelectOptions[];
  ukprn?: number;
  urn?: number;
  name?: string;
  paymentOrganisationUKPRN?: number;
  paymentOrganisationName?: string;
  fromDate?: string;
  endDate?: string;
  LastModifiedFromDate?: string;
  LastModifiedEndDate?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProviderSearchFilterService implements IFilterService, OnDestroy {

  readonly STORAGE_KEY = 'providerFilterOptions';

  // Selections state
  searchState: IProviderSearchState = {};

  _notifier$ = new Subject<void>();

  // Options for checkbox fields
  statusOptions$ = new BehaviorSubject<IMultiSelectOption[]>([]);
  authorityOptions$ = new BehaviorSubject<IMultiSelectOption[]>([]);
  typeOptions$ = new BehaviorSubject<ITreeSelectOptions[]>([]);
  fundingFlagOptions$ = new BehaviorSubject<ITreeSelectOptions[]>([]);

  _filterThrottle$ = new Subject<IProviderSearchState>();
  public searchCriteria$ = new BehaviorSubject<Array<Array<SearchCriterionModel>>>([]);
  public filterTags$ = new BehaviorSubject<IFilterTag[]>([]);

  // Subjects to reset filters
  clearFilters$ = new Subject<Filters | null>();

  constructor(public config: ConfigApiService,
    private maintainDataStateCommand: MaintainDataStateCommand,
    private breadcrumbsService: BreadcrumbsService,
    private referenceDataService: ReferenceDataService) {

    this.loadFilterConfiguration();

    // refresh funding flag options, when other
    referenceDataService.getClearFundingStreamOptions().subscribe(() => {
      this.updateFilterConfiguration(Filters.FundingFlag);
    })

    this._filterThrottle$.pipe(
      debounceTime(600),
      // Skip the first search that happens when checkbox options are initialised.
      skip(1),
      takeUntil(this._notifier$)
    ).subscribe(searchTerms => {
      this.updateSearchCriteria(searchTerms);
      this.updateFilterTags(searchTerms);
    });
  }

  loadFilterConfiguration(): void {
    // Try to retrieve data from SessionStorage first
    const cachedConfiguration: IConfigurationResponse = JSON.parse(sessionStorage.getItem(this.STORAGE_KEY));
    if (cachedConfiguration) {
      this.getAuthorityOptions(cachedConfiguration.authorities);
      this.getStatusOptions(cachedConfiguration.statuses);
      this.getTypeOptions(cachedConfiguration.providerTypeConfiguration);
      this.getFundingFlagOptions(cachedConfiguration.fundingStreams);
      return;
    }

    this.config.configuration(FundingStreamType.Pre16).subscribe(data => {
      sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
      this.getAuthorityOptions(data.authorities);
      this.getStatusOptions(data.statuses);
      this.getTypeOptions(data.providerTypeConfiguration);
      this.getFundingFlagOptions(data.fundingStreams);
    });
  }

  public updateFilterConfiguration(fieldName?: Filters): void {
    const cachedConfiguration: IConfigurationResponse = JSON.parse(sessionStorage.getItem(this.STORAGE_KEY));

    switch (fieldName) {
      case Filters.Status:
        this.config.providerStatusesConfiguration().pipe(take(1)).subscribe((statuses) => {
          cachedConfiguration.statuses = statuses;
          sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(cachedConfiguration));
          this.getStatusOptions(statuses);
        });
        break;

      case Filters.Authority:
        this.config.authoritiesConfiguration().pipe(take(1)).subscribe((authorities) => {
          cachedConfiguration.authorities = authorities;
          sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(cachedConfiguration));
          this.getAuthorityOptions(authorities);
        });
        break;

      case Filters.Type:
        this.config.providerTypesConfiguration().pipe(take(1)).subscribe((providerTypes) => {
          cachedConfiguration.providerTypeConfiguration = providerTypes;
          sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(cachedConfiguration));
          this.getTypeOptions(providerTypes);
        });
        break;

      case Filters.FundingFlag:
        this.config.fundingFlagsConfiguration(FundingStreamType.Pre16).pipe(take(1)).subscribe((fundingFlags) => {
          cachedConfiguration.fundingStreams = fundingFlags;
          sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(cachedConfiguration));
          this.getFundingFlagOptions(fundingFlags);
        });
        break;

      default:
        this.config.configuration(FundingStreamType.Pre16).subscribe((data) => {
          sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
          this.getAuthorityOptions(data.authorities);
          this.getStatusOptions(data.statuses);
          this.getTypeOptions(data.providerTypeConfiguration);
          this.getFundingFlagOptions(data.fundingStreams);
        });
    }
  }

  public clearFilters(filter?: Filters): void {
    this.clearFilters$.next(filter);
    this.searchState = {};
  }

  private getFundingFlagOptions(fundingStreams: IFundingStream[]): void {
    const fundingStreamOptions = fundingStreams.map(fundingStream => {
      return {
        name: fundingStream.name,
        value: fundingStream.id,
        children: fundingStream.fundingPeriodsInScope.map(fundingPeriod => {
          return { name: fundingPeriod.name, value: fundingPeriod.id };
        }),
      } as ITreeSelectOptions;
    });
    fundingStreamOptions.unshift({
      name: '-',
      value: 'fundingFlagOption',
      children: [{
        name: '-',
        value: null,
      }] as ITreeSelectOptions[],
    } as ITreeSelectOptions);
    this.fundingFlagOptions$.next(fundingStreamOptions);
  }

  private getTypeOptions(providerTypeConfiguration: IProviderType[]): void {
    const providerTypeOptions = providerTypeConfiguration.map(provider => {
      return {
        name: provider.providerType,
        value: provider.providerType,
        children: provider.providerSubTypes.map(providerSubType => {
          return { name: providerSubType, value: providerSubType };
        }),
      } as ITreeSelectOptions;
    });
    providerTypeOptions.unshift({
      name: '-',
      value: 'type',
    } as ITreeSelectOptions);
    this.typeOptions$.next(providerTypeOptions);
  }

  private getStatusOptions(statuses: string[]): void {
    const statusOptions = statuses.map(option => {
      return { name: option, value: option } as IMultiSelectOption;
    });
    statusOptions.unshift({ name: '-', value: ProviderSearchTerms.Null });
    this.statusOptions$.next(statusOptions);
  }

  private getAuthorityOptions(authorities: string[]): void {
    const authorityOptions = authorities.map(option => {
      return { name: option, value: option } as IMultiSelectOption;
    });
    authorityOptions.unshift({ name: '-', value: ProviderSearchTerms.Null });
    this.authorityOptions$.next(authorityOptions);
  }

  public filterChanged(searchTerms: IFieldSearchTerms): void {
    const original = JSON.stringify(this.searchState);

    switch (searchTerms.fieldName) {
      case Filters.Name:
        this.searchState.name = searchTerms.searchTerms as string;
        break;
      case Filters.Ukprn:
        this.searchState.ukprn = searchTerms.searchTerms as number;
        break;
      case Filters.Urn:
        this.searchState.urn = searchTerms.searchTerms as number;
        break;
      case Filters.Authority:
        this.searchState.selectedAuthorities = searchTerms.searchTerms as string[];
        break;
      case Filters.Status:
        this.searchState.selectedStatuses = searchTerms.searchTerms as string[];
        break;
      case Filters.FundingFlag:
        this.searchState.selectedFundingStreams = searchTerms.searchTerms as ITreeSelectOptions[];
        break;
      case Filters.Type:
        this.searchState.selectedProviderTypes = searchTerms.searchTerms as ITreeSelectOptions[];
        break;
      case Filters.DateOpened:
        this.searchState.fromDate = (searchTerms.searchTerms as IDateRange).fromDate;
        this.searchState.endDate = (searchTerms.searchTerms as IDateRange).endDate;
        break;
      case Filters.PaymentOrganisationName:
        this.searchState.paymentOrganisationName = searchTerms.searchTerms as string;
        break;
      case Filters.PaymentOrganisationUkprn:
        this.searchState.paymentOrganisationUKPRN = searchTerms.searchTerms as number;
        break;
      case Filters.LastModified:
        this.searchState.LastModifiedFromDate = (searchTerms.searchTerms as IDateRange).fromDate;
        this.searchState.LastModifiedEndDate = (searchTerms.searchTerms as IDateRange).endDate;
        break;
    }
    const isChanged = original != JSON.stringify(this.searchState);
    isChanged && this._filterThrottle$.next(this.searchState);
  }

  // Create SearchCriteria based on search and filter state
  private updateSearchCriteria(searchState: IProviderSearchState): void {
    // String/number input search terms
    const searchCriteria: Array<Array<SearchCriterionModel>> = [
      [new SearchCriterionModel(ProviderSearchTerms.Ukprn, SearchOperator.NumericContains, searchState.ukprn)],
      [new SearchCriterionModel(ProviderSearchTerms.Urn, SearchOperator.NumericContains, searchState.urn)],
      [new SearchCriterionModel(ProviderSearchTerms.Name, SearchOperator.Contains, searchState.name?.trim())],
      [new SearchCriterionModel(ProviderSearchTerms.PaymentOrganisationUkprn, SearchOperator.Contains, searchState.paymentOrganisationUKPRN?.toString())],
      [new SearchCriterionModel(ProviderSearchTerms.PaymentOrganisationName, SearchOperator.Contains, searchState.paymentOrganisationName?.trim())]
    ];

    // Multi-select dropdown search terms
    searchCriteria.push(this.getAuthorities(searchState.selectedAuthorities));
    searchCriteria.push(this.getStatuses(searchState.selectedStatuses));

    // Tree select dropdown search terms
    searchCriteria.push(this.getProviderTypes(searchState.selectedProviderTypes));
    searchCriteria.push(this.getFundingStreams(searchState.selectedFundingStreams));

    // Date search terms
    if (searchState.fromDate && !searchState.endDate) {
      searchCriteria.push([new SearchCriterionModel(ProviderSearchTerms.DateOpened, SearchOperator.GreaterThanOrEqual, this.formattedDate(searchState.fromDate))]);
    } else if (!searchState.fromDate && searchState.endDate) {
      searchCriteria.push([new SearchCriterionModel(ProviderSearchTerms.DateOpened, SearchOperator.LesserThanOrEqual, this.formattedDate(searchState.endDate))]);
    } else if (searchState.fromDate && searchState.endDate) {
      searchCriteria.push([new SearchCriterionModel(ProviderSearchTerms.DateOpened, SearchOperator.GreaterThanOrEqual, this.formattedDate(searchState.fromDate))]);
      searchCriteria.push([new SearchCriterionModel(ProviderSearchTerms.DateOpened, SearchOperator.LesserThanOrEqual, this.formattedDate(searchState.endDate))]);
    }

    // LastModifiedOn search terms
    if (searchState.LastModifiedFromDate && !searchState.LastModifiedEndDate) {
      searchCriteria.push([new SearchCriterionModel(ProviderSearchTerms.LastModified, SearchOperator.GreaterThanOrEqual, this.formattedDate(searchState.LastModifiedFromDate))]);
    } else if (!searchState.LastModifiedFromDate && searchState.LastModifiedEndDate) {
      searchCriteria.push([new SearchCriterionModel(ProviderSearchTerms.LastModified, SearchOperator.LesserThanOrEqual, this.formattedDate(searchState.LastModifiedEndDate))]);
    } else if (searchState.LastModifiedFromDate && searchState.LastModifiedEndDate) {
      searchCriteria.push([new SearchCriterionModel(ProviderSearchTerms.LastModified, SearchOperator.GreaterThanOrEqual, this.formattedDate(searchState.LastModifiedFromDate))]);
      searchCriteria.push([new SearchCriterionModel(ProviderSearchTerms.LastModified, SearchOperator.LesserThanOrEqual, this.formattedDate(searchState.LastModifiedEndDate))]);
    }

    this.searchCriteria$.next(this.validateSearchCriteria(searchCriteria));
  }

  // Remove null and invalid values, 'All' selections, etc.
  private validateSearchCriteria(searchModel: Array<Array<SearchCriterionModel>>): Array<Array<SearchCriterionModel>> {
    const validSearchCriteria = new Array<Array<SearchCriterionModel>>();
    searchModel.forEach(
      (criteriaSet) => {
        const validCriteriaSet = new Array<SearchCriterionModel>();
        criteriaSet?.forEach(
          (criterion) => {
            if (criterion.value && criterion.value !== ProviderSearchTerms.All && criterion.value !== ProviderSearchTerms.Null) {
              validCriteriaSet.push(criterion);
            } else {
              // Allow null values for provider type
              if (criterion.fieldName === ProviderSearchTerms.ProviderType && criterion.value !== ProviderSearchTerms.All) {
                criterion.operator = SearchOperator.Equals;
                validCriteriaSet.push(criterion);
              }

              // Allow null values for FS/FP
              if (criterion.fieldName === ProviderSearchTerms.FundingPeriod && criterion.value !== ProviderSearchTerms.All) {
                criterion.fieldName = ProviderSearchTerms.FundingFlags;
                criterion.operator = SearchOperator.CollectionCount;
                criterion.value = 0;
                validCriteriaSet.push(criterion);
              }

              // Allow null values for multi-select dropdowns
              if (
                ([ProviderSearchTerms.Authority, ProviderSearchTerms.ProviderStatusName] as String[]).includes(criterion.fieldName)
                && criterion.value === ProviderSearchTerms.Null
              ) {
                criterion.operator = SearchOperator.Equals;
                criterion.value = null;
                validCriteriaSet.push(criterion);
              }
            }
          },
        );
        if (validCriteriaSet.length > 0) {
          validSearchCriteria.push(validCriteriaSet);
        }
      }
    );
    return validSearchCriteria;
  }

  private updateFilterTags(searchState: IProviderSearchState): void {
    const filterTags: IFilterTag[] = [];

    searchState.ukprn?.toString().length > 0
      && filterTags.push({ fieldName: Filters.Ukprn, display: `UKPRN contains "${searchState.ukprn?.toString()}"` });

    searchState.urn?.toString().length > 0
      && filterTags.push({ fieldName: Filters.Urn, display: `URN contains "${searchState.urn?.toString()}"` });

    searchState.name?.toString().length > 0
      && filterTags.push({ fieldName: Filters.Name, display: `Name contains "${searchState.name?.toString()}"` });

    searchState.selectedStatuses?.length > 0 && searchState.selectedStatuses[0] !== 'All'
      && filterTags.push({ fieldName: Filters.Status, display: `Provider status (${searchState.selectedStatuses?.length})` });

    searchState.selectedAuthorities?.length > 0 && searchState.selectedAuthorities[0] !== 'All'
      && filterTags.push({ fieldName: Filters.Authority, display: `Authority (${searchState.selectedAuthorities?.length})` });

    searchState.selectedFundingStreams?.length > 0 && searchState.selectedFundingStreams[0].value !== 'All'
      && filterTags.push({
        fieldName: Filters.FundingFlag,
        display: `Funding stream & period (${searchState.selectedFundingStreams.map(node => node.children?.length).reduce((a, b) => a + b, 0)})`,
      });

    searchState.selectedProviderTypes?.length > 0 && searchState.selectedProviderTypes[0].value !== 'All'
      && filterTags.push({
        fieldName: Filters.Type,
        display: `Provider type & subtype (${searchState.selectedProviderTypes.map(node => node.children?.length > 0 ? node.children?.length : 1).reduce((a, b) => a + b, 0)})`,
      });

    (searchState.fromDate?.length > 0 || searchState.endDate?.length > 0)
      && filterTags.push({
        fieldName: Filters.DateOpened,
        display: `Open date
        ${searchState.fromDate && 'from ' + searchState.fromDate}
        ${searchState.endDate && 'until ' + searchState.endDate}`
      });

    (searchState.LastModifiedFromDate?.length > 0 || searchState.LastModifiedEndDate?.length > 0)
      && filterTags.push({
        fieldName: Filters.LastModified,
        display: `Modified on date
        ${searchState.LastModifiedFromDate && 'from ' + searchState.LastModifiedFromDate}
        ${searchState.LastModifiedEndDate && 'until ' + searchState.LastModifiedEndDate}`
      });

    searchState.paymentOrganisationUKPRN?.toString().length > 0
      && filterTags.push({
        fieldName: Filters.PaymentOrganisationUkprn,
        display: `Payment organisation UKPRN contains "${searchState.paymentOrganisationUKPRN?.toString()}"`,
      });

    searchState.paymentOrganisationName?.toString().length > 0
      && filterTags.push({
        fieldName: Filters.PaymentOrganisationName,
        display: `Payment organisation name contains "${searchState.paymentOrganisationName?.toString()}"`,
      });

    filterTags.length > 0
      && filterTags.unshift({ fieldName: null, display: 'Clear all' });

    this.filterTags$.next(filterTags);
  }

  getAuthorities(selectedAuthorities: string[]): Array<SearchCriterionModel> {
    if (selectedAuthorities) {
      return selectedAuthorities.map(authority => {
        return new SearchCriterionModel(ProviderSearchTerms.Authority, SearchOperator.Equals, authority);
      });
    }
  }

  getStatuses(selectedStatuses: string[]): Array<SearchCriterionModel> {
    if (selectedStatuses) {
      return selectedStatuses.map(status => {
        return new SearchCriterionModel(ProviderSearchTerms.ProviderStatusName, SearchOperator.Equals, status);
      });
    }
  }

  getProviderTypes(selectedProviderTypes: ITreeSelectOptions[]): Array<SearchCriterionModel> {
    if (selectedProviderTypes) {
      return selectedProviderTypes.map(providerType => {
        if (providerType.children) {
          return new SearchCriterionModel(
            ProviderSearchTerms.ProviderType,
            SearchOperator.Equals,
            providerType.value,
            providerType.children.map(providerSubType => {
              return new SearchCriterionModel(ProviderSearchTerms.ProviderSubType, SearchOperator.Equals, providerSubType.value);
            }));
        } else {
          return new SearchCriterionModel(ProviderSearchTerms.ProviderType, SearchOperator.Equals, providerType.value);
        }
      });
    }
  }

  getFundingStreams(selectedFundingStreams: ITreeSelectOptions[]): Array<SearchCriterionModel> {
    let fundingStreamCriteria: SearchCriterionModel[] = [];
    if (selectedFundingStreams) {
      selectedFundingStreams.forEach(fundingStream => {
        fundingStream.children?.forEach(fundingPeriod => {
          fundingPeriod.value
            ? fundingStreamCriteria.push(
              new SearchCriterionModel(ProviderSearchTerms.FundingPeriod, SearchOperator.CollectionEquals, fundingPeriod.value, null,
                new SearchCriterionModel(ProviderSearchTerms.FundingPeriodIsDelete, SearchOperator.Equals, false)))
            : fundingStreamCriteria.push(new SearchCriterionModel(ProviderSearchTerms.FundingPeriod, SearchOperator.CollectionEquals, fundingPeriod.value))
        });
      });
    }
    return fundingStreamCriteria;
  }

  formattedDate(input: string): string {
    return input.split("/").reverse().join("-");
  }

  ngOnDestroy(): void {
    this._notifier$.next();
    this._notifier$.complete();
  }
}