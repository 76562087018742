import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BackNavigationService } from '../../services/back-navigation.service';
import { SessionsStoreUserInformationService } from '../../services/sessions-store-user-information.service';
import { IUserInformation } from '../../models/user-information';
import { environment } from '../../../environments/environment';

@Component({
  templateUrl: './view-my-permissions-page.component.html',
  styleUrls: ['./view-my-permissions-page.component.scss']
})
export class ViewMyPermissionsPageComponent {

  user$ = new BehaviorSubject<IUserInformation>(null);
  isAdmin: boolean = false;
  canProcess: boolean = false;
  canPublish: boolean = false;
  genericAccess: boolean = false;
  fundingAgreementData: boolean = false;
  schemaManagement: boolean = false;
  fundingAgreementDataDeleteAccess: boolean = false;
  fundingDataProcess: boolean = false;
  fundingDataPublish: boolean = false;
  reconciliation: boolean = false;
  adultAllocation: boolean = false;
  indicator: boolean = false;
  allowedFundingStreamCodes: string[] = [];

  constructor(private backService: BackNavigationService, private userInformationService: SessionsStoreUserInformationService) {
    this.user$.next(userInformationService.getUserInformation());
  }

  onBackClicked(): void {
    this.backService.back();
  }

  ngOnInit(): void {
    this.user$.subscribe(user => {
      this.isAdmin = user.group === environment.sysAdminGroup;
      this.canProcess = user.permissions.canProcess;
      this.canPublish = user.permissions.canPublish;
      this.genericAccess = user.permissions.genericAccess;
      this.fundingAgreementData = user.permissions.fundingAgreementData;
      this.schemaManagement = user.permissions.schemaManagement;
      this.fundingAgreementDataDeleteAccess = user.permissions.fundingAgreementDataDeleteAccess;
      this.fundingDataProcess = user.permissions.fundingDataProcess;
      this.fundingDataPublish = user.permissions.fundingDataPublish;
      this.reconciliation = user.permissions.reconciliation;
      this.adultAllocation = user.permissions.adultAllocation;
      this.indicator = user.permissions.indicator;
      this.allowedFundingStreamCodes = this.myFundingStringCodes(user.permissions.allowedFundingStreamCodes);
    });
  }

  myFundingStringCodes(streamCodes: string[]): string[] {
    let codes: string[] = [];
    let lineString: string = "";
    // First, sort the array
    streamCodes.sort();
    // Then, group by the first character
    let grouped = streamCodes.reduce((r: any, e) => {
      let group = e[0]; // get the first character of the string
      if (!r[group]) r[group] = [e];
      else r[group].push(e);
      return r;
    }, {});

    // Now, loop through each group
    Object.keys(grouped).forEach(key => {
      grouped[key].forEach(item => {
        if(lineString == "")
          lineString = item
        else
        lineString = lineString + ", "+ item;
      });
      codes.push(lineString);
      lineString = "";
    });
    return codes;
  }
}
