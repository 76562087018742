import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { BannerStateCommand } from 'src/app/layout/components/banner/state/banner-state.command';
import { ISnapshotTransaction } from 'src/app/maintain-provider-attributes/models/provider-transaction-data.model';
import { IProvidersFound } from 'src/app/maintain-provider-attributes/models/provider-transaction-found-data.model';
import { ProviderTansactionResponse } from 'src/app/models/providerTransaction';
import { API_BASE_URL, CustomApiService } from './custom-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderTransactionApiService extends CustomApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(BannerStateCommand) bannerStateCommand: BannerStateCommand, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, bannerStateCommand, baseUrl);
  }

  public createSnapshotTransaction(snapshotTransaction: ISnapshotTransaction): Observable<ISnapshotTransaction> {
    return this.post<ISnapshotTransaction>('/api/ProviderTransaction/createTransactionData', snapshotTransaction);
  }

  public scopeProviders(snapshotTransaction: ISnapshotTransaction): Observable<ISnapshotTransaction> {
    return this.post<ISnapshotTransaction>('/api/ProviderTransaction/createTransactionData', snapshotTransaction);
  }

  public getSnapshotTransaction(): Observable<ISnapshotTransaction[]> {
    return this.post<ISnapshotTransaction[]>('/api/ProviderTransaction/transactions',null);
  }
  
  public deleteSnapshotTransaction(snapshotTransaction: ISnapshotTransaction): Observable<boolean> {
    return this.post<boolean>('/api/ProviderTransaction/remove',snapshotTransaction);
  }

  public providersByUkprns(ukprns: IProvidersFound): Observable<ProviderTansactionResponse[]> {
    return this.post<ProviderTansactionResponse[]>('/api/ProviderTransaction/by/ukprns', ukprns);
  }

  saveProviders(provider: ProviderTansactionResponse[]): Observable<boolean> {
    return this.post<boolean>('/api/ProviderTransaction/bulk/attribute/save', provider);
  }
  
  public updateProviderTransactionDfEEstablishment(snapshotTransaction: ISnapshotTransaction): Observable<boolean> {
    return this.post<boolean>('/api/ProviderTransaction/bulk/dfe/establishment', snapshotTransaction);
  }

  public syncProviderLink(snapshotTransaction: ISnapshotTransaction): Observable<boolean> {
    return this.post<boolean>('/api/ProviderTransaction/sync/provider/links', snapshotTransaction);
  }
}
