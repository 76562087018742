import { IFundingFlag } from "src/app/models/funding-flag";
import { ISnapshotTransaction } from "./provider-transaction-data.model";

export interface ISnapshotTransactionScope {
    providerTransactionData?: ISnapshotTransaction | undefined;
    fundingFlags?: IFundingFlag[] | undefined;
}

  export class SnapshotTransactionScope implements ISnapshotTransactionScope {
    providerTransactionData?: ISnapshotTransaction | undefined;
    fundingFlags?: IFundingFlag[] | undefined;
  
    constructor(data?: ISnapshotTransactionScope) {
      if (data) {
        for (const property in data) {
          if (data.hasOwnProperty(property))
            (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }
  