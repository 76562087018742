// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding: 5px;
}

.compact-title {
  font-size: 1rem;
  font-weight: 400;
}

.input-field {
  padding: 5px;
}

.column {
  padding: 0 5px 0 5px;
}

.error-message {
  padding: 0 5px 0 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/date-range-field/date-range-field.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,oBAAA;AACF","sourcesContent":[".header {\n  padding: 5px;\n}\n\n.compact-title {\n  font-size: 1rem;\n  font-weight: 400;\n}\n\n.input-field {\n  padding: 5px;\n}\n\n.column {\n  padding: 0 5px 0 5px;\n}\n\n.error-message {\n  padding: 0 5px 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
