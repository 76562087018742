// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-left-padding-zero {
  padding: 0;
}

.column-width-two-tenths {
  width: 20%;
}

.column-width-three-tenths {
  width: 30%;
}

.margin-left-one-rem {
  margin-left: 1rem;
}

.flex-row {
  display: flex;
  align-content: flex-end;
}

.cancel-link {
  margin-top: 0.6rem;
  padding-left: 1rem;
}

.record-editor-element {
  padding-bottom: 1rem;
}

.margin-left-0 {
  margin-left: 0;
}

.record-editor-left-padding-1 {
  padding-left: 1.16rem;
}

.w-100 {
  width: 100%;
}

.p-0 {
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/record-editor/date-editor/date-editor.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAGA;EACE,iBAAA;AAAF;;AAIA;EACE,aAAA;EACA,uBAAA;AADF;;AAIA;EACE,kBAAA;EACA,kBAAA;AADF;;AAIA;EACE,oBAAA;AADF;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,qBAAA;AADF","sourcesContent":[".row-left-padding-zero {\n  padding: 0;\n}\n\n.column-width-two-tenths {\n  width: 20%;\n}\n\n.column-width-three-tenths {\n  width: 30%;\n}\n\n\n.margin-left-one-rem {\n  margin-left: 1rem;\n}\n\n//module.scss\n.flex-row {\n  display: flex;\n  align-content: flex-end;\n}\n\n.cancel-link {\n  margin-top: .6rem;\n  padding-left: 1rem;\n}\n\n.record-editor-element {\n  padding-bottom: 1rem;\n}\n\n.margin-left-0 {\n  margin-left: 0;\n}\n\n.record-editor-left-padding-1 {\n  padding-left: 1.16rem;\n}\n\n.w-100 {\n  width: 100%;\n}\n\n.p-0 {\n  padding: 0 !important;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
