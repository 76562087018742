import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TrackByFunction } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IFundingPeriodScope, IFundingStreamScope } from '../../../models/funding-flag-scope';
import { FundingFlag } from '../../../models/funding-flag';
import { UserApiService } from 'src/app/services/api/user-api.service';

@Component({
  selector: 'funding-flag-select',
  templateUrl: './funding-flag-select.component.html',
  styleUrls: ['./funding-flag-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundingFlagSelectComponent implements OnInit, OnChanges {

  @Input() selections: FundingFlag[] = [];
  @Input() options$: Observable<IFundingStreamScope[]>;
  @Input() multiSelect: boolean = false;
  @Input() addText = 'Add a stream & period';
  @Input() checkErrors$: Observable<void>;
  @Input() storeSelections: boolean = true;
  /*** If required, the selectors will show if no option is selected, and the state will be incomplete ***/
  @Input() required: boolean = false;
  @Input() sourceOrTarget: string = '';
  @Input() adultPreSelectedStream: IFundingStreamScope = null;
  @Output() fundingFlagsSelected = new EventEmitter<FundingFlag[]>();
  @Output() incompleteSelection = new EventEmitter<boolean>();
  @Output() adultOptionSelected = new EventEmitter<boolean>();

  // local copy of input options.
  _options: IFundingStreamScope[];

  _fundingStreamOptions$ = new BehaviorSubject<IFundingStreamScope[]>([]);
  _fundingPeriodOptions$ = new BehaviorSubject<IFundingPeriodScope[]>([]);

  _selectedFundingStream: IFundingStreamScope = null;
  _selectedFundingPeriod: IFundingPeriodScope = null;

  _showAddButton: boolean;
  _showSelector: boolean;
  _showSelectorError: boolean = false;
  user$ = null;
  hasFsAccess: boolean = true;

  constructor(private cd: ChangeDetectorRef, private userApiService: UserApiService) {
    this.userApiService.getUserProfile().subscribe((user) => {
      this.user$ = user;

      if (this.adultPreSelectedStream) {
        this.onFundingStreamSelected(this.adultPreSelectedStream);
      }
    });
  }

  ngOnInit(): void {
    this._showAddButton = this.selections?.length === 0 || this.multiSelect;
    this._showSelector = this.required;
    this.incompleteSelection.emit(this.required && this.selections?.length === 0);

    this.options$?.subscribe((options) => {
      this._options = options;

      // Filter out funding periods whose funding periods have all been selected
      const selectedFundingPeriodIds = this.selections.map((selection) => selection.fundingPeriodId);
      this._fundingStreamOptions$.next(
        this.storeSelections
          ? this._options.filter(fs => fs.fundingPeriodsInScope.filter(fp => !selectedFundingPeriodIds.includes(fp.id))?.length > 0) ?? []
          : this._options
      );
    });

    this.checkErrors$?.subscribe(() => {
      this._showSelectorError = this._showSelector;
      this.cd.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.adultPreSelectedStream) {
      this.onFundingStreamSelected(this.adultPreSelectedStream);
    }
  }

  trackById: TrackByFunction<IFundingStreamScope | IFundingPeriodScope> = (index, item) => item.id;

  onFundingStreamSelected(selectedFundingStream: IFundingStreamScope): void {
    this._showSelectorError = false;
    this.hasFsAccess = this.user$.permissions.allowedFundingStreamCodes.includes(selectedFundingStream.code);
    if (this.hasFsAccess) {
      this._showSelectorError = false;
      this._selectedFundingStream = selectedFundingStream;
      this._fundingPeriodOptions$.next(
        selectedFundingStream.fundingPeriodsInScope?.filter(
          (fundingPeriod) => !this.selections?.some(selection => selection.fundingPeriodId === fundingPeriod.id)
        )
      );
    } else {
      this._showSelectorError = false;
      this._selectedFundingStream = selectedFundingStream;
    }
    this._selectedFundingPeriod = null;
  }

  onFundingPeriodSelected(selectedFundingPeriod: IFundingPeriodScope): void {
    this._showSelectorError = false;
    this._selectedFundingPeriod = selectedFundingPeriod;
  }

  onSelectClicked(): void {
    const newSelection = new FundingFlag({
      fundingPeriodId: this._selectedFundingPeriod.id,
      fundingStreamId: this._selectedFundingStream.id,
      fundingPeriodName: this._selectedFundingPeriod.name,
      fundingStreamName: this._selectedFundingStream.name,
      fundingPeriodCode: this._selectedFundingPeriod.code,
      fundingStreamCode: this._selectedFundingStream.code,
    });

    if (this.storeSelections) {
      this.selections.push(newSelection);
      this.fundingFlagsSelected.emit(this.selections);
      this._showAddButton = this.multiSelect;

      // Filter out funding periods whose funding periods have all been selected
      const selectedFundingPeriodIds = this.selections.map((selection) => selection.fundingPeriodId);
      if (this.adultPreSelectedStream)
        this.updateFundingStreamAndPeriodOptions(selectedFundingPeriodIds);
      else
        this._fundingStreamOptions$.next(
          this.storeSelections
            ? this._options.filter(fs => fs.fundingPeriodsInScope.filter(fp => !selectedFundingPeriodIds.includes(fp.id))?.length > 0) ?? []
            : this._options
        );
    } else {
      this.fundingFlagsSelected.emit([newSelection]);
      this._showAddButton = true;
    }

    this.incompleteSelection.emit(false);
    this._selectedFundingPeriod = null;
    this._showSelectorError = false;
    this._showSelector = false;

    if (this.adultPreSelectedStream) {
      this.adultOptionSelected.emit(true);
    }
  }

  onAddClicked(): void {
    this._showSelector = true;
    this.incompleteSelection.emit(true);
  }

  onRemoveClicked(entry: FundingFlag): void {
    const index = this.selections.findIndex((s) => s.fundingPeriodId === entry.fundingPeriodId)
    this.selections.splice(index, 1);

    // Filter out funding periods whose funding periods have all been selected
    const selectedFundingPeriodIds = this.selections.map((selection) => selection.fundingPeriodId);
    if (this.adultPreSelectedStream)
      this.updateFundingStreamAndPeriodOptions(selectedFundingPeriodIds);
    else
      this._fundingStreamOptions$.next(
        this.storeSelections
          ? this._options.filter(fs => fs.fundingPeriodsInScope.filter(fp => !selectedFundingPeriodIds.includes(fp.id))?.length > 0) ?? []
          : this._options
      );

    this._showAddButton = this.selections.length === 0 || this.multiSelect;
    this._showSelector = this.required && this.selections.length === 0;
    this.fundingFlagsSelected.emit(this.selections);

    if (this.adultPreSelectedStream) {
      this.adultOptionSelected.emit(false);
    }
  }

  onCancelClicked(): void {
    // Always want this to stay as ADF for Adult scoping
    if (!this.adultPreSelectedStream)
      this._selectedFundingStream = null;

    this._selectedFundingPeriod = null;
    this._showSelector = this.required && this.selections.length === 0;
    this._showSelectorError = false;
    this.incompleteSelection.emit(this._showSelector);
  }

  updateFundingStreamAndPeriodOptions(selectedFundingPeriodIds: number[]) {
    if (this.storeSelections) {
      this._options.forEach(fundingStream => {
        let fundingPeriods = [...fundingStream.fundingPeriodsInScope];
        fundingPeriods = fundingPeriods.filter(fp => !selectedFundingPeriodIds.includes(fp.id));
        if (this.adultPreSelectedStream && fundingStream.id === this.adultPreSelectedStream.id)
          this._fundingPeriodOptions$.next(fundingPeriods);
      });
    }
    this._fundingStreamOptions$.next(this._options);
  }
}
