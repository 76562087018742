export const Constants = {
    core: 'core',
    fundingFlags: 'Funding flags',
    fundingStreams: 'Funding streams',
    provider: 'provider',
}

export const ROUTES = {
    pre16ProviderScopeConfirm: '/manage-provider-data/manage-core-data/bulk/scope-confirmation',
    post16ProviderScopeConfirm: 'manage-funding-data/manage-adult-provider-data/manage-adult-provider-data-table/confirm',
};