import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BannerStateCommand } from '../../layout/components/banner/state/banner-state.command';
import { VISIBILITY_BANNER } from '../../layout/components/banner/state/banner-state.model';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@Injectable({
  providedIn: 'root'
})
export class CustomApiService {

  private http: HttpClient;
  private baseUrl: string;
  private bannerStateCommand: BannerStateCommand;

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(BannerStateCommand) bannerStateCommand: BannerStateCommand, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    this.bannerStateCommand = bannerStateCommand;
  }

  get<T>(uri: string, headers: any = null, queryParams: any = null, handleError: boolean = true): Observable<T> {
    const params = CustomApiService.setupParams(queryParams);
    const uri_ = (this.baseUrl + uri).replace(/[?&]$/, "");

    return this.http.get<T>(uri_, {headers, params}).pipe(
        catchError((error: HttpErrorResponse) => this.errorHandler(error, handleError))
    );
  }

  postWithBlobResponse(uri: string, body: any, headers: any = null, queryParams: any = null, handleError: boolean = true): Observable<any> {
    const params = CustomApiService.setupParams(queryParams);
    const uri_ = (this.baseUrl + uri).replace(/[?&]$/, "");

    return this.http.post(uri_, body, {responseType: 'blob', headers, params}).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandler(error, handleError))
    );
  }

  post<T>(uri: string, body: any, headers: any = null, queryParams: any = null, handleError: boolean = true): Observable<T> {
    const params = CustomApiService.setupParams(queryParams);
    const uri_ = (this.baseUrl + uri).replace(/[?&]$/, "");

    return this.http.post<T>(uri_, body, {headers, params}).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandler(error, handleError))
    );
  }

  put<T>(uri: string, body: any, headers: any = null, queryParams: any = null, handleError: boolean = true): Observable<T> {
    const params = CustomApiService.setupParams(queryParams);
    const uri_ = (this.baseUrl + uri).replace(/[?&]$/, "");

    return this.http.put<T>(uri_, body, {headers, params}).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandler(error, handleError))
    );
  }

  delete<T>(uri: string, headers: any = null, queryParams: any = null, handleError: boolean = true): Observable<T> {
    const params = CustomApiService.setupParams(queryParams);
    const uri_ = (this.baseUrl + uri).replace(/[?&]$/, "");

    return this.http.delete<T>(uri_, {headers, params}).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandler(error, handleError))
    );
  }

  private errorHandler(error: HttpErrorResponse, handleError: boolean): Observable<never> {
    if (handleError) {
      console.log('caught HTTP error:', error);
      this.bannerStateCommand.updateBanner({
        visibility: VISIBILITY_BANNER.SHOW_ERROR,
        notificationItem: {
          messages: [error.error?.title, error.message],
        },
      });
      return NEVER;
    } else {
      // Rethrow error if we want to handle it in place of subscription.
      return throwError(error);
    }
  }

  private static setupParams(queryParams: any): HttpParams {
    let params = new HttpParams();
    if (queryParams) {
      for (const key of Object.keys(queryParams)) {
        if (queryParams[key] !== undefined) {
          params = params.append(key, queryParams[key]);
        }
      }
    }
    return params;
  }
}
