// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: #f3f2f1;
  position: relative;
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.compact-header {
  padding: 5px !important;
  background-color: white !important;
}

.title {
  margin: 0;
  width: 16rem;
}

.compact-title {
  font-size: 1rem;
  font-weight: 400;
}

.subtext {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
}

.toggle-button {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  width: 2rem;
  height: 2rem;
  border: none;
  background: none;
  cursor: pointer;
}

.body {
  background-color: white;
  border: 2px solid #f3f2f1;
  padding: 0.5rem;
}

.compact-body {
  background-color: white;
  padding: 0 5px 0 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/search-field/search-field.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;AACF;;AAEA;EACE,uBAAA;EACA,kCAAA;AACF;;AAEA;EACE,SAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,uBAAA;EACA,yBAAA;EACA,eAAA;AACF;;AAEA;EACE,uBAAA;EACA,oBAAA;AACF","sourcesContent":[".header {\n  background-color: #f3f2f1;\n  position: relative;\n  padding: 0.5rem;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.compact-header {\n  padding: 5px !important;\n  background-color: white !important;\n}\n\n.title {\n  margin: 0;\n  width: 16rem;\n}\n\n.compact-title {\n  font-size: 1rem;\n  font-weight: 400;\n}\n\n.subtext {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  margin-right: 1rem;\n}\n\n.toggle-button {\n  position: absolute;\n  top: 0.4rem;\n  right: 0.4rem;\n  width: 2rem;\n  height: 2rem;\n  border: none;\n  background: none;\n  cursor: pointer;\n}\n\n.body {\n  background-color: white;\n  border: 2px solid #f3f2f1;\n  padding: 0.5rem;\n}\n\n.compact-body {\n  background-color: white;\n  padding: 0 5px 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
