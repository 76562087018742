// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-paginator .mat-paginator-container {
  display: inline-flex;
  margin-left: auto;
  justify-content: space-between;
}
::ng-deep .mat-paginator .mat-paginator-range-actions {
  margin-left: -2rem;
  flex-basis: 85%;
  justify-content: flex-start;
}
::ng-deep .mat-paginator .mat-paginator-page-size {
  order: 2;
  margin-top: 0.7rem;
  margin-right: 0%;
}
::ng-deep .mat-paginator .mat-paginator-range-label {
  order: 3;
  margin-left: auto;
  flex-basis: 15%;
}
::ng-deep .mat-paginator .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
::ng-deep .mat-paginator .mat-fab:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
::ng-deep .mat-paginator .mat-form-field-infix {
  border: 1px solid rgba(0, 0, 0, 0.87) !important;
}
::ng-deep .mat-paginator .mat-select-value {
  padding-left: 5%;
}

::ng-deep .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/paging/paging.component.scss"],"names":[],"mappings":"AACI;EACI,oBAAA;EACA,iBAAA;EACA,8BAAA;AAAR;AAGI;EACI,kBAAA;EACA,eAAA;EACA,2BAAA;AADR;AAII;EACI,QAAA;EACA,kBAAA;EACA,gBAAA;AAFR;AAKI;EACI,QAAA;EACA,iBAAA;EACA,eAAA;AAHR;AAMI;EACI,gBAAA;AAJR;AAOI;EACI,gBAAA;AALR;AAQI;EACI,gDAAA;AANR;AASI;EACI,gBAAA;AAPR;;AAWA;EACI,0BAAA;AARJ","sourcesContent":["::ng-deep .mat-paginator {\n    .mat-paginator-container {\n        display: inline-flex;\n        margin-left: auto;\n        justify-content: space-between;\n    }\n\n    .mat-paginator-range-actions {\n        margin-left: -2rem;\n        flex-basis: 85%;\n        justify-content: flex-start;\n    }\n\n    .mat-paginator-page-size {\n        order: 2;\n        margin-top: 0.7rem;\n        margin-right: 0%;\n    }\n\n    .mat-paginator-range-label {\n        order: 3;\n        margin-left: auto;\n        flex-basis: 15%;\n    }\n\n    .mat-mini-fab:not([class*=\"mat-elevation-z\"]) {\n        box-shadow: none;\n    }\n\n    .mat-fab:not([class*=\"mat-elevation-z\"]) {\n        box-shadow: none;\n    }\n\n    .mat-form-field-infix {\n        border: 1px solid rgba(0, 0, 0, 0.87) !important;\n    }\n\n    .mat-select-value {\n        padding-left: 5%;\n    }\n}\n\n::ng-deep .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {\n    color: rgba(0, 0, 0, 0.87);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
