import { FundingFlag } from 'src/app/models/funding-flag';
import * as RecordEditorModel from 'src/app/record-editor/record-editor.model';

export interface IProviderTab extends Record<string, any> {
  id?: number;
  name?: string;
  urn?: number;
  ukprn?: number;
  upin?: string;
  establishmentNumber?: number;
  dfeEstablishmentNumber?: number;
  authorityFDSUID?: string;
  providerTypeFDSUID?: string;
  providerSubtypeFDSUID?: string;
  storeProviderTypeFDSUID?: string;
  storeProviderSubtypeFDSUID?: string;
  dateOpened?: Date;
  dateClosed?: Date;
  phaseOfEducationFDSUID?: string;
  reasonEstablishmentOpenedFDSUID?: string;
  reasonEstablishmentClosedFDSUID?: string;
  trustStatusFDSUID?: string;
  providerStatusFDSUID?: string;
  paymentOrganisationId?: number;
  furtherEducationTypeFDSUID?: string;
  statutoryLowAge?: number;
  statutoryHighAge?: number;
  previousLaCode?: number;
  previousLaName?: string;
  previousEstablishmentNumber?: number;
  isProviderPaymentOrganisation?: boolean;
  fundingFlags?: FundingFlag[];
  lastModified?: Date;
  parliamentaryConstituencyName?: string;
  parliamentaryConstituencyCode?: string;
  transactionId?: number;
  providerId?: number;
  isFundingAgreementDataChecked: boolean;
}

export interface ITabRowDefinition {
  displayName: string;
  name: string | RecordEditorModel.Property;
  childName?: string;
  childDisplayName?: string;
  type: RecordEditorModel.EditComponentType;
  changeData: boolean;
  validationRules: any;
  referenceColumnName?: ReferenceColumnName;
  childReferenceColumnName?: ReferenceColumnName;
  childProperties: RecordEditorModel.Property[];
  nullable?: boolean;
}

export enum ProviderColumnName {
  name = 'name',
  urn = 'urn',
  ukprn = 'ukprn',
  establishmentNumber = 'establishmentNumber',
  dfeEstablishmentNumber = 'dfeEstablishmentNumber',
  dateOpened = 'dateOpened',
  dateClosed = 'dateClosed',
  trustCode = 'trustCode',
  groupIdNumber = 'groupIdNumber',
  paymentOrganisation = 'paymentOrganisation',
  paymentOrganisationId = 'paymentOrganisationId',
  paymentOrganisationType = 'paymentOrganisationType',
  statutoryLowAge = 'statutoryLowAge',
  statutoryHighAge = 'statutoryHighAge',
  previousLaCode = 'previousLaCode',
  previousLaName = 'previousLaName',
  previousEstablishmentNumber = 'previousEstablishmentNumber',
  parliamentaryConstituencyName = 'parliamentaryConstituencyName',
  parliamentaryConstituencyCode = 'parliamentaryConstituencyCode',
  isProviderPaymentOrganisation = 'isProviderPaymentOrganisation',

  authorityFDSUID = 'authorityFDSUID',
  providerTypeFDSUID = 'providerTypeFDSUID',
  providerSubtypeFDSUID = 'providerSubtypeFDSUID',
  storeProviderTypeFDSUID = 'storeProviderTypeFDSUID',
  storeProviderSubtypeFDSUID = 'storeProviderSubtypeFDSUID',
  phaseOfEducationFDSUID = 'phaseOfEducationFDSUID',
  reasonEstablishmentOpenedFDSUID = 'reasonEstablishmentOpenedFDSUID',
  reasonEstablishmentClosedFDSUID = 'reasonEstablishmentClosedFDSUID',
  trustStatusFDSUID = 'trustStatusFDSUID',
  providerStatusFDSUID = 'providerStatusFDSUID',
  furtherEducationTypeFDSUID = 'furtherEducationTypeFDSUID',
}

export enum NewProviderColumnName {
  name = 'name',
  ukprn = 'ukprn',
  providerTypeFDSUID = 'providerTypeFDSUID',
  providerSubtypeFDSUID = 'providerSubtypeFDSUID',
  authorityFDSUID = 'authorityFDSUID',
  providerStatusFDSUID = 'providerStatusFDSUID',

  storeProviderTypeFDSUID = 'storeProviderTypeFDSUID',
  storeProviderSubtypeFDSUID = 'storeProviderSubtypeFDSUID',
  furtherEducationTypeFDSUID = 'furtherEducationTypeFDSUID',
  dateOpened = 'dateOpened',
}

export enum CustomColumnName {
  paymentOrganisationName = 'Payment organisation name',
  paymentOrganisationUkprn = 'Payment organisation UKPRN',
  paymentOrganisationType = 'Payment organisation type',
  LAcode = 'LA Code',
  Record = 'Record',
  LAOrgUKPRN = 'LA Org. UKPRN'
}

export enum ReferenceColumnName {
  authority = 'Authority',
  providerType = 'ProviderType',
  providerSubType = 'ProviderSubtype',
  storeProviderType = 'StoreProviderType',
  storeProviderSubtype = 'StoreProviderSubtype',
  phaseOfEducation = 'PhaseOfEducation',
  reasonEstablishmentOpened = 'ReasonEstablishmentOpened',
  reasonEstablishmentClosed = 'ReasonEstablishmentClosed',
  trustStatus = 'TrustStatus',
  providerStatus = 'ProviderStatus',
  furtherEducationType = 'FurtherEducationTypeName',
}

export enum ReferenceColumnCode {
  code = 'code',
}

export enum RecordType {
  Provider = 'Provider',
  PaymentOrganisation = 'PaymentOrganisation'
}

export enum BulkActionType {
  ScopeProviders = 'ScopeProviders',
  DeScopeProviders = 'DeScopeProviders',
  CopyProviderScopes = 'CopyProviderScopes',
  UpdateProviderAttribute = 'UpdateProviderAttribute'
}
