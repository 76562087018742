import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomePage } from "./navigation-pages/home/home.page";
import { MsalRedirectComponent } from "@azure/msal-angular";
import { ManageDataPage } from "./navigation-pages/manage-data/manage-data.page";
import { AccessibilityStatementComponent } from "./navigation-pages/accessibility-statement/accessibility-statement.page";
import { CookiesComponent } from "./navigation-pages/cookies/cookies-statement";
import { CookiesBannerComponent } from "./navigation-pages/cookies-banner/cookies-banner";
import { AdminGuard } from "./guards/admin-guard";
import { ProcessGuard } from "./guards/process.guard";
import { Helpers } from "src/core/helpers";
import { ViewMyPermissionsPageComponent } from "./components/view-my-permissions-page/view-my-permissions-page.component";
import { PermissionErrorPageComponent } from "./components/permission-error-page/permission-error-page.component";
import { ManageProviderComponent } from "./navigation-pages/manage-provider/manage-provider.component";
import { ManageFundingDataComponent } from "./navigation-pages/manage-funding-data/manage-funding-data.component";
import { HelpPageComponent } from "./navigation-pages/help.page/help.page.component";
const routes: Routes = [
  {
    path: "",
    component: HomePage,
    pathMatch: "full",
    canActivate: Helpers.getGuards(),
    title: "Funding Data Service - Home",
  },
  {
    path: ".auth/login/aad/callback",
    component: MsalRedirectComponent,
  },
  {
    path: "accessibility-statement",
    data: { breadcrumb: "Accessibility" },
    component: AccessibilityStatementComponent,
    canActivate: Helpers.getGuards(),
    title: "Funding Data Service - Accessibility",
  },
  {
    path: "help",
    data: { breadcrumb: "Get help with Funding Data Service" },
    component: HelpPageComponent,
    canActivate: Helpers.getGuards(),
    title: "Funding Data Service - Help",
  },
  {
    path: "cookies-statement",
    data: { breadcrumb: "Cookies statement" },
    component: CookiesComponent,
    canActivate: Helpers.getGuards(),
    title: "Funding Data Service - Cookies statement",
  },
  {
    path: "cookies-banner",
    component: CookiesBannerComponent,
    canActivate: Helpers.getGuards(),
    title: "Funding Data Service - Cookies banner",
  },
  {
    path: "manage-funding-data",
    data: { breadcrumb: "Manage funding data" },
    children: [
      {
        path: "",
        data: { breadcrumb: null },
        component: ManageFundingDataComponent,
        title: "Funding Data Service - Manage funding data",
      },
      {
        path: "funding-data",
        data: { breadcrumb: "Onboard funding data" },
        loadChildren: () =>
          import("./funding-data/funding-data.module").then(
            (m) => m.FundingDataModule
          ),
        title: "Funding Data Service - Onboard funding data",
      },
      {
        path: "funding-agreement-data",
        data: { breadcrumb: "Maintain funding agreement data" },
        loadChildren: () =>
          import("./funding-agreement-data/funding-agreement-data.module").then(
            (m) => m.FundingAgreementDataModule
          ),
        title: "Funding Data Service - Maintain funding agreement data",
      },
      {
        path: "manage-adult-provider-data",
        data: { breadcrumb: null },
        loadChildren: () =>
          import("./adult-provider-data/adult-provider-data.module").then(
            (m) => m.AdultProviderDataModule
          ),
      },
    ],
  },
  {
    path: "admin",
    data: { showBreadcrumbs: true, breadcrumb: "Admin" },
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: Helpers.getGuards([AdminGuard]),
    title: "Funding Data Service - Admin",
  },
  {
    path: "my-permissions",
    data: { showBreadcrumbs: false },
    component: ViewMyPermissionsPageComponent,
    canActivate: Helpers.getGuards(),
    title: "Funding Data Service - My permissions",
  },
  {
    path: "permission-error",
    data: { showBreadcrumbs: false },
    component: PermissionErrorPageComponent,
    canActivate: Helpers.getGuards(),
    title: "Funding Data Service - Permission error",
  },
  {
    path: "manage-provider-data",
    data: { breadcrumb: "Manage provider data" },
    children: [
      {
        path: "",
        data: { breadcrumb: null },
        component: ManageProviderComponent,
        title: "Funding Data Service - Manage provider data",
      },
      {
        path: "manage-core-data",
        data: { breadcrumb: "Manage core provider data table" },
        children: [
          {
            path: "",
            data: { breadcrumb: null },
            component: ManageDataPage,
            title: "Funding Data Service - Manage core provider data table",
          },
          {
            path: "maintain-data",
            data: { breadcrumb: null },
            loadChildren: () =>
              import("./maintain-data/maintain-data.module").then(
                (m) => m.MaintainDataModule
              ),
          },
          {
            path: "bulk",
            data: { showBreadcrumbs: false, breadcrumb: null },
            loadChildren: () =>
              import(
                "./bulk-update-provider-data/bulk-update-provider-data.module"
              ).then((m) => m.BulkUpdateProviderDataModule),
          },
          {
            path: "new-providers",
            data: { showBreadcrumbs: false, breadcrumb: null },
            loadChildren: () =>
              import(
                "./update-provider-data/import-new-providers/import-new-providers.module"
              ).then((m) => m.ImportNewProvidersModule),
          },
          {
            path: "update-records",
            data: { showBreadcrumbs: false, breadcrumb: null },
            loadChildren: () =>
              import(
                "./update-provider-data/update-existing-records/update-existing-records.module"
              ).then((m) => m.UpdateExistingRecordsModule),
          },
          {
            path: "create-a-new-record",
            data: { breadcrumb: null },
            loadChildren: () =>
              import("./create-new-record/create-new-record.module").then(
                (m) => m.CreateNewRecordModule
              ),
          },
        ],
        canActivate: Helpers.getGuards([ProcessGuard]),
        canLoad: Helpers.getGuards([ProcessGuard]),
        canActivateChild: Helpers.getGuards([ProcessGuard]),
      },
      {
        path: "share-data",
        data: { breadcrumb: "Share provider data snapshots" },
        children: [
          {
            path: "",
            data: { showBreadcrumbs: true, breadcrumb: null },
            loadChildren: () =>
              import("./share-data/share-data.module").then(
                (m) => m.ShareDataModule
              ),
            canActivate: Helpers.getGuards(),
          },
          {
            path: "update-and-share",
            data: { breadcrumb: null },
            loadChildren: () =>
              import(
                "./maintain-provider-attributes/maintain-provider-attributes.module"
              ).then((m) => m.MaintainProviderAttributesModule),
            canActivate: Helpers.getGuards([ProcessGuard]),
            canMatch: Helpers.getGuards([ProcessGuard]),
            canActivateChild: Helpers.getGuards([ProcessGuard]),
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
