// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.spinner-container__content {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.fds-loader {
  border: 12px solid #DEE0E2;
  border-radius: 50%;
  border-top-color: #005EA5;
  width: 80px;
  height: 80px;
  animation: lds-spinner 2s linear infinite;
}
@keyframes lds-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/loading-spinner/loading-spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,WAAA;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,aAAA;AAEF;;AACA;EACE,0BAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EAEA,yCAAA;AAEF;AAKA;EACE;IAAK,uBAAA;EAOL;EANA;IAAO,yBAAA;EASP;AACF","sourcesContent":[".spinner-container {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n.spinner-container__content {\n  display: flex;\n  justify-content: center;\n  padding: 20px;\n}\n\n.fds-loader {\n  border: 12px solid #DEE0E2;\n  border-radius: 50%;\n  border-top-color: #005EA5;\n  width: 80px;\n  height: 80px;\n  -webkit-animation: lds-spinner 2s linear infinite;\n  animation: lds-spinner 2s linear infinite;\n}\n\n@-webkit-keyframes lds-spinner {\n  0% { -webkit-transform: rotate(0deg); }\n  100% { -webkit-transform: rotate(360deg); }\n}\n@keyframes lds-spinner {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
