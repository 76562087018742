import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BannerStateCommand } from '../../layout/components/banner/state/banner-state.command';
import { VISIBILITY_BANNER } from '../../layout/components/banner/state/banner-state.model';
import { Helpers } from 'src/core/helpers';
import { commonErrorMessageTemplate, IPropertyEditCancelled, IPropertyEdited } from '../record-editor.model';
import { ValidatorService } from 'src/app/validators/validator-service';
import { ALLOWED_CHARACTERS } from '../../validators/allowedCharacters';

@Component({
  selector: 'app-free-text',
  templateUrl: './free-text.component.html',
  styleUrls: ['./free-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeTextComponent implements OnInit {
  @Input() showCommentTextBox: boolean;
  @Input() isMultiText: boolean = false;
  @Input() propertyValue: string;
  @Input() propertyName: string;
  @Input() propertyDisplayName: string;
  @Input() hintMessage: string;
  @Input() validationRules?: any;
  @Input() id: any;
  @Input() isNumber: boolean = false;
  @Input() isValidUser: boolean;
  @Output() propertyValueChanged = new EventEmitter<IPropertyEdited>();
  @Output() commentAdded = new EventEmitter<IPropertyEdited>();
  @Output() cancelled = new EventEmitter<IPropertyEditCancelled>();

  freeTextForm: UntypedFormGroup;
  commentHasError: boolean;
  freeTextHasError: boolean;
  commentErrorMessage: string;
  freeTextErrorMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private bannerStateCommand: BannerStateCommand,
    private customValidatorService: ValidatorService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.propertyName) {
      let requiredValidation = [];

      if (this.validationRules?.pattern) {
        requiredValidation.push(Validators.pattern(this.validationRules?.pattern));
      }

      if (this.validationRules?.maxLength) {
        requiredValidation.push(Validators.maxLength(this.validationRules?.maxLength));
      }

      if (this.validationRules?.length) {
        requiredValidation.push(Validators.maxLength(this.validationRules?.length));
        requiredValidation.push(Validators.minLength(this.validationRules?.length));
      }

      if (this.validationRules?.requiredConditionally && this.propertyValue) {
        requiredValidation.push(Validators.required);
      }

      if (this.validationRules?.max) {
        requiredValidation.push(Validators.max(this.validationRules?.max));
      }

      if (this.validationRules?.min) {
        requiredValidation.push(Validators.min(this.validationRules?.max));
      }

      if (this.propertyName == 'name') {
        requiredValidation.push(Validators.required);
      }
      this.setConditionalRequiredValidator(requiredValidation);
    }
  }

  setConditionalRequiredValidator(requiredValidation: any[]) {
    if (this.showCommentTextBox) {
      this.freeTextForm = this.fb.group({
        assignedValue: [this.propertyValue || '', requiredValidation],
        comments: ['', [Validators.required, Validators.maxLength(200), Validators.pattern(ALLOWED_CHARACTERS)]],
      });
    }
    else {
      this.freeTextForm = this.fb.group({
        assignedValue: [this.propertyValue || '', requiredValidation],
        comments: ['', []],
      });
    }
  }

  onSubmit() {
    this.resetError();

    if (this.freeTextForm.invalid) {
      this.bannerStateCommand.updateBanner({
        visibility: VISIBILITY_BANNER.SHOW_ERROR,
        notificationItem: {
          messages: this.buildNotificationErrors(this.freeTextForm),
        },
      });
      return;
    }

    const property = this.freeTextForm.get('assignedValue');

    if (!this.validationRules?.isDbUnique || !property.value || property.value?.toString()?.trim() === this.propertyValue?.toString()?.trim()) {
      return this.emitResponse();
    }
    switch (this.propertyName) {
      case 'ukprn': {
        if (property.value?.toString()?.trim() !== this.propertyValue?.toString()?.trim()) {
          const response = this.customValidatorService.validateDuplicateUKPRN(property);
          response.subscribe(innerResponse => {
            if (innerResponse?.ukprnExists) {
              this.freeTextHasError = true;
              this.freeTextErrorMessage = innerResponse?.ukprnExists;
              this.cdr.markForCheck();
              this.updateBanner(innerResponse?.ukprnExists)
            } else {
              this.emitResponse();
            }
            return;
          });
        }
        break;
      }
      case 'urn': {
        const responseURN = this.customValidatorService.validateDuplicateURN(property);
        responseURN.subscribe(innerResponse => {
          if (innerResponse) {
            this.freeTextHasError = true;
            this.freeTextErrorMessage = 'URN is already in use, enter a unique URN';
            this.cdr.markForCheck();
            this.updateBanner(this.freeTextErrorMessage)
          } else {
            this.emitResponse();
          }
          return;
        });
        break;
      }
    }
  }


  emitResponse(): void {
    const property = {
      comment: this.freeTextForm.get('comments').value,
      propertyName: Helpers.capitalizeFirstChar(this.propertyName),
      propertyValue: this.freeTextForm.get('assignedValue').value?.toString()?.trim(),
      id: this.id,
    } as IPropertyEdited;

    property.propertyValue === this.propertyValue?.toString()?.trim() || (!property.propertyValue && !this.propertyValue)
      ? this.commentAdded.emit(property)
      : this.propertyValueChanged.emit(property);
  }

  updateBanner(message: string): void {
    this.bannerStateCommand.updateBanner({
      visibility: VISIBILITY_BANNER.SHOW_ERROR,
      notificationItem: {
        messages: [message]
      },
    });
  }

  onCancelled(): void {
    this.cancelled.emit({
      propertyName: this.propertyName,
      cancelled: true,
      id: this.id
    } as IPropertyEditCancelled);
  }

  buildNotificationErrors(form: UntypedFormGroup): string[] {
    const commentControl = form.get('comments');
    const nameControl = form.get('name');
    const assignedValueControl = form.get('assignedValue');
    let message = [];

    if (assignedValueControl.invalid) {
      this.freeTextHasError = true;

      if (assignedValueControl.errors?.required) {
        if (this.propertyName == 'ukprn') {
          this.freeTextErrorMessage = commonErrorMessageTemplate.ukprn;
          message.push(this.freeTextErrorMessage);
        }
        if (this.propertyName == 'name') {
          this.freeTextErrorMessage = commonErrorMessageTemplate.name;
          message.push(this.freeTextErrorMessage);
        }
      }

      if (this.validationRules?.length &&
        (assignedValueControl.errors?.minlength || assignedValueControl.errors?.maxlength)) {
        this.freeTextErrorMessage = this.lengthError(
          this.propertyName,
          this.validationRules?.length
        );
        message.push(this.freeTextErrorMessage);
      } else if (assignedValueControl.errors?.maxlength) {
        this.freeTextErrorMessage = this.maxLengthError(
          this.propertyName,
          this.validationRules?.maxLength
        );
        message.push(this.freeTextErrorMessage);
      }

      if (this.isNumber && assignedValueControl.errors?.max) {
        this.freeTextErrorMessage = this.maxError(this.propertyName, this.validationRules?.max);
        message.push(this.freeTextErrorMessage);
      }

      if (this.isNumber && assignedValueControl.errors?.min) {
        this.freeTextErrorMessage = this.minError(this.propertyName, this.validationRules?.min);
        message.push(this.freeTextErrorMessage);
      }

      if (assignedValueControl.errors?.pattern) {
        if (this.isNumber) {
          this.freeTextErrorMessage = this.numberOnlyError(
            this.propertyName,
            this.validationRules?.length
          );
        } else {
          this.freeTextErrorMessage = this.allowedCharactersError(
            this.propertyName,
          );
        }
        message.push(this.freeTextErrorMessage);
      }
    }

    if (commentControl.invalid) {
      this.commentHasError = true;

      if (commentControl.errors?.maxlength) {
        this.commentErrorMessage = this.maxLengthError('comment', 200);
        message.push(this.commentErrorMessage);
      }

      if (commentControl.errors?.pattern) {
        this.commentErrorMessage = this.allowedCharactersError('Comment');
        message.push(this.commentErrorMessage);
      }

      if (commentControl.errors?.required) {
        if (this.propertyDisplayName === "UKPRN") {
          this.commentErrorMessage = commonErrorMessageTemplate.comments;
        }
        else {
          this.commentErrorMessage = commonErrorMessageTemplate.commentErrorMessage1 + this.propertyDisplayName + '. ';
          this.commentErrorMessage = this.commentErrorMessage + commonErrorMessageTemplate.commentErrorMessage2;
        }
        message.push(this.commentErrorMessage);
      }
    }

    return message;
  }

  errorGroupCss(hasError: boolean) {
    return !hasError
      ? 'record-editor-left-padding-1'
      : 'govuk-form-group--error';
  }

  errorElementCss(hasError: boolean) {
    return !hasError ? '' : 'govuk-input--error';
  }

  resetError() {
    this.commentHasError = false;
    this.freeTextHasError = false;
    this.freeTextErrorMessage = '';
    this.commentErrorMessage = '';
  }

  maxLengthError(propertyName: string, maxLength: number) {
    return `Enter a ${Helpers.getTransformedColumnHeader(
      propertyName
    ).toLowerCase()} of ${maxLength} characters or less`;
  }

  lengthError(propertyName: string, minLength: number) {
    return `${Helpers.getTransformedColumnHeader(
      propertyName
    ).toUpperCase()} must be ${minLength} characters in length`;
  }

  numberOnlyError(propertyName: string, maxLength: number) {
    return `${Helpers.getTransformedColumnHeader(
      propertyName
    ).toUpperCase()} should contain numeric characters, with no leading zeros`;
  }

  allowedCharactersError(propertyName: string) {
    return `${Helpers.getTransformedColumnHeader(
      propertyName
    ).toUpperCase()} must only contain allowed characters: A-z, 0-9 and !&()+-.,:;?@/'à`;
  }

  maxError(propertyName: string, max: number) {
    return `${Helpers.getTransformedColumnHeader(
      propertyName
    ).toUpperCase()} must be ${max} or lower.`;
  }

  minError(propertyName: string, min: number) {
    return `${Helpers.getTransformedColumnHeader(
      propertyName
    ).toUpperCase()} must be ${min} or higher.`;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
