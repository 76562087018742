export interface IProviderattribute {
  providerAttributeName?: string;
  providerAttributeCode?: string;
  control?: ControlType;
  isVisible?: boolean;
  validationRules? : any;
}

export class Providerattribute implements IProviderattribute {
  providerAttributeName?: string;
  providerAttributeCode?: string;
  control?: ControlType;
  isVisible?: boolean;
  validationRules? : any;

  constructor(data?: IProviderattribute) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export enum ControlType {
  Dropdown = 'Dropdown',
  Date = "Date",
  Textbox = "Textbox",
}