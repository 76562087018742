import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { IAddDatasetAttributeResponse, IAgreementDataset, IAgreementDatasetAttributes, IAgreementDatasetFields, IAgreementDataViewModel, IAgreementSelectedDataViewModel, IFundingAgreementDatasetFieldDefaults, IFundingAgreementDetailData, IFundingAgreementResponse } from 'src/app/funding-agreement-data/models/funding-agreement-data.model';
import { BannerStateCommand } from 'src/app/layout/components/banner/state/banner-state.command';
import { API_BASE_URL, CustomApiService } from './custom-api.service';

@Injectable({
  providedIn: 'root'
})
export class FundingAgreementDataApiService extends CustomApiService {

  private httpClient: HttpClient;
  private httpBaseUrl: string;

  constructor(@Inject(HttpClient) http: HttpClient,
    @Inject(BannerStateCommand) bannerStateCommand: BannerStateCommand,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, bannerStateCommand, baseUrl);
    this.httpClient = http;
    this.httpBaseUrl = baseUrl;
  }

  public getFundingAgreementDataset(): Observable<IAgreementDataset[]> {
    return this.get<IAgreementDataset[]>('/api/FundingAgreementData/GetFundingAgreementDataset');
  }

  public getAgreementDatasetFields(): Observable<IAgreementDatasetFields[]> {
    return this.get<IAgreementDatasetFields[]>('/api/FundingAgreementData/GetAllAgreementDatasetFields');
  }

  public getAgreementDatasetFieldsByDataset(datasetId: number): Observable<IAgreementDatasetFields[]> {
    return this.get<IAgreementDatasetFields[]>('/api/FundingAgreementData/GetAllAgreementDatasetFieldsByDataset', null, { datasetId: datasetId });
  }

  public getAgreementDatasetFieldDefaults(): Observable<IFundingAgreementDatasetFieldDefaults[]> {
    return this.get<IFundingAgreementDatasetFieldDefaults[]>('/api/FundingAgreementData/GetFundingAgreementDatasetFieldDefaults');
  }

  public saveAgreementDetails(fundingDetails: IAgreementDataViewModel[]): Observable<boolean> {
    return this.post<boolean>('/api/FundingAgreementData/bulkSaveFundingDetails', fundingDetails, null, null, false);
  }

  public validateProviderURNs(providerUrns: number[]): Observable<boolean> {
    return this.post<boolean>('/api/Provider/validateProviderURNs', providerUrns);
  }

  public getAgreementDetails(selectedDatasets: number[]): Observable<any[]> {
    return this.post<any[]>('/api/FundingAgreementData/GetFundingAgreementDetailedData', selectedDatasets);
  }

  public getFundingAgreementDetailData(providerId: number): Observable<IFundingAgreementDetailData[]> {
    return this.get<IFundingAgreementDetailData[]>('/api/FundingAgreementData/GetFundingAgreementDataByProviderId', null, { providerId: providerId });
  }

  public saveFundingAgreementData(data: IFundingAgreementDetailData): Observable<boolean> {
    return this.post<boolean>('/api/FundingAgreementData/saveFundingAgreementData', data, null, null, false);
  }

  public saveFundingAgreementDetailedData(fundingDetails: IFundingAgreementDetailData[]): Observable<boolean> {
    return this.post<boolean>('/api/FundingAgreementData/bulkSaveFundingDetailedData', fundingDetails);
  }

  public AddFundingAgreementDataset(dataset: IAgreementDataset): Observable<IFundingAgreementResponse> {
    return this.post<IFundingAgreementResponse>('/api/FundingAgreementData/addFundingAgreementDataset', dataset);
  }

  public AddFundingAgreementDatasetFields(datasetField: IAgreementDatasetAttributes): Observable<IAddDatasetAttributeResponse> {
    return this.post<IAddDatasetAttributeResponse>('/api/FundingAgreementData/addFundingAgreementDatasetFields', datasetField);
  }

  public descopeProviderFromFundingAgreement(ukprn: number): Observable<boolean> {
    return this.post<boolean>('/api/FundingAgreementData/descopeProviderFromFundingAgreement', ukprn);
  }

  public deleteFundingAgreementDataset(datasetIds: number[]): Observable<boolean> {
    return this.post<boolean>('/api/FundingAgreementData/deleteFundingAgreementDatasets', datasetIds);
  }

  public getScopedFundingAgreementUKPRNs(UKPRNs: number[]): Observable<number[]> {
    return this.post<number[]>('/api/FundingAgreementData/GetScopedFundingAgreementDetailedData', UKPRNs);
  }

  public deleteFundingAgreementDatasetFields(datasetField: IAgreementDatasetAttributes): Observable<boolean> {
    return this.post<boolean>('/api/FundingAgreementData/deleteFundingAgreementDatasetField', datasetField);
  }

  downloadFundingAgreementDataTemplate(datasetIds: number[]): Observable<Blob> {
    return this.httpClient.post(`${this.httpBaseUrl}/api/FundingAgreementData/DownloadFundingAgreementDataTemplate`,datasetIds, { responseType: 'blob' });
  }
}
