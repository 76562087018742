import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE_URL, CustomApiService } from './custom-api.service';
import { BannerStateCommand } from '../../layout/components/banner/state/banner-state.command';

export interface IConfigurationResponse {
  authorities: string[];
  statuses: string[];
  providerTypeConfiguration: IProviderType[];
  fundingStreams: IFundingStream[];
}

export interface IProviderType {
  providerType: string;
  providerSubTypes: string[];
}

export interface IFundingStream {
  id: number;
  name: string;
  code: string;
  fundingPeriodsInScope: IFundingPeriod[];
}

export interface IFundingPeriod {
  id: number;
  name: string;
  code: string;
}

@Injectable()
export class ConfigApiService extends CustomApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(BannerStateCommand) bannerStateCommand: BannerStateCommand, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, bannerStateCommand, baseUrl);
  }

  configuration(type: string): Observable<IConfigurationResponse> {
    return this.post<IConfigurationResponse>('/api/Config/provider/configuration', null, null, { fundingStreamType: type });
  }

  providerTypesConfiguration(): Observable<IProviderType[]> {
    return this.post<IProviderType[]>('/api/Config/provider/configuration/provider-types', null);
  }

  providerStatusesConfiguration(): Observable<string[]> {
    return this.post<string[]>('/api/Config/provider/configuration/provider-statuses', null);
  }

  authoritiesConfiguration(): Observable<string[]> {
    return this.post<string[]>('/api/Config/provider/configuration/authorities', null);
  }

  fundingFlagsConfiguration(type: string): Observable<IFundingStream[]> {
    return this.post<IFundingStream[]>('/api/Config/provider/configuration/funding-streams', null, null, { fundingStreamType: type });
  }
}
