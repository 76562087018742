import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Filters, IFieldSearchTerms } from '../../../maintain-data/models/search-filter.model';

@Component({
  selector: 'search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFieldComponent implements OnInit {

  isOpen = true;
  searchText = '';

  @Input() title: string;
  @Input() hintMessage: string;
  @Input() clearFilter$: Observable<Filters | null>;
  @Input() fieldName?: Filters;
  @Input() isNumber = false;
  @Input() compactView = false;
  @Output() searchTextChanged = new EventEmitter<string | number | IFieldSearchTerms>();

  ngOnInit(): void {
    this.clearFilter$?.subscribe(filter => {
      filter
        ? filter === this.fieldName && this.clearFilters()
        : this.clearFilters();
    });
  }

  clearFilters(): void {
    this.searchText = '';
    this.searchTextChanged.emit(
      this.fieldName
        ? { fieldName: this.fieldName, searchTerms: this.searchText } as IFieldSearchTerms
        : this.searchText
    );
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  onModelChange(): void {
    this.searchTextChanged.emit(
      this.fieldName
        ? { fieldName: this.fieldName, searchTerms: this.searchText } as IFieldSearchTerms
        : this.searchText
    );
  }
}
