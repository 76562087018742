import { Injectable } from '@angular/core';
import { IUserInformation } from '../models/user-information';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SessionsStoreUserInformationService {
  useInformationKey = 'useInformation';

  setUserInformation(userInformation: IUserInformation) {
    sessionStorage.removeItem(this.useInformationKey);
    sessionStorage.setItem(this.useInformationKey, JSON.stringify(userInformation));
  }

  getUserInformation(): IUserInformation {
    try {
      return JSON.parse(sessionStorage.getItem(this.useInformationKey)) as IUserInformation;
    } catch {
      return undefined;
    }
  }

  canProcess(): boolean {
    return this.getUserInformation()?.permissions?.canProcess ?? false;
  }

  canPublish(): boolean {
    return this.getUserInformation()?.permissions?.canPublish ?? false;
  }

  isSysAdmin(): boolean {
    const group = this.getUserInformation()?.group;
    return (group === environment.sysAdminGroup);
  }

  isFundingAgreementDataAccess(): boolean {
    return this.getUserInformation()?.permissions?.fundingAgreementData ?? false;
  }

  isFundingAgreementEditDataAccess(): boolean {
    return this.getUserInformation()?.permissions?.fundingAgreementDataDeleteAccess ?? false;
  }

  isFundingDataProcessAccess(): boolean {
    return this.getUserInformation()?.permissions?.fundingDataProcess ?? false;
  }

  isFundingDataPublishAccess(): boolean {
    return this.getUserInformation()?.permissions?.fundingDataPublish ?? false;
  }

  getUserId(): number {
    return this.getUserInformation()?.userId ?? null;
  }

  hasReconciliationAccess(): boolean {
    return this.getUserInformation()?.permissions?.reconciliation ?? false;
  }

  hasAdultAllocationAccess(): boolean {
    return this.getUserInformation()?.permissions?.adultAllocation ?? false;
  }
}
