export interface ITab {
  name: string;
  isSelected: boolean;
}

export enum VISIBILITY_TABS {
  SHOW_SEMI = 'SHOW_SEMI',
  SHOW_NONE = 'SHOW_NONE',
  SHOW_ALL = 'SHOW_ALL',
  SHOW_ALL_CONSTRAINED = 'SHOW_ALL_CONSTRAINED',
}

export enum SORTINGCOLUMN_MAPPING {
  PAYMENTORGANISATIONNAME = 'paymentOrganisationName',
  AUTHORITYCODE = 'authorityCode',
  PAYMENTORGANISATIONUKPRN = 'paymentOrganisationUKPRN',
  PAYMENTORGANISATIONTYPE = 'paymentOrganisationType',
  PROVIDERTYPE = 'providerType',
  PROVIDERSUBTYPE = 'providerSubtype',
  PROVIDERSTATUS = 'providerStatus',
  AUTHORITY = 'authority',
  TRUSTSTATUS ='trustStatus',
  STOREPROVIDERTYPE = 'storeProviderType',
  STOREPROVIDERSUBTYPE = 'storeProviderSubtype',    
}