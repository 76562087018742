import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IUserInformation } from './models/user-information';
import { UserApiService } from './services/api/user-api.service';
import { SessionsStoreUserInformationService } from './services/sessions-store-user-information.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Funding Data Service';
  isIframe = false;
  loggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loggedIn$: Observable<boolean> = this.loggedInSubject.asObservable();
  private readonly _destroying$ = new Subject<void>();
  msalGuardConfig: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userApiServiceService: UserApiService,
    private sessionsStoreUserInformationService: SessionsStoreUserInformationService,
  ) {
    this.msalGuardConfig = msalGuardConfig;
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    if (environment?.useLocalHttpInterceptor) {
      console.info('useLocalHttpInterceptor - development mode');
      this.retrieveUserInformation();
    } else {
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) =>
              msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          ),
          switchMap((value: any) => {
            return this.userApiServiceService.addMe().pipe(takeUntil(this._destroying$));
          }),
          takeUntil(this._destroying$)
        )
        .subscribe((userInformation: IUserInformation) => {
          if (userInformation) {
            this.sessionsStoreUserInformationService.setUserInformation(userInformation);
            this.loggedInSubject.next(true);
          }
        });
    }
    this.checkLoggedIn();
  }

  retrieveUserInformation(): void {
    this.userApiServiceService
      .addMe()
      .pipe(takeUntil(this._destroying$))
      .subscribe((userInformation) => {
        if (userInformation) {
          this.sessionsStoreUserInformationService.setUserInformation(userInformation);
          this.loggedInSubject.next(true);
        }
      });
  }

  logout() {
    this.authService.logout();
  }

  checkLoggedIn() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.loggedInSubject.next(true);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
