import { Component } from '@angular/core';

@Component({
  selector: 'app-help.page',
  standalone: true,
  imports: [],
  templateUrl: './help.page.component.html',
  styleUrl: './help.page.component.scss'
})
export class HelpPageComponent {

}
