import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PhaseBannerComponent } from './components/phase-banner/phase-banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BannerComponent } from './components/banner/banner.component';
import { AppRoutingModule } from '../app-routing.module';
import { HeaderComponent } from './components/header/header.component';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    PhaseBannerComponent,
    BreadcrumbComponent,
    BannerComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    PhaseBannerComponent,
    BreadcrumbComponent,
    BannerComponent,
    HeaderComponent,
  ],
})
export class LayoutModule { }
