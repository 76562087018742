import { Component, OnInit } from '@angular/core';
import { SessionsStoreUserInformationService } from '../../services/sessions-store-user-information.service';

@Component({
  templateUrl: './home.page.html',
})
export class HomePage implements OnInit {
  showAdminLink: boolean = false;
  showFundingDataLink: boolean = false;
  constructor(private sessionsStoreUserInformationService: SessionsStoreUserInformationService) { }

  ngOnInit(): void {
    this.showAdminLink = this.sessionsStoreUserInformationService.isSysAdmin();
    this.showFundingDataLink = this.sessionsStoreUserInformationService.isFundingDataProcessAccess() ||
      this.sessionsStoreUserInformationService.isFundingDataPublishAccess() ||
      this.sessionsStoreUserInformationService.isFundingAgreementDataAccess();
  }
}
