import { Component } from '@angular/core';

@Component({
  selector: 'app-phase-banner',
  templateUrl: './phase-banner.component.html',
  styles: [`
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  `]
})
export class PhaseBannerComponent {
}
