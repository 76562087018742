import { Component } from "@angular/core";
import { ClarityService } from "src/app/services/clarity/clarity.service";
import { Router } from "@angular/router";

@Component({
  selector: "cookies-banner",
  templateUrl: "./cookies-banner.page.html",
})
export class CookiesBannerComponent {
  showCookieBanner = false;
  showHideBanner = false;

  constructor(private clarityService: ClarityService, private router: Router) {}

  ngOnInit() {
    this.checkCookieConsent();
  }

  checkCookieConsent() {
    const consent = this.getCookie("cookies_preferences_set");

    if (consent === "true") {
      this.clarityService.initializeClarity();
      this.showCookieBanner = false;
    } else {
      this.showCookieBanner = true;
    }
  }

  giveCookieConsent() {
    this.setCookie("cookies_preferences_set", "true", 365);

    this.clarityService.initializeClarity();

    this.showCookieBanner = false;
    this.showHideBanner = true;
  }

  rejectCookieConsent() {
    this.setCookie("cookies_preferences_set", "true", 365);

    this.showCookieBanner = false;
    this.showHideBanner = true;
  }

  hideCookieBanner() {
    this.showHideBanner = false;
  }

  setCookie(name: string, value: string, days: number) {
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  getCookie(name: string) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
