export enum SearchOperator {
  Contains = 'contains',
  NumericContains = 'numericContains',
  Equals = 'equals',
  NotEqual = 'NotEqual',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  LesserThanOrEqual = 'lesserThanOrEqual',
  CollectionEquals = 'collectionEquals',
  CollectionCount = 'collectionCount',
}
