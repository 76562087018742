import { Inject, Injectable, Optional, Provider } from '@angular/core';
import { API_BASE_URL, CustomApiService } from './custom-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IProviderTab } from '../../maintain-data/components/providers/provider-tab/provider-tab.model';
import { QueryModel } from '../../models/query-model';
import { BulkCopyScopeSummary, DeleteRecordRequest, DeleteRecordResponse, ProviderPagingResponse, ProviderResponse } from '../../models/provider';
import { ProviderLink } from '../../models/provider-link';
import { IFileParameter } from '../../models/i-file-parameter';
import { BannerStateCommand } from '../../layout/components/banner/state/banner-state.command';
import { IDuplicateResponse } from './reconciliation-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderApiService extends CustomApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(BannerStateCommand) bannerStateCommand: BannerStateCommand, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, bannerStateCommand, baseUrl);
  }

  public providerTransactionRecordById(providerId: number, transactionId: number): Observable<ProviderResponse> {
    return this.get<ProviderResponse>('/api/Provider/getProviderByProviderIdAndTransactionId', null, { providerId: providerId, transactionId: transactionId });
  }

  public providerRecordById(providerId: number): Observable<ProviderResponse> {
    return this.get<ProviderResponse>(`/api/Provider/${providerId.toString()}`);
  }

  public providerRecordByUKPRN(ukprn: number): Observable<ProviderResponse> {
    return this.get<ProviderResponse>(`/api/Provider/GetProviderByUKPRN`, null, { ukprn });
  }
  public GetScopedFundingStreamsByProvider(providerId: number): Observable<string[]> {
    return this.get<string[]>(`/api/Provider/GetScopedFundingStreamsByProvider`, null, { providerId: providerId });
  }

  public providerQuery(query: QueryModel): Observable<ProviderPagingResponse> {
    return this.post<ProviderPagingResponse>('/api/Provider/query', query);
  }

  public getbulkCopyScopeSummary(query: any): Observable<BulkCopyScopeSummary> {
    return this.post<BulkCopyScopeSummary>('/api/Provider/getbulkCopyScopeSummary', query);
  }

  public providerTransactionDataQuery(query: QueryModel): Observable<ProviderPagingResponse> {
    return this.post<ProviderPagingResponse>('/api/Provider/getProviderTransactionData', query);
  }

  public providerDownloadExcel(query: QueryModel): Observable<any> {
    return this.postWithBlobResponse('/api/Provider/download', query);
  }

  public providerTransactionDownloadExcel(query: QueryModel): Observable<any> {
    return this.postWithBlobResponse('/api/ProviderTransaction/download', query);
  }

  public providerLinks(providerId: number): Observable<ProviderLink[]> {
    return this.get<ProviderLink[]>('/api/Provider/Links', null, { providerId: providerId });
  }

  public saveProvider(provider: IProviderTab): Observable<ProviderResponse> {
    return this.post<ProviderResponse>('/api/Provider', provider);
  }

  public saveProviderTransaction(provider: IProviderTab): Observable<ProviderResponse> {
    return this.post<ProviderResponse>('/api/Provider/addOrUpdateProviderTransactionData', provider);
  }

  public addNewProvider(provider: IProviderTab): Observable<ProviderResponse> {
    return this.post<ProviderResponse>('/api/Provider/create-new-provider', provider);
  }

  public uploadCsv(file: IFileParameter): Observable<void> {
    return this.post('/api/Provider/upload/csv', file);
  }

  public paymentOrganisationrQuery(query: QueryModel): Observable<ProviderPagingResponse> {
    return this.post<ProviderPagingResponse>('/api/Provider/query', query);
  }

  public checkDuplicateUkprns(ukprns: number[]): Observable<IDuplicateResponse[]> {
    return this.post<IDuplicateResponse[]>('/api/Provider/check-duplicate-ukprns', ukprns);
  }

  public checkDuplicateUrn(urn: number): Observable<boolean> {
    return this.post<boolean>('/api/Provider/check-duplicate-urn', urn);
  }
  public providersByUkprns(ukprns: number[]): Observable<ProviderResponse[]> {
    return this.post<ProviderResponse[]>('/api/Provider/by/ukprns', ukprns);
  }

  public providerOrPaymentOrganisationsByUkprn(requestViewModel: DeleteRecordRequest): Observable<DeleteRecordResponse> {
    return this.post<DeleteRecordResponse>('/api/Provider/record-to-delete/by/ukprn', requestViewModel);
  }

  public deleteRecord(requestViewModel: DeleteRecordRequest, recordType: string): Observable<DeleteRecordResponse> {
    return this.post<DeleteRecordResponse>('/api/' + recordType + '/delete-' + recordType, requestViewModel);
  }

  public getAllProviderUKPRN(): Observable<string[]> {
    return this.get<string[]>(`/api/Provider/AllUKPRN`);
  }

  public getProviderDataSkipPages(query: QueryModel): Observable<string[]> {
    return this.post<string[]>('/api/Provider/UKPRN', query);
  }

  saveProviders(provider: ProviderResponse[]): Observable<boolean> {
    return this.post<boolean>('/api/Provider/bulk/save', provider);
  }
}
