import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Breadcrumb, BreadcrumbsService } from '../../services/breadcrumbs.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  showBreadcrumbs$: Observable<boolean>;
  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private breadcrumbsService: BreadcrumbsService) {
  }

  ngOnInit(): void {
    this.breadcrumbs$ = this.breadcrumbsService.getBreadcrumbs();
    this.showBreadcrumbs$ = this.breadcrumbsService.getShowBreadcrumbs();
  }
}
