import { ITreeSelectOptions } from '../../shared/components/tree-select-dropdown/tree-select.model';
import { IDateRange } from '../../shared/components/date-range-field/date-range-field.component';
import { Observable } from 'rxjs';

export enum ProviderSearchTerms {
  Null = '-',
  All = 'All',
  Urn = 'urn',
  Ukprn = 'ukprn',
  Name = 'name',
  PaymentOrganisationUkprn = 'paymentorganisation.ukprn',
  PaymentOrganisationName = 'paymentorganisation.name',
  PaymentOrganisationType = 'paymentOrganisation.PaymentOrganisationType',
  ProviderType = 'providerType.name',
  ProviderSubType = 'providerSubType.name',
  StoreProviderType = 'storeProviderType.name',
  StoreProviderSubType = 'storeProviderSubType.name',
  FundingPeriod = 'fundingFlags.fundingPeriodId',
  FundingPeriodIsDelete = 'isDeleted',
  FundingFlags = 'fundingFlags',
  DateOpened = 'dateOpened',
  Authority = 'authority.name',
  ProviderStatusName = 'providerStatus.providerStatusName',
  AuthorityCode = 'authority.code',
  TrustStatus = 'trustStatus.name',
  LastModified = 'lastModified'
}

export enum PaymentOrganisationSearchTerms {
  All = 'All',
  Ukprn = 'ukprn',
  Name = 'name',
}

export interface IFilterTag {
  fieldName: Filters,
  display: string,
}

export interface IFieldSearchTerms {
  fieldName: Filters,
  searchTerms: string | number | string[] | ITreeSelectOptions[] | IDateRange,
}


export enum Filters {
  Name = 'Name',
  Ukprn = 'Ukprn',
  Urn = 'Urn',
  PaymentOrganisationName = 'PaymentOrganisationName',
  PaymentOrganisationUkprn = 'PaymentOrganisationUkprn',
  Status = 'Status',
  Authority = 'Authority',
  FundingFlag = 'FundingFlag',
  Type = 'Type',
  DateOpened = 'DateOpened',
  LastModified = 'LastModified',
  ModifiedOn = 'ModifiedOn'
}

export interface IFilterService {
  filterTags$: Observable<IFilterTag[]>;
  clearFilters: (fieldName: Filters) => void;
}
