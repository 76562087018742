import { Inject, Injectable, Optional } from '@angular/core';
import { API_BASE_URL, CustomApiService } from './custom-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FDSReferenceData } from '../../models/fds-reference-data';
import { BannerStateCommand } from '../../layout/components/banner/state/banner-state.command';

export interface IProviderTypeLink {
  id: number;
  providerType: string;
  providerTypeFDSUID: string;
  providerSubtype: string;
  providerSubtypeFDSUID: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataApiService extends CustomApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(BannerStateCommand) bannerStateCommand: BannerStateCommand, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, bannerStateCommand, baseUrl);
  }

  public providerTypeLinks(): Observable<IProviderTypeLink[]> {
    return this.get<IProviderTypeLink[]>('/api/ReferenceData/providerTypeLinks');
  }

  public providerReferenceData(): Observable<FDSReferenceData[]> {
    return this.get('/api/ReferenceData/all');
  }
}
