import { Inject, Injectable, Optional } from '@angular/core';
import { API_BASE_URL, CustomApiService } from './custom-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FundingStream, IFundingStream } from '../../models/funding-stream';
import { BannerStateCommand } from '../../layout/components/banner/state/banner-state.command';
import { ICFSFundingStreamChange } from '../../models/cfs-funding-flags';

@Injectable({
  providedIn: 'root'
})
export class FundingStreamApiService extends CustomApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(BannerStateCommand) bannerStateCommand: BannerStateCommand, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, bannerStateCommand, baseUrl);
  }

  fundingStreams(type: string): Observable<IFundingStream[]> {
    return this.get<IFundingStream[]>('/api/FundingStream', null, { fundingStreamType: type });
  }

  getAllFundingStreams(): Observable<IFundingStream[]> {
    return this.get<IFundingStream[]>('/api/FundingStream/GetAllFundingStreams');
  }

  getCFSData(): Observable<ICFSFundingStreamChange[]> {
    return this.get('/api/FundingStream/get/cfs/data');
  }

  saveCFSData(data: ICFSFundingStreamChange[]): Observable<void> {
    return this.post('/api/FundingStream/save/cfs/data', data, null, null, false);
  }
}
