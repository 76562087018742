import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  static readonly HEADER_WIDE_VIEW = 'wideView'
  headerWidthSubject = new Subject<boolean>();

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setHeaderWidth(this.calculateHeaderWidth(this.route));
      });
  }

  setHeaderWidth(wideView: boolean = false): void {
    this.headerWidthSubject.next(wideView);
  }

  getHeaderWidth(): Observable<boolean> {
    return this.headerWidthSubject.asObservable();
  }

  private calculateHeaderWidth(route: ActivatedRoute) {
    const routeChild: ActivatedRoute = route.children[0];
    if (!routeChild) {
      return false;
    }
    const data = routeChild.snapshot.data[HeaderService.HEADER_WIDE_VIEW];
    if (data && data === true) {
      return true;
    } else {
      return this.calculateHeaderWidth(routeChild);
    }
  }
}
