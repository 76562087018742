import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProviderResponse } from '../../../models/provider';
import { Helpers } from '../../../../core/helpers';
import { ProviderApiService } from '../../../services/api/provider-api.service';
import { BehaviorSubject } from 'rxjs';
import { ISnapshotTransaction } from 'src/app/maintain-provider-attributes/models/provider-transaction-data.model';
import { ProviderTransactionApiService } from 'src/app/services/api/provider-transaction-api.service';
import { ProviderTansactionResponse } from 'src/app/models/providerTransaction';
import { FundingAgreementDataApiService } from 'src/app/services/api/funding-agreement-data-api.service';
export interface IProviderUkprnSearchResult {
  providersFound: ProviderResponse[],
  ukprnsNotFound: number[],
  invalidUkprns: string[],
  ukprnsWithNullURNs: number[],
  alreadyScopedUKPRNs: number[]
}

@Component({
  selector: 'funding-provider-ukprn-search',
  templateUrl: './funding-provider-ukprn-search.component.html',
  styleUrls: ['./funding-provider-ukprn-search.component.scss'],
  providers: [ProviderApiService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundingProviderUkprnSearchComponent implements OnInit {

  providerSearchForm: UntypedFormGroup;
  validUkprns: number[] = [];
  invalidUkprns: string[] = [];
  ukprnsNotFound: number[] = [];
  ukprnsNULLURNs: number[] = [];
  alreadyScopedUKPRNs: number[] = [];
  providersFound: ProviderResponse[] = [];
  providersFoundWithoutScoped: ProviderResponse[] = [];
  transactionProvidersFound: ProviderTansactionResponse[] = [];
  UPDATE_DATA_TYPE = 'providerDataUpdateType';
  updateType: ISnapshotTransaction;
  transaction: ISnapshotTransaction
  TRANSACTION_DATA_STORAGE_KEY = 'transactionData';
  showSearchResults = false;
  loadingResults = new BehaviorSubject(false);
  isFromBack: boolean;
  UKPRNs: string;
  STORAGE_KEY = "UKPRNS";

  @Output() providersSearched = new EventEmitter<IProviderUkprnSearchResult>();

  constructor(private fb: UntypedFormBuilder,
    private providerApiService: ProviderApiService,
    public providerTransactionApiService: ProviderTransactionApiService,
    private fundingAgreementApi: FundingAgreementDataApiService) {
    this.preserveDataAfterBackLink();
  }

  ngOnInit(): void {
    this.updateType = JSON.parse(sessionStorage.getItem(this.UPDATE_DATA_TYPE));
    this.transaction = JSON.parse(sessionStorage.getItem(this.TRANSACTION_DATA_STORAGE_KEY));
    this.providerSearchForm = this.fb.group({
      ukprns: ['', [Validators.required]],
    });
  }
  preserveDataAfterBackLink() {
    this.isFromBack = JSON.parse(sessionStorage.getItem("BACKCLICK"));
    if (this.isFromBack) {
      this.UKPRNs = JSON.parse(sessionStorage.getItem(this.STORAGE_KEY));
      sessionStorage.setItem("BACKCLICK", JSON.stringify(false));
      sessionStorage.removeItem(this.STORAGE_KEY);
    }
  }
  onProviderSearchSubmit(): void {
    sessionStorage.setItem("UKPRNS", JSON.stringify(this.providerSearchForm.get('ukprns').value));
    this.getValidUkprns(this.providerSearchForm.get('ukprns').value);
    this.showSearchResults = true;
    this.loadingResults.next(true);
    const foundUkprns = [];
    this.providerApiService.providersByUkprns(this.validUkprns).subscribe((providersFound) => {
      this.providersFound = providersFound;
      const foundUkprns = providersFound.map((p) => p.ukprn) ?? [];
      const foundUkprnsNullURN = providersFound.filter(function (record) {
        return record.urn == null;
      });
      this.ukprnsNULLURNs = foundUkprnsNullURN.map(e => e.ukprn);
      this.ukprnsNotFound = this.validUkprns.filter((ukprn) => !foundUkprns.includes(ukprn));

      this.fundingAgreementApi.getScopedFundingAgreementUKPRNs(foundUkprns).subscribe(
        (responseScopedUKPRNS) => {
          this.alreadyScopedUKPRNs = responseScopedUKPRNS.filter((ukprn) => ukprn != null);
          this.providersFoundWithoutScoped = this.providersFound.filter((data) => !this.alreadyScopedUKPRNs.includes(data.ukprn));
          this.providersFoundWithoutScoped = this.providersFoundWithoutScoped.filter((data) => !this.ukprnsNULLURNs.includes(data.ukprn));

          this.providersSearched.emit({
            providersFound: this.providersFoundWithoutScoped,
            ukprnsNotFound: this.ukprnsNotFound,
            invalidUkprns: this.invalidUkprns,
            ukprnsWithNullURNs: this.ukprnsNULLURNs,
            alreadyScopedUKPRNs: responseScopedUKPRNS
          });
          this.loadingResults.next(false);
        });
    });
  }

  getValidUkprns(ukprnsText: string): void {
    this.validUkprns = [];
    this.invalidUkprns = [];

    // Parse input into unique string segments
    const ukprnsStrings = [...new Set(
      ukprnsText
        .replace(/\n/g, ',')
        .split(',')
        .filter(x => x !== '')
        .map((x) => x.trim())
    )];

    ukprnsStrings.forEach((ukprnString) => {
      ukprnString !== '' && Helpers.isNumeric(ukprnString) && ukprnString.length === 8
        ? this.validUkprns.push(parseInt(ukprnString))
        : this.invalidUkprns.push(ukprnString);
    });
  }
}
