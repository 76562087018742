import { ElementRef, forwardRef } from '@angular/core';
import { Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

declare var $: any;

export const CUSTOM_INPUT_DATE_PICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerDirective),
  multi: true
};

@Directive({
  selector: '[appDatePicker]',
  providers: [CUSTOM_INPUT_DATE_PICKER_CONTROL_VALUE_ACCESSOR]
})
export class DatePickerDirective implements ControlValueAccessor {

  private selectedDate: string;
  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    $(this.el.nativeElement).datepicker({
      autoclose: true,
      format: 'dd/mm/yyyy',
      orientation: "bottom",
    }).on('change', (e: any) => {
      this.onChange(e.target.value);
    });
  };

  public onChange: any = (_: any) => { }
  public onTouched: any = () => { }

  get value(): any {
    return this.selectedDate;
  };

  set value(v: any) {
    if (v !== this.selectedDate) {
      this.selectedDate = v;
      this.onChange(v);
    }
  }

  writeValue(val: string): void {
    this.selectedDate = val;
    $(this.el.nativeElement).datepicker("setDate", this.selectedDate);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}