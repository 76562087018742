import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ITab } from '../../models/tab';

@Component({
  selector: 'app-tab-body',
  templateUrl: './tab-body.component.html',
  styleUrls: ['./tab-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabBodyComponent {

  @Input() tabName: string;
  @Input() tabs: ITab[];

  isTabSelected(tabs:ITab[])
  {
    var result = tabs?.findIndex(x => x.name.toLowerCase() === this.tabName.toLowerCase() && x.isSelected === true);
    return result !== -1;
  }

}
