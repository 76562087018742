export interface IProviderattributeValue {
  providerAttributeValue?: string;
  providerAttributeCode?: string;
  displayText?: string;
}

export class ProviderattributeValue implements IProviderattributeValue {
  providerAttributeValue?: string;
  providerAttributeCode?: string;
  displayText?: string;

  constructor(data?: IProviderattributeValue) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}
export enum DisplayText {
  Authority = 'Authority',
  dateClosed = 'Date Closed',
  dateOpened = 'Date Opened',
  dfeEstablishmentNumber = 'Dfe Establishment Number',
  districtCode = 'District Code',
  establishmentNumber = 'Establishment Number',
  FurtherEducationTypeName = 'Further Education Type',
  lowerSuper = 'Lower Super Output Area Code',
  middleSuper = 'Middle Super Output Area Code',
  paymentOrganisationId = 'Payment Organisation Group Id Number',
  paymentOrganisation = 'Payment Organisation Name',
  paymentOrganisationTrustCode = 'Payment Organisation Trust Code',
  paymentOrganisationType = 'Payment Organisation Type',
  paymentOrganisationUkprn = 'Payment Organisation Ukprn',
  previousLaName = 'Previous LA Name',
  ProviderStatus = 'Provider Status',
  ProviderSubtype = 'Provider Sub type',
  ProviderType = 'Provider Type',
  ReasonEstablishmentClosed = 'Reason Establishment Closed',
  ReasonEstablishmentOpened = 'Reason Establishment Opened',
  statutoryHighAge = 'Statutory High Age',
  statutoryLowAge = 'Statutory Low Age',
  TrustStatus = 'Trust Status'
}