import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { BannerStateCommand } from 'src/app/layout/components/banner/state/banner-state.command';
import { IUserInformation } from 'src/app/models/user-information';
import { API_BASE_URL, CustomApiService } from './custom-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserApiService extends CustomApiService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Inject(BannerStateCommand) bannerStateCommand: BannerStateCommand,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    super(http, bannerStateCommand, baseUrl);
  }

  public addMe(): Observable<IUserInformation> {
    return this.post<IUserInformation>('/api/user/add/me', {});
  }

  public getAllUsers(): Observable<IUserInformation[]> {
    return this.get<IUserInformation[]>('/api/User/all');
  }

  public updatePermissions(user: IUserInformation): Observable<boolean> {
    return this.post('/api/User/update/permissions', user, null, null, false);
  }

  public getUserProfile(): Observable<IUserInformation> {
    return this.get<IUserInformation>('/api/User/find/me');
  }
}
