// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.grey-button {
  color: black;
  background-color: lightgrey;
  font-weight: normal;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-grow {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/funding-flag-select/funding-flag-select.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,YAAA;EACA,2BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".text-ellipsis {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.w-100 {\n  width: 100%;\n}\n\n.w-50 {\n  width: 50%;\n}\n\n.grey-button {\n  color: black;\n  background-color: lightgrey;\n  font-weight: normal;\n}\n\n.flex-row {\n  display: flex;\n  flex-direction: row;\n}\n\n.flex-grow {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
