import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IBannerUIState } from '../banner.model';
import { BannerStateState, VISIBILITY_BANNER } from './banner-state.model';

/**
 * Create initial state
 */
export function createInitialState(): BannerStateState {
  return {
    ui : { visibility: VISIBILITY_BANNER.SHOW_NONE } as IBannerUIState
  };
}

/**
 * BannerState store
 *
 * @export
 * @class BannerStateStore
 * @extends {Store<BannerStateState>}
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'banner-state' , resettable: true})
export class BannerStateStore extends Store<BannerStateState> {

  constructor() {
    super(createInitialState());
  }
}
