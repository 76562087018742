export interface ITreeSelectOptions {
  name: string;
  value: any;
  title?:string;
  checked?: boolean;
  children?: ITreeSelectOptions[];
}

export interface ITreeNode {
  name: string;
  value: any;
  checked?: boolean;
  expanded?: boolean;
  indeterminate?: boolean;
  children?: TreeNode[];
}

export class TreeNode implements ITreeNode {
  name: string;
  value: any;
  checked: boolean;
  expanded: boolean;
  indeterminate?: boolean;
  children: TreeNode[];

  constructor(option: ITreeSelectOptions) {
    this.name = option.name;
    this.value = option.value;
    this.children = option.children?.map(child => {
      return new TreeNode({ name: child.name, value: child.value, checked: child.checked });
    }) || [];
    this.checked = option.checked ?? false;
    this.expanded = false;
    this.indeterminate = false;
  }
}
