import { identifierName } from "@angular/compiler";

export interface IPropertyEdited {
  propertyName: string;
  propertyValue: any;
  codeName?: string;
  codeValue?: string;
  childPropertyName?: string;
  childPropertyValue?: string;
  childCodeName?: string;
  childCodeValue?: string;
  comment: string;
  id: number;
  isProviderPaymentOrganisation?: boolean
}

export interface IPropertyEditCancelled {
  propertyName: string;
  cancelled: boolean;
  id?: number;
}

export enum EditComponentType {
  searchDropdown = 'searchDropdown',
  dropdown = 'dropdown',
  childDropdown = 'childDropdown',
  inputText = 'inputText',
  inputNumber = 'inputNumber',
  date = 'date',
  readOnly = 'readOnly',
  referenceDropdown = 'referenceDropdown',
  checkbox = 'checkbox'
}

export enum DropdownSubtype {
  paymentOrganisation = 'paymentOrganisation',
}

export enum dateFormat {
  day = 'day',
  month = 'month',
  year = 'year',
}

export enum commonErrorMessageTemplate {
  comments = 'Enter a comment',
  commentErrorMessage1 = 'There is a problem with one on of the field you have edited. Please enter a comment in the field name : ',
  commentErrorMessage2 = 'This is required in order to help other users to understand change made to the data.',
  name = 'Enter a Name',
  ukprn = 'Enter a UKPRN',
  urn = 'Enter a URN',
  paymentOrganisation = 'Select a payment organisation',
  description = 'Enter a description',
  identifierName = 'Enter a identifier',
  dataType = 'Select a data type',
  selectionField = 'Select Yes or No',
  isAggregable = 'Select Yes or No',
  schemaName = 'Select a schema name',
  enableCopyData = 'Select enable copy data for provider',
  comment = 'Enter a comment',
  minFormatMessage = 'Minimum value must be in the specified format',
  maxFormatMessage = 'Maximum value must be in the specified format'
}

export interface IDropdownItem {
  display: string
  value: any
}

export interface IPropertyLink {
  parentName?: string;
  parentFDSUID: string;
  childName?: string;
  childFDSUID: string;
}

export class Property {
  name: string;
  displayName: string;
  referenceColumn: string
  parentProperty: any;
  type: EditComponentType;

  constructor(name: string = null, displayName: string = null, parentProperty: any = null, type: EditComponentType = null, referenceColumn: string = null) {
    this.name = name;
    this.displayName = displayName;
    this.parentProperty = parentProperty;
    this.type = type;
    this.referenceColumn = referenceColumn;
  }
}

