import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: 'unsaved-changes-dialog.component.html',
  styleUrls: ['unsaved-changes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsavedChangesDialogComponent {
  constructor(private dialogRef: MatDialogRef<UnsavedChangesDialogComponent, boolean>) {}

  stay(): void {
    this.dialogRef.close(false);
  }

  leave(): void {
    this.dialogRef.close(true);
  }
}
