// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-none {
  display: none;
  opacity: 0;
}

.banner-show {
  opacity: 1;
}

.margin-bottom {
  margin-bottom: 0;
}

.govuk-notification-banner__heading {
  max-width: none;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/banner/banner.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,UAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".banner-none {\n  display: none;\n  opacity: 0;\n}\n\n.banner-show {\n  opacity: 1;\n}\n\n.margin-bottom {\n  margin-bottom: 0;\n}\n\n.govuk-notification-banner__heading {\n  max-width: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
