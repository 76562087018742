// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.padding-bottom {
  padding-bottom: 0.25rem !important;
}

.padding-top {
  padding-top: 0.5rem !important;
}

.padding-bottom-readonly {
  padding-bottom: 0.5rem !important;
}

.csHeight {
  height: 33px !important;
}

.flex-row {
  display: flex;
  align-content: flex-end;
}

.cancel-link {
  margin-top: 0.6rem;
  padding-left: 1rem;
}

.record-editor-element {
  padding-bottom: 1rem;
}

.margin-left-0 {
  margin-left: 0;
}

.record-editor-left-padding-1 {
  padding-left: 1.16rem;
}

.w-100 {
  width: 100%;
}

.p-0 {
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/record-editor/drop-down-editor/drop-down-editor.component.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,iCAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAGA;EACE,aAAA;EACA,uBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,qBAAA;AAAF","sourcesContent":[".padding-bottom {\n  padding-bottom: 0.25rem !important;\n}\n\n.padding-top {\n  padding-top: 0.5rem !important;\n}\n\n.padding-bottom-readonly {\n  padding-bottom: 0.5rem !important;\n}\n\n.csHeight{\n  height: 33px !important;\n}\n\n//module.scss\n.flex-row {\n  display: flex;\n  align-content: flex-end;\n}\n\n.cancel-link {\n  margin-top: .6rem;\n  padding-left: 1rem;\n}\n\n.record-editor-element {\n  padding-bottom: 1rem;\n}\n\n.margin-left-0 {\n  margin-left: 0;\n}\n\n.record-editor-left-padding-1 {\n  padding-left: 1.16rem;\n}\n\n.w-100 {\n  width: 100%;\n}\n\n.p-0 {\n  padding: 0 !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
