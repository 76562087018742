import { Injectable } from '@angular/core';
import { VISIBILITY_TABS } from 'src/app/data-tab/models/tab';
import { MaintainDataStateStore } from './maintain-data-state-store';
import { LEFT_PANEL_STATE } from '../maintain-data.model';

/**
 * ProvidersState service
 *
 * @export
 * @class MaintainDataStateService
 */
@Injectable({ providedIn: 'root' })
export class MaintainDataStateCommand {

  constructor(private maintainDataStateStore: MaintainDataStateStore) {
  }

  updateRightPanelTabState(rightPanelTabs: VISIBILITY_TABS): void {
    this.maintainDataStateStore.updateRightPanelTabs(rightPanelTabs);
  }

  updateLeftPanelState(leftPanel: LEFT_PANEL_STATE): void {
    this.maintainDataStateStore.updateLeftPanel(leftPanel);
  }

  resetState(): void {
    this.maintainDataStateStore.reset();
  }
}
