// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.page-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: "GDS Transport", arial, sans-serif !important;
}

.app-content {
  flex-grow: 1;
}

.footer {
  flex-grow: 0;
}

.margin-0 {
  margin: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,0DAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".wrapper {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.page-content {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  font-family: \"GDS Transport\", arial, sans-serif !important;\n}\n\n.app-content {\n  flex-grow: 1;\n}\n\n.footer {\n  flex-grow: 0;\n}\n\n.margin-0 {\n  margin: 0 !important;\n}\n\n.padding-0 {\n  padding: 0 !important;\n}\n\n.padding-right-0 {\n  padding-right: 0 !important;\n}\n\n.padding-top-0 {\n  padding-top: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
