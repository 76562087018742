import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FreeTextComponent } from './free-text/free-text.component';
import { DateEditorComponent } from './date-editor/date-editor.component';
import { DropEditorComponent } from './drop-down-editor/drop-down-editor.component';
import { SearchDropdown } from './search-dropdown/search-dropdown.component';
import { EllipsisTooltipDirective } from './ellipsis-tooltip.directive';
import { ValidatorService } from '../validators/validator-service';

@NgModule({
  declarations: [
    FreeTextComponent,
    DateEditorComponent,
    DropEditorComponent,
    SearchDropdown,
    EllipsisTooltipDirective,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule
  ],
  providers: [
    ValidatorService
  ],
  exports: [
    FreeTextComponent,
    DateEditorComponent,
    DropEditorComponent,
    SearchDropdown,
    EllipsisTooltipDirective,
  ]
})
export class RecordEditorModule { }
