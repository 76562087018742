import { SearchOperator } from './search-operator';

export class SearchCriterionModel {

  public fieldName: string;
  public value: any;
  public operator: SearchOperator;
  public childValue?: SearchCriterionModel[];
  public extraParam?: SearchCriterionModel;

  constructor(fieldName: string = null, operator: SearchOperator = null, value: any = null, childValue: SearchCriterionModel[] = null, extraParam: SearchCriterionModel = null) {
      this.fieldName = fieldName;
      this.operator = operator;
      this.value = value;
      this.childValue = childValue;
      this.extraParam = extraParam;
  }
}
