import { Injectable } from '@angular/core';
import { IBannerUIState } from '../banner.model';
import { BannerStateStore } from './banner-state.store';

/**
 * BannerState service
 *
 * @export
 * @class BannerStateService
 */
@Injectable({ providedIn: 'root' })
export class BannerStateCommand {

  constructor(private bannerStateStore: BannerStateStore) {
  }

  updateBanner(banner: IBannerUIState): void
  {
    this.bannerStateStore.update({ ui: banner });
    window.scrollTo(0,0);
  }

  resetState(): void
  {
    this.bannerStateStore.reset();
  }
}
