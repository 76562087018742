import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITreeSelectOptions, TreeNode } from '../tree-select-dropdown/tree-select.model';
import { Observable } from 'rxjs';
import { Filters, IFieldSearchTerms } from '../../../maintain-data/models/search-filter.model';

@Component({
  selector: 'tree-select-field',
  templateUrl: './tree-select-field.component.html',
  styleUrls: ['./tree-select-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeSelectFieldComponent implements OnInit {

  @Input() title: string;
  @Input() clearFilter$: Observable<Filters | null>;
  @Input() fieldName: string;
  @Input() options$: Observable<ITreeSelectOptions[]>;
  @Input() isEnable: boolean;

  /*** if true, root level selection returns all children of selected node */
  @Input() returnAllChildren = false;
  @Output() selectionChange = new EventEmitter<ITreeSelectOptions[] | IFieldSearchTerms>();

  isOpen = false;
  allSelected = true;
  allIndeterminate = false;
  treeState: TreeNode[];
  selectedOptions: ITreeSelectOptions[] = [{ name: 'All', value: 'All' }];
  selectedChildrenCount: number;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.clearFilter$?.subscribe(filter => {
      filter
        ? filter === this.fieldName && this.unselectAll(this.treeState)
        : this.unselectAll(this.treeState);
    });

    this.options$.subscribe(options => this.setupCheckboxOptions(options));
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  private setupCheckboxOptions(options: ITreeSelectOptions[]): void {
    this.treeState = options.map(option => {
      return new TreeNode(option);
    });
    this.updateSelection();
  }

  onAllClicked(): void {
    this.allSelected ? this.selectAll(this.treeState) : this.unselectAll(this.treeState);
  }

  nodeClicked(event: Event, node: TreeNode, child?: TreeNode): void {
    if (child) {
      node.checked = this.allOptionsSelected(node.children);
      this.updateSelection();
    } else if (node) {
      node.checked ? this.selectAll(node.children) : this.unselectAll(node.children);
    }
    node.indeterminate = this.checkIndeterminate(node);
    this.allIndeterminate = !this.allSelected && this.treeState.some(node => node.checked || node.indeterminate);
  }

  toggleNodeExpand(node: TreeNode): void {
    node.expanded = !node.expanded;
  }

  allOptionsSelected(nodes: TreeNode[]): boolean {
    return nodes.every(val => val.checked);
  }

  selectAll(nodes: TreeNode[]): void {
    nodes?.forEach(node => {
      node.checked = true;
      node.indeterminate = false;
      if (node.children.length > 0) {
        this.selectAll(node.children);
      }
    });
    this.allIndeterminate = false;
    this.updateSelection();
  }

  unselectAll(nodes: TreeNode[]): void {
    nodes.forEach(node => {
      node.checked = false;
      node.indeterminate = false;
      if (node.children.length > 0) {
        this.unselectAll(node.children);
      }
    });
    this.allIndeterminate = false;
    this.updateSelection();
  }

  checkIndeterminate(node: TreeNode): boolean {
    return node.children?.some(child => child.checked) && !node.children?.every(child => child.checked);
  }

  private updateSelection(): void {
    this.allSelected = this.allOptionsSelected(this.treeState);
    this.selectedOptions = [];
    if (this.allSelected) {
      this.selectedOptions = [{ name: 'All', value: 'All' }];
    } else {
      this.selectedOptions = this.treeState.map(treeNode => {
        const selectedChildren = treeNode.children.filter(child => child.checked).map(child => {
          return { name: child.name, value: child.value }
        });
        return treeNode.checked
          ? this.returnAllChildren
            ? { name: treeNode.name, value: treeNode.value, children: selectedChildren }
            : { name: treeNode.name, value: treeNode.value }
          : selectedChildren.length > 0
            ? { name: treeNode.name, value: treeNode.value, children: selectedChildren }
            : null;
      }).filter(node => node !== null);
    }
    this.countSelectedChildren();
    this.fieldName
      ? this.selectionChange.emit({ fieldName: this.fieldName, searchTerms: this.selectedOptions } as IFieldSearchTerms)
      : this.selectionChange.emit(this.selectedOptions);
    this.cd.markForCheck();
  }

  countSelectedChildren(): void {
    this.selectedChildrenCount = this.selectedOptions.map(node => node.children?.length).reduce((a, b) => a + b, 0);
  }

  getSubtext(): string {
    return this.allSelected
      ? 'All selected'
      : `${this.selectedChildrenCount} selected`;
  }
}
