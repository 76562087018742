import { Directive, Host, Optional, Renderer2, Self, ViewContainerRef, Input } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatButton } from "@angular/material/button";

interface PageObject {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

@Directive({
  selector: "[app-custom-paginator]"
})
export class CustomPaginatorDirective {
  private _pageGapTxt = "...";
  private _rangeStart: number;
  private _rangeEnd: number;
  private _buttons = [];
  private _curPageObj: PageObject = {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
    previousPageIndex: 0
  };

  @Input() pageSize: number;
  @Input()
  set length(value: number) {
    this.initPageRange();
  }
  @Input()
  get showTotalPages(): number {
    return this._showTotalPages;
  }
  set showTotalPages(value: number) {
    this._showTotalPages = value % 2 == 0 ? value + 1 : value;
  }
  private _showTotalPages = 2;

  get inc(): number {
    return this._showTotalPages % 2 == 0
      ? this.showTotalPages / 2
      : (this.showTotalPages - 1) / 2;
  }

  get numOfPages(): number {
    return this.matPag.getNumberOfPages();
  }

  get lastPageIndex(): number {
    return this.matPag.getNumberOfPages() - 1;
  }

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private vr: ViewContainerRef,
    private ren: Renderer2
  ) {
    this.matPag.page.subscribe((e: PageObject) => {
      if (
        this._curPageObj.pageSize != e.pageSize &&
        this._curPageObj.pageIndex != 0
      ) {
        e.pageIndex = 0;
        this._rangeStart = 0;
        this._rangeEnd = this._showTotalPages - 1;
      }
      this._curPageObj = e;

      this.initPageRange();
    });
  }

  private initPageRange(): void {
    const middleIndex = (this._rangeStart + this._rangeEnd) / 2;

    this._rangeStart = this.getRangeStart(middleIndex);
    this._rangeEnd = this.getRangeEnd(middleIndex);

    this.generatePageNumbers();
  }

  private generatePageNumbers() {
    const actionContainer = this.vr.element.nativeElement.querySelector(
      "div.mat-paginator-range-actions"
    );
    const nextPageNode = this.vr.element.nativeElement.querySelector(
      "button.mat-paginator-navigation-next"
    );

    if (this._buttons.length > 0) {
      this._buttons.forEach(button => {
        this.ren.removeChild(actionContainer, button);
      });
      this._buttons.length = 0;
    }

    if (this._buttons.length == 0) {
      let nodeArray = this.vr.element.nativeElement?.childNodes[0]?.childNodes[0]?.childNodes[2]?.childNodes;
      setTimeout(() => {
        if (nodeArray) {
          for (let i = 0; i < nodeArray.length; i++) {
            if (nodeArray[i].nodeName === "BUTTON") {
              if (nodeArray[i].disabled) {
                this.ren.setStyle(nodeArray[i], "font-weight", "Bold");
              }
            }
          }
        }
      });
    }

    for (let i = 0; i < this.numOfPages; i++) {
      if (i >= this._rangeStart && i <= this._rangeEnd) {
        this.ren.insertBefore(
          actionContainer,
          this.generatePageButtons(i, this.matPag.pageIndex),
          nextPageNode
        );
      }

      if (i == this._rangeEnd && (this.lastPageIndex - this._rangeEnd) > 1) {
        this.ren.insertBefore(
          actionContainer,
          this.generatePageButtons(this._pageGapTxt, this.matPag.pageIndex),
          nextPageNode
        );
      }

      if (i == this._rangeEnd + 1) {
        this.ren.insertBefore(
          actionContainer,
          this.generatePageButtons(this.lastPageIndex, this._rangeEnd),
          nextPageNode
        );
      }
    }
  }

  private getRangeStart(middleIndex: number): number {
    switch (true) {
      case this._curPageObj.pageIndex == 0 && this._rangeStart != 0:
        return 0;
      case this._curPageObj.pageIndex > this._rangeEnd:
        return this._curPageObj.pageIndex + this.inc > this.lastPageIndex
          ? this.lastPageIndex - this.inc * 2
          : this._curPageObj.pageIndex - this.inc;
      case this._curPageObj.pageIndex > this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex > middleIndex && this._rangeEnd < this.lastPageIndex:
        return this._rangeStart + 1;
      case this._curPageObj.pageIndex < this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex < middleIndex && this._rangeStart > 0:
        return this._rangeStart - 1;
      default:
        return this._rangeStart;
    }
  }

  private getRangeEnd(middleIndex: number): number {
    switch (true) {
      case this._curPageObj.pageIndex == 0 &&
        this._rangeEnd != this._showTotalPages:
        return this._showTotalPages - 1;
      case this._curPageObj.pageIndex > this._rangeEnd:
        return this._curPageObj.pageIndex + this.inc > this.lastPageIndex
          ? this.lastPageIndex
          : this._curPageObj.pageIndex + 1;
      case this._curPageObj.pageIndex > this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex > middleIndex && this._rangeEnd < this.lastPageIndex:
        return this._rangeEnd + 1;
      case this._curPageObj.pageIndex < this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex < middleIndex && this._rangeStart >= 0 &&
        this._rangeEnd > this._showTotalPages - 1:
        return this._rangeEnd - 1;
      default:
        return this._rangeEnd;
    }
  }

  private switchPage(i: number): void {
    const previousPageIndex = this.matPag.pageIndex;
    this.matPag.pageIndex = i;
    this.matPag["_emitPageEvent"](previousPageIndex);
    this.initPageRange();
  }

  private generatePageButtons(i: any, pageIndex: number): any {
    const linkBtn: MatButton = this.ren.createElement("button");
    this.ren.addClass(linkBtn, "mat-mini-fab");
    this.ren.setStyle(linkBtn, "background-color", "white");
    this.ren.setStyle(linkBtn, "font-weight", "lighter");

    const pagingTxt = isNaN(i) ? this._pageGapTxt : +(i + 1);
    const text = this.ren.createText(pagingTxt + "");

    this.ren.addClass(linkBtn, "mat-custom-page");
    switch (i) {
      case pageIndex:
        this.ren.setAttribute(linkBtn, "disabled", "disabled");
        break;
      case this._pageGapTxt:
        let newIndex = this._rangeEnd + 1;

        if (newIndex >= this.numOfPages) { 
          newIndex = this.lastPageIndex;
        }

        if (pageIndex != this.lastPageIndex) {
          this.ren.listen(linkBtn, "click", () => {
            this.switchPage(newIndex);
          });
        }

        if (pageIndex == this.lastPageIndex) {
          this.ren.setAttribute(linkBtn, "disabled", "disabled");
        }
        break;
      default:
        this.ren.listen(linkBtn, "click", () => {
          this.switchPage(i);
        });
        break;
    }
    this.ren.appendChild(linkBtn, text);
    this._buttons.push(linkBtn);
    return linkBtn;
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this._rangeStart = 0;
      this._rangeEnd = this._showTotalPages - 1;
      this.initPageRange();
    }, 10);
  }
}