import { Pipe, PipeTransform } from '@angular/core';
import { ITreeSelectOptions } from './tree-select.model';

@Pipe({
  name: 'treeSelectionText'
})
export class TreeSelectionTextPipe implements PipeTransform {

  transform(selectedOptions: ITreeSelectOptions[]): string {
    return selectedOptions?.length === 0 || selectedOptions[0].value === 'All'
      ? ''
      : selectedOptions
        .map(node => node.children?.length > 0 ? node.children.length : 1)
        .reduce((a, b) => a + b, 0)
        .toString() + ' selected';
  }

}
