import { SearchCriterionModel } from './search-criterion-model';

export class QueryModel {
  public sortColumn: string;
  public sortDescending: boolean;
  public pageNumber: number;
  public pageSize: number;
  public skipPaging: boolean;
  public searchCriteria: SearchCriterionModel[][] = [];
  public agreementData: boolean;
  public providerTransactionData: boolean;
  public transactionId: number;

  constructor(
    pageNumber: number,
    pageSize?: number,
    sortColumn?: string,
    sortDescending?: boolean,
    searchCriteria?: SearchCriterionModel[][],
    skipPaging: boolean = false,
    agreementData: boolean = false,
    providerTransactionData: boolean = false,
    transactionId: number = 0
  ) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.sortColumn = sortColumn;
    this.sortDescending = sortDescending;
    this.searchCriteria = searchCriteria;
    this.skipPaging = skipPaging;
    this.agreementData = agreementData;
    this.providerTransactionData = providerTransactionData;
    this.transactionId = transactionId;
  }

}
