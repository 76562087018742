export interface IFundingFlag {
  id?: number;
  providerId?: number;
  isDeleted?: boolean;
  fundingPeriodId?: number;
  fundingStreamId?: number;
  fundingPeriodName?: string;
  fundingStreamName?: string;
  fundingPeriodCode?: string;
  fundingStreamCode?: string;
}

export class FundingFlag implements IFundingFlag {
  id?: number;
  providerId?: number;
  isDeleted?: boolean;
  fundingPeriodId?: number;
  fundingStreamId?: number;
  fundingPeriodName?: string;
  fundingStreamName?: string;
  fundingPeriodCode?: string;
  fundingStreamCode?: string;

  constructor(data?: IFundingFlag) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}
