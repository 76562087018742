import { Component, OnInit } from '@angular/core';
import { IAdultTransaction } from 'src/app/adult-provider-data/models/provider-transaction-data.model';

@Component({
  selector: 'app-manage-provider',
  templateUrl: './manage-provider.component.html',
  styleUrls: ['./manage-provider.component.scss']
})
export class ManageProviderComponent implements OnInit {
  TRANSACTION_DATA_STORAGE_KEY = 'adultAllocationTransactionData';
  constructor() { }
  ngOnInit(): void {
    const transaction: IAdultTransaction = JSON.parse(sessionStorage.getItem(this.TRANSACTION_DATA_STORAGE_KEY));
    if (transaction) {
      sessionStorage.removeItem(this.TRANSACTION_DATA_STORAGE_KEY);
    }
  }

}
